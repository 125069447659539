import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  _MAIN_WALLET_ID,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import {
  setBusy,
  setIdle,
  showMessage,
  setGameLobbyModal,
  setLoginModal,
} from "../../redux/AppAction.js";
import {
  stringIsNullOrEmpty,
} from "../../util/Util";
import {
  map,
  filter,
} from "lodash";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay } from 'swiper';
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
// import Spinner from "react-bootstrap/Spinner";
import {
} from "reactstrap";
import LoadingGames from "components/template_1/LoadingGames";
import FallBackImage from "components/template_1/FallBackImage";
import 'react-loading-skeleton/dist/skeleton.css'
import SweetAlert from "react-bootstrap-sweetalert";


const RecommendedCasino = ({ updateMasterProductId, updateSelectedGameIsSeamless, updateSelectedGameName, updateProductGameId,
  updatePopBannerImage, updateTitle, updateCategoryTitle, UpdateIsSupportIframe, updateProviderName, pageName }) => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _dispatch = useDispatch();
  const liveSlickRef = React.useRef(null);

  // Swiper Plugins
  SwiperCore.use([Autoplay]);

  var { heartBeat } = useSelector((state) => state.authState);
  const memberData = useSelector((state) => state.appState.member);
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [selectedGameName, setSelectedGameName] = useState("");
  const [masterProductId, setMasterProductId] = useState(0);
  const [isApp, setIsApp] = useState(false);
  const [appDeepLink, setAppDeepLink] = useState("");
  const [packageNameKeyword, setPackageNameKeyword] = useState("");
  const [selectedGameHasOwnLobby, setSelectedGameHasOwnLobby] = useState(false);
  const [selectedGameIsSeamless, setSelectedGameIsSeamless] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [bonus, setBonus] = useState(0);
  const [commission, setCommission] = useState(0);
  const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
    useState(0);
  const [masterProductContent, setMasterProductContent] = useState("");
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [showPregamePopup, setShowPregamePopup] = useState(false);
  const [productGameId, setProductGameId] = useState(0);
  const [toLobbyBtn, SetToLobbyBtn] = useState(false);
  const [rolloverBalance, setRolloverBalance] = useState("");
  const [rolloverTarget, setRolloverTarget] = useState("");
  const [productCommRate, setProductCommRate] = useState([]);
  const [appUrl, setAppUrl] = useState("");
  const [clubFee, setClubFee] = useState(0);
  const [activeLanguageId, setActiveLanguageId] = useState(0);
  const [recommendedSlotGames, setRecommendedSlotGames] = useState(
    useSelector((state) => state.appState.gamesRecommended.slotGames)
  );
  const [recommendedSlotGamesCount, setRecommendedSlotGamesCount] = useState(
    useSelector((state) => state.appState.gamesRecommended.casinoGamesCount)
  );
  const [recommendedCasinoGames, setRecommendedCasinoGames] = useState(
    useSelector((state) => state.appState.gamesRecommended.casinoGames)
  );
  const [recommendedCasinoGamesCount, setRecommendedCasinoGamesCount] =
    useState(
      useSelector((state) => state.appState.gamesRecommended.casinoGamesCount)
    );

  // const [latestBets, setLatestBets] = useState("");
  const latestBets = useSelector((state) => state.appState.latestBets);
  const [dragging, setDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const walletsDetails = useSelector((state) => state.appState.walletsDetails);
  const companyIcons = useSelector((state) => state.appState.companyIcons);
  const [alertIcon, setAlertIcon] = useState();
  const [defaultGameIcon, setDefaultGameIcon] = useState();
  const [isSupportIframeState, setIsSupportIframeState] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [iframeAlert, setIsIframeAlert] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [loadingRecommendedGames, setLoadingRecommendedGames] = useState(false);

  const [isGameLobbyModal, setIsGameLobbyModal] = useState(false);
  const [isNewWindow, setIsNewWindow] = useState(false);


  const slotSlick = {
    slidesToShow: 1,
    dots: false,
    arrows: false,
    lazyLoad: true,
    swipe: true,
    infinite: false,
    swipeToSlide: true,
    speed: 1000,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
  };


  useEffect(() => {
    getCompanyIcon();
    getNewGames()

  }, []);

  async function getCompanyIcon() {
    _dispatch(setBusy());

    const defaultGameIconTemp = filter(companyIcons, (icon) => {
      return icon.iconCategory === "default_game_icon";
    });
    if (defaultGameIconTemp) {
      setDefaultGameIcon(defaultGameIconTemp[0]?.iconImage||defaultGameIconTemp[0]?.defaultImage);
    }

    _dispatch(setIdle());
  }

  async function getNewGames() {
    if (recommendedCasinoGamesCount == 0) {
      setLoadingRecommendedGames(true);
      let responseJson = await ApiEngine.get(
        ApiUrl._API_HOMEPAGE_PRODUCTS +
        "?languageId=" +
        activeLanguageId + "&type=Recommended"
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {

        /////get recommended Slots
        const data = responseJson[ApiKey._API_DATA_KEY].recommendedGames.filter(
          (x) => x.categoryTitle === "Slots"
        );
        setRecommendedSlotGamesCount(data.length);

        let groupedArray = [];

        for (let i = 0; i < data.length; i += 12) {
          groupedArray.push(data.slice(i, i + 12));
        }
        setRecommendedSlotGames(groupedArray);

        /////get recommended Casino
        const casinoRecommendedGame = responseJson[
          ApiKey._API_DATA_KEY
        ].recommendedGames.filter((x) => x.categoryTitle === "Casino");

        setRecommendedCasinoGamesCount(casinoRecommendedGame.length);
        let casinoGameGrouped = [];

        for (let i = 0; i < casinoRecommendedGame.length; i += 12) {
          casinoGameGrouped.push(casinoRecommendedGame.slice(i, i + 12));
        }
        setRecommendedCasinoGames(casinoGameGrouped);
        setLoadingRecommendedGames(false);


        //_dispatch(
        //  bindGamesRecommended({
        //    slotGamesCount: data.length,
        //    slotGames: groupedArray,
        //    casinoGamesCount: casinoRecommendedGame.length,
        //    casinoGames: casinoGameGrouped,
        //  })
        //);
      }
      setLoadingRecommendedGames(false);
    }

  }

  async function startGame(
    gameId,
    hasOwnLobby,
    masterProductId,
    gameName,
    isSeamless,
    isApp,
    appUrl,
    appDeepLink,
    isNewWindow,
    packageNameKeyword,
    isSupportIframe,
    categoryTitle,
    providerName,
    popoutBanner
  ) {
    setSelectedGameName(gameName);
    setMasterProductId(masterProductId);
    setIsApp(isApp);
    setAppDeepLink(appDeepLink);
    setPackageNameKeyword(packageNameKeyword);
    setSelectedGameHasOwnLobby(hasOwnLobby);
    setSelectedGameIsSeamless(isSeamless);
    setIsSupportIframeState(isSupportIframe);
    setCategoryTitle(categoryTitle);
    setProviderName(providerName);

    if (pageName == "HOME") {
      updateMasterProductId(masterProductId);
      updateSelectedGameIsSeamless(isSeamless);
      updateSelectedGameName(gameName);
      updateProductGameId(gameId);
      updatePopBannerImage(popoutBanner);
      updateTitle(t("HOME"))
      updateCategoryTitle(categoryTitle);
      UpdateIsSupportIframe(isSupportIframe);
      updateProviderName(providerName);
    }

    if (isApp && !stringIsNullOrEmpty(appUrl)) {
      setAppUrl(appUrl);
    }
    if (hasOwnLobby !== true) {
      if (isSeamless == false) {
        setProductGameId(gameId);
        _dispatch(setGameLobbyModal(true));
        setIsGameLobbyModal(true);
      } else {
        if (isSupportIframe) {
          var breadcrumbData = [{
            pageName: t("HOME"),
            location: _history.location.pathname + _history.location.search,
            state: _history.location.state
          }];

          var path = WebUrl._URL_IFRAME_GAME_PAGE + "?gameId=" + gameId;
          _history.push(path, {
            breadcrumbData: breadcrumbData
          });
        } else {
          window.open(
            ApiUrl._API_START +
            "?gameId=" +
            gameId + "&device=" + (window.innerWidth <= 768 ? "m" : "d")
          );
        }

      }
    } else {
      if (isSeamless == false) {
        setIsLoading(true);
        var responseJson = await ApiEngine.get(
          ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
          "?masterProductId=" +
          masterProductId
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setWalletList(responseJson[ApiKey._API_DATA_KEY]);

          let tempFromOptions = [];
          let tempToOptions = [];

          responseJson[ApiKey._API_DATA_KEY].map((product) => {
            let option = {
              label: product.productName,
              value: product.productId,
              amount: parseFloat(product.balance),
            };
            if (product.productId === _MAIN_WALLET_ID) {
              tempFromOptions.push(option);
            } else if (product.productId === masterProductId) {
              setBonus(product.bonus);
              setCommission(product.commission);
              if (product.productName === "D2 Poker") {
                setClubFee(product.clubFee);
              }
              setGameWalletBalanceBeforeTransfer(product.balance);
              if (product.masterProductContent !== null) {
                setMasterProductContent(product.masterProductContent);
              } else {
                setMasterProductContent("");
              }
              tempToOptions.push(option);
            }
          });
          setFromOptions(tempFromOptions);
          setToOptions(tempToOptions);

        }

        setShowPregamePopup(true);
        getProductComRate(gameName);
        setProductGameId(gameId);
        SetToLobbyBtn(true);
        getRolloverProgress(gameId);

        var path = WebUrl._URL_GAME_LOBBY +
          "?name=" +
          gameName +
          "&id=" +
          gameId +
          "&languageId=" +
          memberData["languageSetting"] +
          "&category=" +
          categoryTitle;

        var breadcrumbData = [{
          pageName: t('HOME'),
          location: _history.location.pathname + _history.location.search,
          state: _history.location.state
        }, {
          pageName: gameName,
          location: path
        }];

        var state = {
          breadcrumbData: breadcrumbData
        }

        _history.push(path, state);
        setIsLoading(false);
      }
    }
  }

  async function setTransactionValidForPromo(gameId) {
    _dispatch(setBusy());
    let responseJson = await ApiEngine.get(
      ApiUrl._API_UPDATE_TRANSACTION_VALID_FOR_PROMO
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      // setIsValidForPromotion(false);
    }
    _dispatch(setIdle());
  }

  async function getProductComRate(selectedGameName) {
    let apiUrl = ApiUrl._API_PRODUCT_COMM_RATE;
    let data = [];
    let responseJson = await ApiEngine.get(apiUrl);
    if (
      responseJson[ApiKey._API_SUCCESS_KEY] &&
      responseJson[ApiKey._API_DATA_KEY].length > 0
    ) {
      data = responseJson[ApiKey._API_DATA_KEY].filter(
        (x) => x.productName === selectedGameName
      );

      if (data.length > 0) {
        setProductCommRate(data[0]);
      }
    }
  }

  async function getRolloverProgress(masterProductId) {
    setRolloverBalance("");
    setRolloverTarget("");
    if (masterProductId != "0" && !stringIsNullOrEmpty(masterProductId)) {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_DEPOSIT_PROMOTION_ROLLOVER_PROGRESS +
        "?masterProductId=" +
        masterProductId
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        if (responseJson[ApiKey._API_DATA_KEY] != null) {
          if (responseJson[ApiKey._API_DATA_KEY].data != null) {
            setRolloverBalance(
              responseJson[ApiKey._API_DATA_KEY].data["rolloverBalance"]
            );
            setRolloverTarget(
              responseJson[ApiKey._API_DATA_KEY].data["rolloverTarget"]
            );
          }
        }
      }
    }
  }

  return (
    <>
      {iframeAlert && (
        <div className="sweet-alert-container t3-sweet-alert-container">
          <SweetAlert
            customIcon={(<img
              className="m-auto"
              src={alertIcon}
              height="90px"
              width="90px"
              alt="Custom Icon"
            />)}
            cancelBtnText={t("CANCEL")}
            confirmBtnText={t("CONTINUE")}
            icon
            // onConfirm={() => {
            //   getRestoreAccountBlance();
            // }}
            onCancel={() => {
              setIsIframeAlert(false);
            }}
            custom
            showCancel
            reverseButtons={true}
            cancelBtnBsStyle="danger"
            customButtons={
              <React.Fragment>
                <div className="sweetalert-custom-buttons">
                  <button
                    className={"btn btn-lg btn-primary"}
                    onClick={() => {
                      startGame(
                        productGameId,
                        selectedGameHasOwnLobby,
                        masterProductId,
                        selectedGameName,
                        selectedGameIsSeamless,
                        isApp,
                        appDeepLink,
                        isNewWindow,
                        packageNameKeyword,
                        isSupportIframeState
                      )
                      setIsIframeAlert(false);
                    }}
                  >
                    {t("CONTINUE")}
                  </button>
                  <a
                    onClick={() => (_dispatch(setGameLobbyModal(false)), setIsIframeAlert(false))}
                    className="btn btn-primary btn-lg"
                  >
                    {t("CLOSE")}
                  </a>
                </div>

              </React.Fragment>
            }
          >
            <span className="sweet-alert-custom-text">
              <div dangerouslySetInnerHTML={{ __html: t("IFRAME_WARM_").replace('\n', '<br/>') }} style={{ marginBottom: "20px", fontSize: "14px" }} />
            </span>
          </SweetAlert>
        </div>
      )}
      {!loadingRecommendedGames && recommendedCasinoGames?.length > 0 &&
        <div className="t3-game-list-container mt-5">
          <div className="t3-game-list-title-with-arrows-box mb-4">
            <div className="first">
              <h3 className="t3-custom-title">
                {t("RECOMMENDED")} <span>{t("LIVE")}</span>{" "}{t("CASINO").toUpperCase()}
              </h3>
            </div>
            <div className="second">
              <div className="t3-game-item-count-box" style={{ display: "none" }}>
                {t("ALL")} <span>{recommendedCasinoGamesCount}</span>{" "}
                {t("CASINO")}
              </div>
              {liveSlickRef?.current?.innerSlider.props.children.length > 1 && <>
                <div className="t3-custom-slick-arrow">
                  <div onClick={() => liveSlickRef?.current?.slickPrev()}>
                    <VIcon
                      icon={themeIcons.angleLeft}
                      className="theme-icon-size-20"
                    />
                  </div>
                  <div onClick={() => liveSlickRef?.current?.slickNext()}>
                    <VIcon
                      icon={themeIcons.angleRight}
                      className="theme-icon-size-20"
                    />
                  </div>
                </div>
              </>}
            </div>
          </div>
          {recommendedCasinoGames.length == 0 && (
            <div className="spinner-wrapper">
              <LoadingGames />
            </div>
          )}
          <div className="t3-slot-games-box">
            <Slider
              ref={liveSlickRef}
              {...slotSlick}
              className="t3-live-game-slick"
            >
              {recommendedCasinoGames &&
                recommendedCasinoGames.length &&
                map(recommendedCasinoGames, (item, index) => {
                  return (
                    <div key={index} className="t3-game-list-box">
                      {item &&
                        item.length &&
                        map(item, (game, index2) => {
                          return (
                            <div
                              key={index2}
                              className="t3-game-list-item"
                              onClick={(e) => {
                                if (dragging) {
                                  e.preventDefault();
                                } else {
                                  if (heartBeat) {
                                    setPopupBannerImage(
                                      game["popupBannerImage"]
                                    );
                                    if (game["gameUm"] !== true) {
                                      if (
                                        walletsDetails.isValidForPromotion
                                      ) {
                                        _dispatch(
                                          showMessage({
                                            custom: true,
                                            customIcon: (
                                              <img
                                                className="m-auto"
                                                src={alertIcon}
                                                height="120px"
                                                width="120px"
                                                alt="Custom Icon"
                                              />
                                            ),
                                            content: t(
                                              "PLEASE_CLAIM_PROMO_BEFORE_ENTER_GAME"
                                            ),
                                            showCancel: true,
                                            onConfirm: () => {
                                              setTransactionValidForPromo();
                                              setIsSupportIframeState(game["isSupportIframe"])
                                              if (game["isSupportIframe"]) {
                                                startGame(
                                                  game["id"],
                                                  game["hasOwnLobby"],
                                                  game["masterProductId"],
                                                  game["gameName"],
                                                  game[
                                                  "masterProductIsSeamless"
                                                  ],
                                                  game["isApp"],
                                                  game["appUrl"],
                                                  game["appDeepLink"],
                                                  game["isNewWindow"],
                                                  game["packageNameKeyword"],
                                                  game["isSupportIframe"]
                                                );
                                              } else {
                                                setSelectedGameName(game["gameName"]);
                                                setMasterProductId(game["masterProductId"]);
                                                setIsApp(game["isApp"]);
                                                setAppDeepLink(game["appDeepLink"]);
                                                setPackageNameKeyword(game["packageNameKeyword"]);
                                                setSelectedGameHasOwnLobby(game["hasOwnLobby"]);
                                                setSelectedGameIsSeamless(game["isSeamless"]);
                                                setIsSupportIframeState(game["isSupportIframe"]);

                                                if (isApp && !stringIsNullOrEmpty(appUrl)) {
                                                  setAppUrl(game["appUrl"]);
                                                }
                                                setProductGameId(game["id"]);
                                                setIsIframeAlert(true);
                                              }

                                            },
                                          })
                                        );
                                      } else {
                                        setIsSupportIframeState(game["isSupportIframe"])
                                        if (game["isSupportIframe"]) {
                                          startGame(
                                            game["id"],
                                            game["hasOwnLobby"],
                                            game["masterProductId"],
                                            game["gameName"],
                                            game["masterProductIsSeamless"],
                                            game["isApp"],
                                            game["appUrl"],
                                            game["appDeepLink"],
                                            game["isNewWindow"],
                                            game["packageNameKeyword"],
                                            game["isSupportIframe"]
                                          );
                                        } else {
                                          setSelectedGameName(game["gameName"]);
                                          setMasterProductId(game["masterProductId"]);
                                          setIsApp(game["isApp"]);
                                          setAppDeepLink(game["appDeepLink"]);
                                          setPackageNameKeyword(game["packageNameKeyword"]);
                                          setSelectedGameHasOwnLobby(game["hasOwnLobby"]);
                                          setSelectedGameIsSeamless(game["isSeamless"]);
                                          setIsSupportIframeState(game["isSupportIframe"]);

                                          if (isApp && !stringIsNullOrEmpty(appUrl)) {
                                            setAppUrl(game["appUrl"]);
                                          }
                                          setProductGameId(game["id"]);
                                          setIsIframeAlert(true);
                                        }

                                      }
                                    }
                                  } else {
                                    _dispatch(setLoginModal(true));
                                  }
                                }
                              }}
                            >
                              <div className="t3-game-list-image-box">
                                <div className="image">
                                  <div className="first">
                                    <FallBackImage
                                      src={game.image}
                                      alt={game.gameName}
                                      fallbackSrc={defaultGameIcon}
                                    />
                                  </div>
                                  <div className="second">
                                    <svg
                                      className="play-icon"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 63 63"
                                    >
                                      <defs></defs>
                                      <g fill="none" fillRule="evenodd">
                                        <circle
                                          cx="31.3"
                                          cy="31.3"
                                          r="30.3"
                                          fill="currentColor"
                                          fillOpacity=".2"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        ></circle>
                                        <path
                                          fill="currentColor"
                                          d="M39.5 34.3l-11.3 7.5a2 2 0 01-3-1.6v-15a2 2 0 013-1.7L39.5 31a2 2 0 010 3.3z"
                                        ></path>
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="t3-game-list-text-box">
                                <h6>{game["gameName"]}</h6>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
            </Slider>
          </div>
        </div>
      }
    </>
  )

}

export default RecommendedCasino