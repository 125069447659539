import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ApiEngine from "../../util/ApiEngine.js";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  _MAIN_WALLET_ID,
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import {
  filter,
} from "lodash";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import {
  stringIsNullOrEmpty,
} from "../../util/Util";
import FallBackImage from "components/template_1/FallBackImage";
import {
  setGameLobbyModal,
  setLoginModal,
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import Slider from "react-slick";
import GameLobbyModal from "components/template_1/GameLobbyModal";


// RecentBigWin component
const RecentBigWin = ({ updateMasterProductId, updateSelectedGameIsSeamless, updateSelectedGameName, updateProductGameId,
  updatePopBannerImage, updateTitle, updateCategoryTitle, UpdateIsSupportIframe, updateProviderName, pageName }) => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _dispatch = useDispatch();

  var { heartBeat } = useSelector((state) => state.authState);
  const memberData = useSelector((state) => state.appState.member);
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [selectedGameName, setSelectedGameName] = useState("");
  const [masterProductId, setMasterProductId] = useState(0);
  const [isApp, setIsApp] = useState(false);
  const [appDeepLink, setAppDeepLink] = useState("");
  const [packageNameKeyword, setPackageNameKeyword] = useState("");
  const [selectedGameHasOwnLobby, setSelectedGameHasOwnLobby] = useState(false);
  const [selectedGameIsSeamless, setSelectedGameIsSeamless] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [bonus, setBonus] = useState(0);
  const [commission, setCommission] = useState(0);
  const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
    useState(0);
  const [masterProductContent, setMasterProductContent] = useState("");
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [showPregamePopup, setShowPregamePopup] = useState(false);
  const [productGameId, setProductGameId] = useState(0);
  const [toLobbyBtn, SetToLobbyBtn] = useState(false);
  const [appUrl, setAppUrl] = useState("");
  const [clubFee, setClubFee] = useState(0);
  const [initFlag, setInitFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultGameIcon, setDefaultGameIcon] = useState();
  const [bigWinList, setBigWinList] = useState([]);
  const companyCurrencySymbol = useSelector((state) => state.appState.currencySymbol);
  const [isSupportIframeState, setIsSupportIframeState] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [iframeAlert, setIsIframeAlert] = useState(false);
  const [providerName, setProviderName] = useState("");
  const companyIcons = useSelector((state) => state.appState.companyIcons);
  const [rolloverBalance, setRolloverBalance] = useState("");
  const [rolloverTarget, setRolloverTarget] = useState("");
  const [productCommRate, setProductCommRate] = useState([]);
  const [isGameLobbyModal, setIsGameLobbyModal] = useState(false);

  const chunkArray = (arr, chunkSize) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  const itemsPerSlide = 2; // Two columns and three rows
  const slides = chunkArray(bigWinList, itemsPerSlide);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slides.length > 2 ? 3 : 2, // Show one slide at a time
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: false,
    autoplay: true, // Enable auto-slide
    autoplaySpeed: 3000, // Time in milliseconds between slides
    arrows: false, // Hide navigation arrows
    responsive: [
      {

        breakpoint: 768,
        settings: {
          className: "",

          variableWidth: false,

          slidesToShow: 2,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: false,
          autoplay: true, // Enable auto-slide
          autoplaySpeed: 3000, // Time in milliseconds between slides
          arrows: false, // Hide navigation arrows

        },
      }
    ]
  };

  useEffect(() => {
    getCompanyIcon()
    getBigWin()

  }, []);


  const getBigWin = async () => {
    var responseJsonBigWin = await ApiEngine.get(
      ApiUrl._API_GET_BIG_WIN
    );
    if (responseJsonBigWin[ApiKey._API_SUCCESS_KEY]) {

      var bigWinResult = responseJsonBigWin[ApiKey._API_DATA_KEY];

      if (bigWinResult.length % 2 !== 0) {
        bigWinResult.pop();
      }
      setBigWinList(bigWinResult)
    }

    setInitFlag(true)
  }

  async function startGame(
    gameId,
    hasOwnLobby,
    masterProductId,
    gameName,
    isSeamless,
    isApp,
    appUrl,
    appDeepLink,
    isNewWindow,
    packageNameKeyword,
    isSupportIframe,
    categoryTitle,
    providerName,
    popoutBanner
  ) {
    setSelectedGameName(gameName);
    setMasterProductId(masterProductId);
    setIsApp(isApp);
    setAppDeepLink(appDeepLink);
    setPackageNameKeyword(packageNameKeyword);
    setSelectedGameHasOwnLobby(hasOwnLobby);
    setSelectedGameIsSeamless(isSeamless);
    setIsSupportIframeState(isSupportIframe);
    setCategoryTitle(categoryTitle);
    setProviderName(providerName);

    if (pageName == "HOME") {
      updateMasterProductId(masterProductId);
      updateSelectedGameIsSeamless(isSeamless);
      updateSelectedGameName(gameName);
      updateProductGameId(gameId);
      updatePopBannerImage(popoutBanner);
      updateTitle(t("HOME"))
      updateCategoryTitle(categoryTitle);
      UpdateIsSupportIframe(isSupportIframe);
      updateProviderName(providerName);
    }

    if (isApp && !stringIsNullOrEmpty(appUrl)) {
      setAppUrl(appUrl);
    }
    if (hasOwnLobby !== true) {
      if (isSeamless == false) {
        setProductGameId(gameId);
        _dispatch(setGameLobbyModal(true));
        setIsGameLobbyModal(true);
      } else {
        if (isSupportIframe) {
          var breadcrumbData = [{
            pageName: t("HOME"),
            location: _history.location.pathname + _history.location.search,
            state: _history.location.state
          }];

          var path = WebUrl._URL_IFRAME_GAME_PAGE + "?gameId=" + gameId;
          _history.push(path, {
            breadcrumbData: breadcrumbData
          });
        } else {
          window.open(
            ApiUrl._API_START +
            "?gameId=" +
            gameId + "&device=" + (window.innerWidth <= 768 ? "m" : "d")
          );
        }

      }
    } else {
      if (isSeamless == false) {
        setIsLoading(true);
        var responseJson = await ApiEngine.get(
          ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
          "?masterProductId=" +
          masterProductId
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setWalletList(responseJson[ApiKey._API_DATA_KEY]);

          let tempFromOptions = [];
          let tempToOptions = [];

          responseJson[ApiKey._API_DATA_KEY].map((product) => {
            let option = {
              label: product.productName,
              value: product.productId,
              amount: parseFloat(product.balance),
            };
            if (product.productId === _MAIN_WALLET_ID) {
              tempFromOptions.push(option);
            } else if (product.productId === masterProductId) {
              setBonus(product.bonus);
              setCommission(product.commission);
              if (product.productName === "D2 Poker") {
                setClubFee(product.clubFee);
              }
              setGameWalletBalanceBeforeTransfer(product.balance);
              if (product.masterProductContent !== null) {
                setMasterProductContent(product.masterProductContent);
              } else {
                setMasterProductContent("");
              }
              tempToOptions.push(option);
            }
          });
          setFromOptions(tempFromOptions);
          setToOptions(tempToOptions);

        }

        setShowPregamePopup(true);
        getProductComRate(gameName);
        setProductGameId(gameId);
        SetToLobbyBtn(true);
        getRolloverProgress(gameId);

        var path = WebUrl._URL_GAME_LOBBY +
          "?name=" +
          gameName +
          "&id=" +
          gameId +
          "&languageId=" +
          memberData["languageSetting"] +
          "&category=" +
          categoryTitle;

        var breadcrumbData = [{
          pageName: t('HOME'),
          location: _history.location.pathname + _history.location.search,
          state: _history.location.state
        }, {
          pageName: gameName,
          location: path
        }];

        var state = {
          breadcrumbData: breadcrumbData
        }

        _history.push(path, state);
        setIsLoading(false);
      }
    }
  }

  async function getProductComRate(selectedGameName) {
    let apiUrl = ApiUrl._API_PRODUCT_COMM_RATE;
    let data = [];
    let responseJson = await ApiEngine.get(apiUrl);
    if (
      responseJson[ApiKey._API_SUCCESS_KEY] &&
      responseJson[ApiKey._API_DATA_KEY].length > 0
    ) {
      data = responseJson[ApiKey._API_DATA_KEY].filter(
        (x) => x.productName === selectedGameName
      );

      if (data.length > 0) {
        setProductCommRate(data[0]);
      }
    }
  }

  async function getRolloverProgress(masterProductId) {
    setRolloverBalance("");
    setRolloverTarget("");
    if (masterProductId != "0" && !stringIsNullOrEmpty(masterProductId)) {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_DEPOSIT_PROMOTION_ROLLOVER_PROGRESS +
        "?masterProductId=" +
        masterProductId
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        if (responseJson[ApiKey._API_DATA_KEY] != null) {
          if (responseJson[ApiKey._API_DATA_KEY].data != null) {
            setRolloverBalance(
              responseJson[ApiKey._API_DATA_KEY].data["rolloverBalance"]
            );
            setRolloverTarget(
              responseJson[ApiKey._API_DATA_KEY].data["rolloverTarget"]
            );
          }
        }
      }
    }
  }

  async function getCompanyIcon() {
    _dispatch(setBusy());

    const defaultGameIconTemp = filter(companyIcons, (icon) => {
      return icon.iconCategory === "default_game_icon";
    });
    if (defaultGameIconTemp) {
      setDefaultGameIcon(defaultGameIconTemp[0]?.iconImage||defaultGameIconTemp[0]?.defaultImage);
    }

    _dispatch(setIdle());
  }
  

  return (
    <>
      {initFlag ? (
        <>
          {bigWinList && bigWinList.length > 0 && (
            <div className="bigwin payout-container mt-4">
              <div>
                <h3 className="t3-custom-second-title mb-2">
                  <img className="gif" src={require("../../assets/img/trophy-gif.gif")} />{t("RECENT")} <span>{t("BIG_WINS")}</span>
                </h3>
                <div className="payout-list">
                  <Slider {...settings}>
                    {slides.map((slide, index) => (
                      <div key={index} className="slider-slide">
                        {slide.map((item, index) => (
                          <div key={index} className="slider-item">
                            <FallBackImage
                              src={item.image}
                              alt={item.productName}
                              fallbackSrc={defaultGameIcon}
                            />

                            <div className="slider-item-item">
                              <div>
                                <div className="title">{t("PROVIDER")}: {item.productName}</div>
                                <div className="username">{item.username} {t("in")} <span>{item.gameName}</span> {t("won")}</div>
                                <div className="amount">{companyCurrencySymbol}{item.winning.toFixed(2)}</div>
                              </div>
                              <div className="play-button" onClick={() => {
                                if (heartBeat) {
                                  setPopupBannerImage(item["popupBannerImage"]);
                                  if (item["gameUm"] !== true) {
                                    setIsSupportIframeState(item["isSupportIframe"])
                                    if (item["isSupportIframe"]) {
                                      startGame(
                                        item["id"],
                                        item["hasOwnLobby"],
                                        item["masterProductId"],
                                        item["gameName"],
                                        item["masterProductIsSeamless"],
                                        item["isApp"],
                                        item["appUrl"],
                                        item["appDeepLink"],
                                        item["isNewWindow"],
                                        item["packageNameKeyword"],
                                        item["isSupportIframe"],
                                        item["categoryTitle"],
                                        item["providerName"],
                                        item["popupBannerImage"]
                                      );
                                    } else {
                                      setSelectedGameName(item["gameName"]);
                                      setMasterProductId(item["masterProductId"]);
                                      setIsApp(item["isApp"]);
                                      setAppDeepLink(item["appDeepLink"]);
                                      setPackageNameKeyword(item["packageNameKeyword"]);
                                      setSelectedGameHasOwnLobby(item["hasOwnLobby"]);
                                      setSelectedGameIsSeamless(item["isSeamless"]);
                                      setIsSupportIframeState(item["isSupportIframe"]);

                                      if (isApp && !stringIsNullOrEmpty(appUrl)) {
                                        setAppUrl(item["appUrl"]);
                                      }
                                      setProductGameId(item["id"]);
                                      setIsIframeAlert(true)
                                    }

                                  }
                                } else {
                                  _dispatch(setLoginModal(true));


                                }
                              }}>
                                <svg
                                  className="btn-play-icon-svg"
                                  width="50"
                                  height="50"
                                  viewBox="0 0 50 50"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M25 0C11.1931 0 0 11.1929 0 25C0 38.8071 11.1931 50 25 50C38.8069 50 50 38.8071 50 25C50 11.1929 38.8069 0 25 0ZM33.6406 26.3252L21.1406 34.1377C20.8877 34.2956 20.6001 34.375 20.3125 34.375C20.052 34.375 19.791 34.3102 19.5549 34.1789C19.0582 33.9035 18.75 33.3809 18.75 32.8125V17.1875C18.75 16.6191 19.0582 16.0965 19.5549 15.8211C20.0516 15.5441 20.6589 15.5609 21.1406 15.8623L33.6406 23.6748C34.0973 23.9609 34.375 24.4614 34.375 25C34.375 25.5386 34.0973 26.0392 33.6406 26.3252Z" />
                                </svg>
                              </div>
                            </div>

                          </div>
                        ))}
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Skeleton className="mt-4" height={400} baseColor="var(--skeleton-base-color)" highlightColor="var(--skeleton-highlight-color)" />
      )
      }
      {pageName != "HOME" &&
        <GameLobbyModal
          masterProductId={masterProductId}
          selectedGameIsSeamless={selectedGameIsSeamless}
          selectedGameName={selectedGameName}
          productGameId={productGameId}
          popupBannerImage={popupBannerImage}
          title={t("HOME")}
          category={categoryTitle}
          isSupportIframe={isSupportIframeState}
          providerName={providerName}
        />
      }
    </>
  )
}

export default RecentBigWin;