import { themeIcons } from "config/theme";
import Deposit from "pages/template_1/transaction/Deposit";
import Downline from "pages/template_1/home/Downline";
import ReferralBonus from "pages/template_1/report/ReferralBonus";
import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import i18n from "localization/i18n";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody } from "reactstrap";
import VIcon from "components/custom/Icon";
import { useDispatch, useSelector } from "react-redux";
import { setReferralBonusModal, setDepositTab, setDownlineModal } from "redux/AppAction";


const ReferralBonusModal = (props) => {
  var { t } = useTranslation();
  const isDownlineModal = useSelector(
    (state) => state.appState.referralBonusModal
  );

  var _dispatch = useDispatch();

  useEffect(() => {
  }, []);

  useEffect(() => {
  }, [isDownlineModal]);


  return (
    <Modal
      id="t3-custom-modal-withdraw"
      fade={false}
      isOpen={isDownlineModal}
      backdrop={'static'}
      toggle={() => {
        // setShowModal(false);
        _dispatch(setReferralBonusModal(!isDownlineModal));

      }}
    //   centered
    >
      <ModalBody>
        <div className="t3-custom-modal-withdraw-container t3-modal-bg-dark">
          <div className="t3-cms-header">
            <div className="first">
              <div>
                {t("REFERRAL_BONUS")}
              </div>
            </div>
            <div className="second">
              <div
                className="t3-close-custom-modal"
                onClick={() => {
                  // setShowModal(!showModal)
                  _dispatch(setReferralBonusModal(!isDownlineModal));

                }}
              >
                <VIcon icon={themeIcons.close} className="vicon" />
              </div>
            </div>
          </div>
          <ReferralBonus/>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ReferralBonusModal;
