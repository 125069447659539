import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ApiKey, ApiUrl, WebUrl } from "../../../util/Constant";
import ApiEngine from "../../../util/ApiEngine";
import {
	setBusy,
	setIdle,
	showMessage,
	showResponseMessage,
	bindCompanyIcon,
} from "../../../redux/AppAction";
import {
	stringIsNullOrEmpty,
	numberWithCurrencyFormat,
} from "../../../util/Util";
import { map, orderBy } from "lodash";
import DateRangeFilter from "components/custom/DateRangeFilter";
import PaginationBar from "../../../components/custom/PaginationBar";
import Spinner from "react-bootstrap/Spinner";

const DailyCheckInHistory = () => {
	let _history = useHistory();
	let _location = useLocation();
	const _dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const [paginationPageNum, setPaginationPageNum] = useState(0);
	const [isResetCurrentPage, setIsResetCurrentPage] = useState(false);
	const [sortOrder, setSortOrder] = useState("");
	const [loadingFlag, setLoadingFlag] = useState(true);
 	const [dailyRecordData, setDailyRecordData] = useState([]);

	const [startDate, setStartDate] = useState(
		_location?.state?.date
			? moment(_location.state.date.split(t("TO"))[0], "DD-MM-YYYY").format(
					"YYYY-MM-DD"
			  )
			: moment().startOf("week").format("YYYY-MM-DD")
	);
	const [endDate, setEndDate] = useState(
		_location?.state?.date
			? moment(_location.state.date.split(t("TO"))[1], "DD-MM-YYYY").format(
					"YYYY-MM-DD"
			  )
			: moment().endOf("week").format("YYYY-MM-DD")
	);
	const [commissionUrl, setCommissionUrl] = useState("");
	const [selectedFilterType, setSelectedFilterType] = useState(
		_location?.state?.date
			? _location.state.date
			: moment().startOf("week").format("DD-MM-YYYY") +
					t("TO") +
					moment().endOf("week").format("DD-MM-YYYY")
	);

	const filterTypeOnSelect = (value) => {
		setSelectedFilterType(value);
	};

	useEffect(() => {
		// getCommissionData();
		getDailyCheckInData();
	}, [startDate, endDate]);

	// useEffect(() => {
	// 	if (isResetCurrentPage) {
	// 		setSortOrder("");
	// 	}
	// }, [isResetCurrentPage]);

	// useEffect(() => {
	// 	if (sortOrder != "") {
	// 		var commData = commissionData;
	// 		var sortedCommissionMemberData = orderBy(commData, ["amount"], sortOrder);

	// 		setCommissionData(sortedCommissionMemberData);
	// 	}
	// }, [sortOrder]);

	async function getDailyCheckInData(){
		setDailyRecordData([]);
		setLoadingFlag(true);
		try{
			// page = page ? page : 1;
			// var start = (page - 1) * 10;

			var apiUrl =
				ApiUrl._API_GET_DAILY_CHECK_IN_RECORD +
				"?StartDate=" +
				moment(startDate).startOf("day").format("YYYY-MM-DD") +
				"&EndDate=" +
				moment(endDate).endOf("day").format("YYYY-MM-DD");

			apiUrl = apiUrl;

			var responseJson = await ApiEngine.get(apiUrl);
			console.log(responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY])

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setDailyRecordData(
					responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]
				);

				// var totalCount = responseJson[ApiKey._API_DATA_KEY].totalCount;
				// updatePageinationPageNum(Math.ceil(totalCount / 10));
			} else {
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);
			}

		}catch(err){
			_dispatch(showResponseMessage(false, err));

		}
		setLoadingFlag(false);


	}
	// async function getCommissionData(page, keyword) {
	// 	setCommissionData([]);
	// 	setLoadingFlag(true);
	// 	try {
	// 		page = page ? page : 1;
	// 		var start = (page - 1) * 10;

	// 		var apiUrl =
	// 			ApiUrl._API_GET_REBATES_RECORD +
	// 			"?StartDate=" +
	// 			moment(startDate).startOf("day").format("YYYY-MM-DD") +
	// 			"&EndDate=" +
	// 			moment(endDate).endOf("day").format("YYYY-MM-DD") +
	// 			"&v=" +
	// 			moment(startDate).startOf("day").format("YYYY-MM-DD");

	// 		apiUrl = apiUrl + "&start=" + start + "&count=10";

	// 		setCommissionUrl(apiUrl);

	// 		var responseJson = await ApiEngine.get(apiUrl);

	// 		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
	// 			setCommissionData(
	// 				responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]
	// 			);

	// 			var totalCount = responseJson[ApiKey._API_DATA_KEY].totalCount;
	// 			updatePageinationPageNum(Math.ceil(totalCount / 10));
	// 		} else {
	// 			_dispatch(
	// 				showMessage(
	// 					responseJson[ApiKey._API_SUCCESS_KEY],
	// 					t(responseJson[ApiKey._API_MESSAGE_KEY])
	// 				)
	// 			);
	// 		}
	// 	} catch (err) {
	// 		_dispatch(showResponseMessage(false, err));
	// 	}
	// 	setLoadingFlag(false);
	// 	return;
	// }

	// function updatePageinationPageNum(pageValue) {
	// 	setPaginationPageNum((prevValue) => pageValue);
	// }

	return (
		<div className="t3-downline-modal-body pt-0 mb-4" id="deposit-id">
			<h3 className="t3-h3 text-left mt-0">{t("DAILY_CHECK_IN")}</h3>
			<DateRangeFilter
				// isOpen={isDateFilterOpen}
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				onSave={(start, end) => {
					setStartDate(start);
					setEndDate(end);
				}}
				options={true}
				initialType={selectedFilterType}
				// onClose={dateRangePickerOnClose}
				typeOnSelect={filterTypeOnSelect}
			/>
			<div className="t3-downline-table-body">
				<div className="t3-table-container" style={{maxHeight:"300px"}}>
					<table className="t3-custom-table">
						<thead>
							<tr>
								<th>{t("DATE")}</th>
								<th>{t("DAYS")}</th>
								<th>{t("ROLLOVER")}</th>
								<th>{t("AMOUNT")}</th>
							</tr>
						</thead>
						<tbody>
							{dailyRecordData && dailyRecordData.length != 0 ? (
								dailyRecordData.map((data, index) => {
									return (
										<tr key={index}>
											<td className="downline-table-username">
												{moment(data.date).format("DD-MM-YYY")}
											</td>
											<td>
												{data.days}
											</td>
											<td>
												{data.rolloverMultiplier}
											</td>
											<td>
												{numberWithCurrencyFormat(
													parseFloat(data.amount),
													3,
													true
												)}
											</td>
										</tr>
									);
								})
							) : loadingFlag ? (
								<tr>
									<td colSpan={4}>
										<div
											style={{
												textAlign: "center",
												marginTop: "25px",
												marginBottom: "20px",
											}}
										>
											<Spinner
												animation="border"
												role="status"
												size="sm"
												style={{
													verticalAlign: "middle",
													width: "2rem",
													height: "2rem",
												}}
											>
												<span className="sr-only">{t("LOADING")}...</span>
											</Spinner>
										</div>
									</td>
								</tr>
							) : (
								<tr>
									<td colSpan={4}>{t("NO_DATA_FOUND")}</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		
		</div>
	);
};

export default DailyCheckInHistory;
