import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  ApiKey,
  ApiUrl,
  _MAIN_WALLET_ID,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import {
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import {
  map,
  filter,
} from "lodash";
import Slider from "react-slick";
import SwiperCore, { Autoplay } from 'swiper';
import FallBackImage from "components/template_1/FallBackImage";

const RecentPayout = (props) => {
  var { t } = useTranslation();
  var _dispatch = useDispatch();

  // Swiper Plugins
  SwiperCore.use([Autoplay]);

  const companyIcons = useSelector((state) => state.appState.companyIcons);
  const [payoutList, setPayoutList] = useState([]);
  const [defaultGameIcon, setDefaultGameIcon] = useState();
  const companyCurrencySymbol = useSelector((state) => state.appState.currencySymbol);

  const payoutSlick = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    // centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    draggable: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          className: "",
          variableWidth: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
          draggable: false,
          autoplay: true,
          touchMove: false,
        },
      }
    ]
  };

  useEffect(() => {
    getCompanyIcon()
    getRecentPayout();
  }, []);


  const getRecentPayout = async () => {
    var responseJsonPayout = await ApiEngine.get(
      ApiUrl._API_GET_RECENT_PAYOUT
    );
    if (responseJsonPayout[ApiKey._API_SUCCESS_KEY]) {
      setPayoutList(responseJsonPayout[ApiKey._API_DATA_KEY])
    }
  }

  async function getCompanyIcon() {
    _dispatch(setBusy());
    
    const defaultGameIconTemp = filter(companyIcons, (icon) => {
      return icon.iconCategory === "default_game_icon";
    });
    if (defaultGameIconTemp) {
      setDefaultGameIcon(defaultGameIconTemp[0]?.iconImage||defaultGameIconTemp[0]?.defaultImage);
    }

    _dispatch(setIdle());
  }

  return (
    <>
      {
        payoutList?.length > 0 && (
          <div className="payout-container mt-4">
            <div>
              <h3 className="t3-custom-second-title mb-2">
                <img className="gif" src={require("../../assets/img/trophy-gif.gif")} /><span>{t("RECENT")}</span> {t("PAYOUT")}
              </h3>
              <div className="payout-list">
                <Slider {...payoutSlick}>
                  {
                    map(payoutList, (item, index) => {
                      return (
                        <div key={index}>
                          <div className="item">
                            <div className="payout-list-image">
                              <FallBackImage
                                src={item.image}
                                alt={item.username}
                                fallbackSrc={defaultGameIcon}
                              />
                            </div>
                            {/* <img src={item.image} /> */}
                            <div className="payout-list-details">
                              <div className="title">{item.game}</div>
                              <div className="username">{item.username}</div>
                              <div className="amount">{companyCurrencySymbol}{item.winning.toFixed(2)}</div>
                            </div>

                          </div>

                        </div>
                      )
                    })
                  }

                </Slider>

              </div>
            </div>
          </div>
        )
      }
    </>
    )
}

export default RecentPayout;