import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setMusicPlayerModal } from "../../redux/AppAction.js";
import MusicPlayerModal from "../template_1/MusicPlayerModal";

const MusicPlayer = (props) => {
    var {
        musicTrackList,
        setTrackTitle,
        setIsTrackLoading,
        setTrackProgress,
        setIsTrackPlaying,
        clickShuffleBtn,
        setClickShuffleBtn,
        shuffleEnabled,
        setShuffleEnabled,
        isPlayNextTrack,
        setIsPlayNextTrack,
        isPlayPrevTrack,
        setIsPlayPrevTrack,
        playButtonClicked,
        setPlayBtnClicked
    } = props;
    var _dispatch = useDispatch();
    var { heartBeat } = useSelector((state) => state.authState);
    var memberData = useSelector((state) => state.appState.member);

    const [systemMusicPlayList, setSystemMusicPlayList] = useState(musicTrackList);
    const [memberFavoritePlaylist, setMemberFavoritePlaylist] = useState([]);
    const [currentTrack, setCurrentTrack] = useState(null);
    const [currentTrackIndex, setCurrentTrackIndex] = useState(() => {
        const savedState = sessionStorage.getItem('currentTrackIndex');
        return savedState ? parseInt(savedState, 10) : 0; // Default to 0 if no value exists
    });
    const [playing, setPlaying] = useState(() => {
        const savedState = sessionStorage.getItem('playing');
        return savedState === 'true';
    });
    const [isShuffle, setIsShuffle] = useState(() => {
        const savedState = sessionStorage.getItem('isShuffle');
        return savedState === 'true';
    });

    const [loop, setLoop] = useState(() => {
        const savedState = sessionStorage.getItem('loop');
        return savedState === 'true';
    });

    const [muted, setMuted] = useState(false);
    const [seeking, setSeeking] = useState(false);
    const [duration, setDuration] = useState(false);
    const [progress, setProgress] = useState(0);
    const [played, setPlayed] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPlayListType, setCurrentPlayListType] = useState(null);
    const [initFlag, setInitFlag] = useState(true);

    const iconTheme = "music-player-contorl-btn-icon";
    const [playList, setPlayList] = useState(musicTrackList);
    const [shuffleBtnClicked, setShuffleBtnClicked] = useState(false);
    const [isMobile, setIsMobile] = useState(true);
    const [mobileOS, setMobileOS] = useState("");
    const [turnOnPlay, setTurnOnPlay] = useState(false);

    var playerRef = useRef(null);

    useEffect(() => {
        setPlayList(musicTrackList);
        setCurrentTrack(musicTrackList[currentTrackIndex]);
        setCurrentPlayListType("system");
        setIsMobile(window.innerWidth < 900);
        detectMobileOS();
    }, []);

    useEffect(() => {
        if (heartBeat && initFlag) {
            var memberFavoriteTrackList = memberData.memberFavoriteTrackList;
            if (memberFavoriteTrackList && memberFavoriteTrackList.length) {
                setMemberFavoritePlaylist(memberFavoriteTrackList);
                setPlayList(memberFavoriteTrackList);
                setCurrentTrack(memberFavoriteTrackList[0]);
                setCurrentPlayListType("member");

                systemMusicPlayList.forEach(track => {
                    // Check if the track's id exists in `memberTracks`
                    const memberTrack = memberFavoriteTrackList.find(memberTrack => memberTrack.id === track.id);
                    if (memberTrack) {
                        track.favorite = true; // Update favorite status if track exists in `memberTracks`
                    }
                });

                setInitFlag(false);
            }
        }
        else if (!memberData.memberFavoriteTrackList) {
            if (currentPlayListType == "member") {
                setMemberFavoritePlaylist([]);
                setPlayList(systemMusicPlayList);
                setCurrentTrack(systemMusicPlayList[0]);
                setCurrentPlayListType("system");
            }
            setInitFlag(true);
        }

    }, [heartBeat, memberData]);

    useEffect(() => {
        if (shuffleBtnClicked) {
            if (isShuffle) {
                // Generate a shuffled playlist when shuffle mode is enabled
                var shuffledSystemPlaylist = shufflePlaylist(systemMusicPlayList);
                setSystemMusicPlayList(shuffledSystemPlaylist);

                var shuffledMemberFavoritePlaylist = shufflePlaylist(memberFavoritePlaylist)
                setMemberFavoritePlaylist(shuffledMemberFavoritePlaylist);

                setPlayList(currentPlayListType == "system" ? shuffledSystemPlaylist : shuffledMemberFavoritePlaylist);
                if (currentTrack) {
                    setNewCurrentTrackIndex(shuffledSystemPlaylist, shuffledMemberFavoritePlaylist);
                }

            } else {
                setSystemMusicPlayList([...musicTrackList]);
                var memberFavoriteList = memberData.memberFavoriteTrackList ? memberData.memberFavoriteTrackList : [];
                setMemberFavoritePlaylist(memberFavoriteList);
                setPlayList(currentPlayListType == "system" ? musicTrackList : memberFavoriteList);

                if (currentTrack) {
                    setNewCurrentTrackIndex(musicTrackList, memberFavoriteList);
                }
            }

            sessionStorage.setItem('isShuffle', isShuffle);
            setShuffleBtnClicked(false);
            setShuffleEnabled(isShuffle);
        }

    }, [shuffleBtnClicked]);

    useEffect(() => {
        sessionStorage.setItem('currentTrackIndex', currentTrackIndex);
    }, [currentTrackIndex]);

    useEffect(() => {
        if (currentTrack) {
            setTrackTitle(currentTrack.title);
        }
    }, [currentTrack]);

    useEffect(() => {
        if (playButtonClicked) {
            handlePlayPause();
            setPlayBtnClicked(false);
        }
    }, [playButtonClicked]);

    useEffect(() => {
        if (clickShuffleBtn) {
            if (shuffleEnabled) {
                // Generate a shuffled playlist when shuffle mode is enabled
                var shuffledSystemPlaylist = shufflePlaylist(systemMusicPlayList);
                setSystemMusicPlayList(shuffledSystemPlaylist);

                var shuffledMemberFavoritePlaylist = shufflePlaylist(memberFavoritePlaylist)
                setMemberFavoritePlaylist(shuffledMemberFavoritePlaylist);

                setPlayList(currentPlayListType == "system" ? shuffledSystemPlaylist : shuffledMemberFavoritePlaylist);

                if (currentTrack) {
                    setNewCurrentTrackIndex(shuffledSystemPlaylist, shuffledMemberFavoritePlaylist);
                }

            } else {
                setSystemMusicPlayList([...musicTrackList]);
                var memberFavoriteList = memberData.memberFavoriteTrackList ? memberData.memberFavoriteTrackList : [];
                setMemberFavoritePlaylist(memberFavoriteList);
                setPlayList(currentPlayListType == "system" ? musicTrackList : memberFavoriteList);

                if (currentTrack) {
                    setNewCurrentTrackIndex(musicTrackList, memberFavoriteList);
                }
            }

            sessionStorage.setItem('isShuffle', shuffleEnabled);
            setClickShuffleBtn(false);
            setIsShuffle(shuffleEnabled);
        }
    }, [clickShuffleBtn]);

    useEffect(() => {
        if (isPlayNextTrack) {
            handleNext();
            setIsPlayNextTrack(false);
        }
    }, [isPlayNextTrack]);

    useEffect(() => {
        if (isPlayPrevTrack) {
            handlePrevious();
            setIsPlayPrevTrack(false);
        }
    }, [isPlayPrevTrack]);

    const handleTrackLoading = () => {
        setIsLoading(true);
        setIsTrackLoading(true);
        setProgress(0);
        setTrackProgress(0);
    }

    const setNewCurrentTrackIndex = (systemPlayList, memberPlayList) => {
        var newIndex = currentPlayListType == "system"
            ? systemPlayList.findIndex((x) => x.id == currentTrack.id)
            : memberPlayList.findIndex((x) => x.id == currentTrack.id);

        setCurrentTrackIndex(newIndex);
    }

    //handle next music
    const handleNext = () => {
        var nextIndex = (currentTrackIndex + 1) % playList.length;
        var isSameTrack = playList[nextIndex]?.id == currentTrack.id;

        if (!isSameTrack) {
            handleTrackLoading();
            setCurrentTrack(playList[nextIndex]);
            setCurrentTrackIndex(nextIndex); // Loop back to the first track
        }
    };

    //handle previous music
    const handlePrevious = () => {
        var prevIndex = (currentTrackIndex - 1 + playList.length) % playList.length;
        var isSameTrack = playList[prevIndex]?.id == currentTrack.id;

        if (!isSameTrack) {
            handleTrackLoading();
            setCurrentTrack(playList[prevIndex]);
            setCurrentTrackIndex(prevIndex); // Loop back to the last track
        }
    };

    const handlePlayPause = () => {
        setPlaying(!playing);
        setIsTrackPlaying(!playing);
        sessionStorage.setItem('playing', !playing);
    };

    const handlePlay = () => {
        setPlaying(true);
    };

    const handleBuffer = () => {
        console.log("bufferring...");
    };

    const handleBufferEnd = () => {
        console.log(playerRef.current.getInternalPlayer());
        if (isMobile && mobileOS === "iOS" && turnOnPlay) {
            console.log("forcePlay");
            setPlaying(true);
            setTurnOnPlay(false);
            if (playerRef.current.getInternalPlayer().playVideo) {
                playerRef.current.getInternalPlayer().playVideo();
            }
        }
    };

    // Handle song end
    const handleEnded = () => {
        handleTrackLoading();
        var nextIndex = currentTrackIndex + 1 < playList.length ? currentTrackIndex + 1 : 0; // Loop back to the start of the playlist
        setCurrentTrackIndex(nextIndex);
        setCurrentTrack(playList[nextIndex]);
    };

    // Function to shuffle the playlist
    const shufflePlaylist = (playlist) => {
        return [...playlist].sort(() => Math.random() - 0.5);
    };

    const handleDuration = (duration) => {
        setDuration(duration); // Set duration in seconds
    };

    const handleProgress = (state) => {
        if (!seeking) {
            setTrackProgress(state.playedSeconds);
            setProgress(state.playedSeconds);
            setPlayed(state.played);
        }
    };

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);  // Adjust minutes calculation
        const secs = Math.floor(seconds % 60);

        if (hours > 0) {
            // If hours are present, return hours, minutes, and seconds
            return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${secs < 10 ? "0" : ""}${secs}`;
        } else {
            // If no hours, return only minutes and seconds
            return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
        }
    };

    const handleError = () => {
        console.log("have error")
        // Skip to next track if there's an error with the current track
        var nextIndex = currentTrackIndex + 1 < playList.length ? currentTrackIndex + 1 : 0; // Loop back to the start of the playlist
        setCurrentTrackIndex(nextIndex);
        setCurrentTrack(playList[nextIndex]);
    };

    const handleToggleMuted = (isMuted) => {
        setMuted(isMuted);
    };

    const handleReady = () => {
        setIsLoading(false);
        setIsTrackLoading(false);
    };

    function detectMobileOS() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Check for Android
        if (/android/i.test(userAgent)) {
            setMobileOS("Android");
            return;
        }

        // Check for iOS (iPhone, iPad, iPod)
        if (/iPhone|iPad|iPod/i.test(userAgent)) {
            setMobileOS("iOS");
            return;
        }

        // Otherwise assume desktop or unknown device
        setMobileOS("others");
    }

    return (
        <div className="music-player">
            <div
                className="first"
                onClick={() => {
                    _dispatch(setMusicPlayerModal(true))
                }}
            >
                <VIcon
                    icon={themeIcons.musicNotes}
                />
            </div>
            <div className="music-player-container">
                <ReactPlayer
                    ref={playerRef}
                    url={currentTrack?.link} // Platform URL (YouTube, SoundCloud, etc.)
                    playing={playing} // Auto-play the audio
                    controls={false}
                    volume={1}
                    width="0px" // Hide video
                    height="0px" // Hide video
                    onPlay={handlePlay}
                    muted={muted}
                    loop={loop}
                    onProgress={handleProgress}
                    onEnded={handleEnded}
                    onError={handleError}
                    onReady={handleReady}
                    onDuration={handleDuration}
                    playsinline={true}
                    onBuffer={handleBuffer}
                    onBufferEnd={handleBufferEnd}
                />
                <div className="music-player-controls-container">
                    <div
                        className="control-button"
                        onClick={handlePrevious}
                    >
                        <VIcon
                            icon={themeIcons.previousBtn}
                            className={iconTheme}
                        />
                    </div>
                    <div
                        className="control-button"
                        onClick={handlePlayPause}
                    >
                        {playing ?
                            <VIcon
                                icon={themeIcons.pauseButton}
                                className={iconTheme}
                            /> :
                            <VIcon
                                icon={themeIcons.playButton}
                                className={iconTheme}
                            />
                        }
                    </div>
                    <div
                        className="control-button"
                        onClick={handleNext}
                    >
                        <VIcon
                            icon={themeIcons.nextBtn}
                            className={iconTheme}
                        />
                    </div>
                    <div
                        className={isShuffle ? "control-button active" : "control-button"}
                        onClick={() => {
                            setShuffleBtnClicked(true);
                            setIsShuffle((prev) => !prev);
                        }}
                    >
                        <VIcon
                            icon={themeIcons.shuffleBtn}
                            className={`music-player-contorl-btn-icon ${isShuffle ? "control-active" : ""}`}
                        />
                    </div>
                    <div
                        className="control-button"
                        onClick={() => {
                            _dispatch(setMusicPlayerModal(true));
                        }}
                    >
                        <VIcon
                            icon={themeIcons.musicPlaylist}
                            className={iconTheme}
                        />
                    </div>
                </div>
                <div className="music-track-info">
                    <VIcon
                        icon={themeIcons.musicNotes}
                    />
                    <div className="track-title">
                        {currentTrack?.title}
                    </div>
                    <div className="audio-time">
                        {isLoading ? (
                            <Spinner
                                className="dynamic-table-spinner"
                                animation="border"
                                role="status"
                                size="sm"
                                style={{
                                    verticalAlign: "middle",
                                    width: "8px",
                                    height: "8px",
                                    color: "#fff"
                                }}
                            ></Spinner>
                        ) : (
                            formatTime(progress)
                        )}
                    </div>
                </div>
            </div>
            <MusicPlayerModal
                systemMusicTracks={systemMusicPlayList}
                setSystemMusicPlayList={setSystemMusicPlayList}
                memberFavoriteTracks={memberFavoritePlaylist}
                setMemberFavoritePlaylist={setMemberFavoritePlaylist}
                setPlayList={setPlayList}
                playerRef={playerRef}
                playing={playing}
                setPlaying={setPlaying}
                currentTrack={currentTrack}
                setCurrentTrack={setCurrentTrack}
                currentTrackIndex={currentTrackIndex}
                setCurrentTrackIndex={setCurrentTrackIndex}
                trackDuration={duration}
                trackProgress={progress} //use to show music total duration
                setTrackProgress={setProgress}
                playedProgress={played}  // use to show music played time
                setPlayed={setPlayed}
                setSeeking={setSeeking}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                loop={loop}
                setLoop={setLoop}
                isShuffle={isShuffle}
                setIsShuffle={setIsShuffle}
                setShuffleBtnClicked={setShuffleBtnClicked}
                currentPlayListType={currentPlayListType}
                setCurrentPlayListType={setCurrentPlayListType}
            />
        </div>
    );
};

export default MusicPlayer;
