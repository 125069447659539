import React, {  useState } from "react";
import { useTranslation } from "react-i18next";
import {  useSelector } from "react-redux";
import {
  _MAIN_WALLET_ID,
} from "../../util/Constant";
import {
  map,
} from "lodash";
import Slider from "react-slick";
import SwiperCore, { Autoplay } from 'swiper';
import 'react-loading-skeleton/dist/skeleton.css'
import ProviderIcon, { ProviderIconDisplay } from "components/custom/ProviderIcon";

const HotProviders = () => {
  var { t } = useTranslation();

  // Swiper Plugins
  SwiperCore.use([Autoplay]);

  const providerList = useSelector((state) => state.appState.provider);
 
  const [providerSlick, setProviderSlick] = useState({
    slidesToShow: 4,
    dots: false,
    arrows: false,
    swipe: true,
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
  });

  return (
    <>
      {providerList?.length > 0 &&
        <div className="t3-providers-container mt-5">
          <h3 className="t3-custom-title mb-3"><span>{t("HOT")}</span> {t("PROVIDERS")}</h3>
          <div className="t3-providers-parent-box">
            <Slider {...providerSlick} infinite={providerList.length >= 4}>
              {providerList &&
                providerList.length &&
                map(providerList, (item, index) => {
                  return (
                    item && (
                      <div key={index} className="t3-provider-item">
                        {/* <div className="first">
                          <img
                            src={item.providerImage}
                            className="img-responsive"
                            alt="provider"
                          />
                        </div> */}
                        <ProviderIcon
                          containerClassName="first"
                          imgLightMode={new ProviderIconDisplay({
                            imgClassName: `img-responsive img-${item.providerName.replace(/\s+/g, '')}`,
                            imgSrc: item.providerImageLight,
                          })}
                          imgDarkMode={new ProviderIconDisplay({
                            imgClassName: `img-responsive img-${item.providerName.replace(/\s+/g, '')}`,
                            imgSrc: item.providerImage,
                          })}
                          imgAlt={item.providerName}
                        />
                      </div>
                    )
                  );
                })}
            </Slider>
          </div>
        </div>
      }
    </>
  )

}

export default HotProviders