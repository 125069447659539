import React, { useEffect, useState, useRef } from "react";
import ApiEngine from "../../util/ApiEngine.js";
import {
  ApiKey,
  ApiUrl,
  _MAIN_WALLET_ID,
} from "../../util/Constant";
import {
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  map,
  find,
  filter,
} from "lodash";
import {
  setBusy,
  setIdle,
} from "../../redux/AppAction.js";
import { useDispatch, useSelector } from "react-redux";

// LiveTransactions component
const LiveTransactions = (props) => {
  var { t } = useTranslation();
  var _dispatch = useDispatch();
  const [transLoading, setTransLoading] = useState();
  const [latestDepositList, setLatestDepositList] = useState();
  const [latestWithdrawalList, setLatestWithdrawalList] = useState();
  const [noDataBg, setNoDataBg] = useState("");
  const companyIcons = useSelector((state) => state.appState.companyIcons);

  useEffect(() => {
    getCompanyIcon();
    getWithdrawalDepositTransaction()
  }, []);

  const getWithdrawalDepositTransaction = async () => {
    setTransLoading(true)
    var responseJson2 = await ApiEngine.get(
      ApiUrl._API_GET_TOP_DEPOSIT_WITHDRAWAL
    );

    if (responseJson2[ApiKey._API_SUCCESS_KEY]) {
      // _dispatch(setBusy());
      const latestDepositData = filter(responseJson2[ApiKey._API_DATA_KEY], {
        transactionTypeId: 1,
      });
      const latestWithdrawalData = filter(responseJson2[ApiKey._API_DATA_KEY], {
        transactionTypeId: 2,
      });

      setLatestDepositList(latestDepositData);
      setLatestWithdrawalList(latestWithdrawalData);
      // _dispatch(setIdle());
    } else {
      throw responseJson2[ApiKey._API_MESSAGE_KEY];
    }

    setTransLoading(false)


  }

  async function getCompanyIcon() {
    _dispatch(setBusy());

    const noDataBg = find(companyIcons, { iconCategory: "no_data_background" });
    if (noDataBg) {
      setNoDataBg(noDataBg.iconImage||noDataBg.defaultImage);
    }

    _dispatch(setIdle());
  }

  return (
    <div className="t3-live-transactions-container mt-4">
      <h3 className="t3-custom-title mb-2">
        <span>{t("LIVE")} {t("TRANSACTION_UPPER")}</span>
      </h3>
      <div className="mobile-latest-deposit-withdraw-box">
        <div>
          <div className="mobile-home-latest-transactions-box">
            <div className="head">
              <div>{t("DEPOSIT")}</div>
            </div>

            {transLoading ?
              <div className="body empty-data">
                <Spinner
                  className="dynamic-table-spinner"
                  animation="border"
                  role="status"
                  size="sm"
                  style={{
                    verticalAlign: "middle",
                    width: "3rem",
                    height: "3rem",
                    margin: "2rem"
                  }}
                ></Spinner>
              </div> :
              latestDepositList && latestDepositList.length > 0 ? (
                map(latestDepositList, (item, index) => {
                  return (
                    <div key={index} className={`body ${index % 2 === 0 ? 'even' : 'odd'}`}>
                      <div className="latest-label">
                        <img src={item.icon} />
                        {item.username}
                      </div>
                      <div>{item.amount.toFixed(2)}</div>
                    </div>
                  );
                })
              ) : (
                <div className="body empty-data">
                  {noDataBg ?
                    <><img
                      src={noDataBg}
                      className="img-responsive"
                      width={"60px"}
                      alt="icon"
                    /><p className="m-0">{t("NO_DATA_FOUND")}</p></> : <Spinner
                      className="dynamic-table-spinner"
                      animation="border"
                      role="status"
                      size="sm"
                      style={{
                        verticalAlign: "middle",
                        width: "3rem",
                        height: "3rem",
                        margin: "2rem"
                      }}
                    ></Spinner>
                  }
                </div>
              )}
          </div>
        </div>
        <div>
          <div className="mobile-home-latest-transactions-box">
            <div className="head">
              <div>{t("WITHDRAWAL")}</div>
            </div>
            {transLoading ?
              <div className="body empty-data">
                <Spinner
                  className="dynamic-table-spinner"
                  animation="border"
                  role="status"
                  size="sm"
                  style={{
                    verticalAlign: "middle",
                    width: "3rem",
                    height: "3rem",
                    margin: "2rem"
                  }}
                ></Spinner>
              </div> :
              latestWithdrawalList && latestWithdrawalList.length > 0 ? (
                map(latestWithdrawalList, (item, index) => {
                  return (
                    <div key={index} className={`body ${index % 2 === 0 ? 'even' : 'odd'}`}>
                      <div className="latest-label">
                        <img src={item.icon} />
                        {item.username}
                      </div>
                      <div>{item.amount.toFixed(2)}</div>
                    </div>
                  );
                })
              ) : (
                <div className="body empty-data">
                  {noDataBg ?
                    <><img
                      src={noDataBg}
                      className="img-responsive"
                      width={"60px"}
                      alt="icon"
                    /><p className="m-0">{t("NO_DATA_FOUND")}</p></> : <Spinner
                      className="dynamic-table-spinner"
                      animation="border"
                      role="status"
                      size="sm"
                      style={{
                        verticalAlign: "middle",
                        width: "3rem",
                        height: "3rem",
                        margin: "2rem"
                      }}
                    ></Spinner>
                  }
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LiveTransactions;