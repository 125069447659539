import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  SessionKey,
  _MAIN_WALLET_ID,
  TransactionStatus,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import {
  showResponseMessage,
  setBusy,
  setIdle,
  setGameLobbyModal,
} from "../../redux/AppAction.js";
import {
  stringIsNullOrEmpty,
  numberWithCurrencyFormat,
  createFormBody,
} from "../../util/Util";
import {
  map,
  find,
  filter,
  get,
  includes,
  forEach,
} from "lodash";
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay } from 'swiper';
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
// import Spinner from "react-bootstrap/Spinner";
import {
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import LoadingPlaceholder from "components/template_1/LoadingPlaceholder";
import SweetAlert from "react-bootstrap-sweetalert";
import GameLobbyModal from "components/template_1/GameLobbyModal";
import PlaceholderLoading from "react-placeholder-loading";
import 'react-loading-skeleton/dist/skeleton.css'
import LiveTransactions from "components/custom/LiveTransactions";
import RecentBigWin from "components/custom/RecentBigWin";
import ReferralBannerComponent from "components/custom/ReferralBanner";
import ProviderList from "components/custom/ProviderList";
import RecentPayout from "components/custom/RecentPayout";
import RecommendedSlots from "components/custom/RecommendedSlots";
import FeaturedSlots from "components/custom/FeaturedSlots";
import HotProviders from "components/custom/HotProviders";
import RecommendedCasino from "components/custom/RecommendedCasino";
import MarqueAnnoucement from "components/custom/MarqueAnnoucement";
import HomePageBanner from "components/custom/HomePageBanner";
import MobileLoginComponents from "components/custom/MobileLoginComponents";
import { useMediaQuery } from 'react-responsive';

const Login = (props) => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _dispatch = useDispatch();

  // Swiper Plugins
  SwiperCore.use([Autoplay]);


  const memberData = useSelector((state) => state.appState.member);

  let isAutoScrolling = false;
  const container = document.getElementById("t3-scroll-row");
  const [isStopScroll, setIsStopScroll] = useState(false);
  const [gameCategoryActive, setGameCategoryActive] = useState({
    id: -1,
    title: "All",
  });
  const [gameCategories, setGameCategories] = useState([]);
  const [games, setGames] = useState(
    useSelector((state) => state.appState.games)
  );
  const [popupBannerImage, setPopupBannerImage] = useState("");
  const [selectedGameName, setSelectedGameName] = useState("");
  const [masterProductId, setMasterProductId] = useState(0);
  const [isApp, setIsApp] = useState(false);
  const [appDeepLink, setAppDeepLink] = useState("");
  const [packageNameKeyword, setPackageNameKeyword] = useState("");
  const [selectedGameHasOwnLobby, setSelectedGameHasOwnLobby] = useState(false);
  const [selectedGameIsSeamless, setSelectedGameIsSeamless] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [bonus, setBonus] = useState(0);
  const [commission, setCommission] = useState(0);
  const [gameWalletBalanceBeforeTransfer, setGameWalletBalanceBeforeTransfer] =
    useState(0);
  const [masterProductContent, setMasterProductContent] = useState("");
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [isNewWindow, setIsNewWindow] = useState(false);
  const [showPregamePopup, setShowPregamePopup] = useState(false);
  const [productGameId, setProductGameId] = useState(0);
  const [toLobbyBtn, SetToLobbyBtn] = useState(false);
  const [rolloverBalance, setRolloverBalance] = useState("");
  const [rolloverTarget, setRolloverTarget] = useState("");
  const [productCommRate, setProductCommRate] = useState([]);
  const [appUrl, setAppUrl] = useState("");
  const [clubFee, setClubFee] = useState(0);
  const [isTransferSuccess, setIsTransferSuccess] = useState(false);
  const [transferShow, setTransferShow] = useState(false);
  const [toGameAmount, setToGameAmount] = useState(0);
  const [promoId, setPromoId] = useState("");
  const [promoCodeList, setPromoCodeList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [promoShow, setPromoShow] = useState(false);
  const [paidAmount, setPaidAmount] = useState();
  const [transferDate, setTransferDate] = useState();
  const openMenu = useSelector((state) => state.appState.openMenu);
  const [activeLanguage, setActiveLanguage] = useState("");
  const [activeLanguageId, setActiveLanguageId] = useState(0);
  const activeLanguageCode = useSelector(
    (state) => state.languageState.languageId
  );
  const [isLoading, setIsLoading] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);

  const [showDepositSuccess, setShowDepositSuccess] = useState(false);
  const [showDepositFailed, setShowDepositFailed] = useState(false);

  const companyIcons = useSelector((state) => state.appState.companyIcons);
  const [alertIcon, setAlertIcon] = useState();
  var companyLanguages = useSelector((state) => state.appState.languages);
  const [teamBanner, setTeamBanner] = useState();
  const [coinIcon, setCoinIcon] = useState();
  const _APPEND_REGISTER_URL = "/register?code=";
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [logo, setLogo] = useState();
  const [announcementList, setAnnouncementsList] = useState([]);
  const [noDataBg, setNoDataBg] = useState();
  const [registerUrl, setRegisterUrl] = useState("");
  const [referralBanner, setReferralBanner] = useState("");
  const [referralBannerMobile, setReferralBannerMobile] = useState("");
  const [defaultGameIcon, setDefaultGameIcon] = useState();
  const [bigWinList, setBigWinList] = useState([]);
  const [isSupportIframeState, setIsSupportIframeState] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [iframeAlert, setIsIframeAlert] = useState(false);
  const [providerName, setProviderName] = useState("");

  const [selectedGameCategoryGames, setSelectedGameCategoryGames] = useState([]);
  const [transcactionNumber, setTranscactionNumber] = useState();
  const dashboardComponent = useSelector((state) => state.appState.dashboardComponent);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const isPC = useMediaQuery({ query: '(min-width: 900px)' });

  const {
    handleSubmit: handleSubmitPopup,
    register: registerPopup,
    control: controlPopup,
    watch: watchPopup,
    getValues: getValuesPopup,
    setValue: setValuePopup,
    errors: errorsPopup,
    reset: resetPopup,
  } = useForm();
  var referralDetails = useSelector((state) => state.appState.member.referralCode);


  const [providerSlick, setProviderSlick] = useState({
    slidesToShow: 4,
    dots: false,
    arrows: false,
    swipe: true,
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
  });

  // Detect window size
  const handleWindowSizeChange = () => {
    if (window.innerWidth < 900) {
      setProviderSlick({
        ...providerSlick,
        slidesToShow: 2,
      });
    } else {
      setProviderSlick({
        ...providerSlick,
        slidesToShow: 4,
      });
    }
  };
  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);


  useEffect(() => {
    if (companyIcons.length > 0) {
      getCompanyIcon();
    }
  }, [companyIcons]);

  useEffect(() => {
    if (companyLanguages != undefined) {
      const tempLangCode = get(companyLanguages[0], "code");
      const tempLangId = get(companyLanguages[0], "id");
      if (
        localStorage.getItem(SessionKey._LANGUAGE) &&
        localStorage.getItem(SessionKey._LANGUAGE) !== "undefined"
      ) {
        let tempLang = companyLanguages.filter(
          (x) => x.code == localStorage.getItem(SessionKey._LANGUAGE)
        )[0];

        if (tempLang != undefined) {
          setActiveLanguage(tempLang.code);
          setActiveLanguageId(tempLang.id);
        } else {
          setActiveLanguage(tempLangCode);
          setActiveLanguageId(tempLangId);
        }
      } else {
        setActiveLanguage(tempLangCode);
        setActiveLanguageId(tempLangId);
      }
    } else {
      setActiveLanguage("en");
      setActiveLanguageId(1);
    }
  }, [companyLanguages, activeLanguageCode]);


  useEffect(() => {
    init();
    //getNewGames();
    // getLatestBets();
  }, []);

  useEffect(() => {
    setRegisterUrl(window.location.origin + _APPEND_REGISTER_URL + referralDetails);
  }, [referralDetails]);


  useEffect(() => {
    if (announcementList.length > 0) {
      setShowAnnouncementModal(true);
    }
  }, [announcementList]);

useEffect(() => {
    if (gameCategoryActive.title === "All") {
        setSelectedGameCategoryGames(games);
    }
    else {
        var selectedCategoryGameList = games.filter(game => game["categoryTitle"] === gameCategoryActive.title);
        setSelectedGameCategoryGames(selectedCategoryGameList);
    }

}, [gameCategoryActive]);

useEffect(() => { 
    if (games.length) {
        setSelectedGameCategoryGames(games);
    }
}, [games]);

  const closePopUp = () => {
    // setting key "seenPopUp" with value true into localStorage
    // localStorage.setItem("seenPopUp", true);
    // setting state to false to not display pop-up
    setShowAnnouncementModal(false);
  };

  async function init() {
    // if (heartBeat) {
    // 	bindMember(_dispatch);
    // }

    const search = window.location.search;
    const params = new URLSearchParams(search);

    if (params.get("transactionStatus")) {
      let transactionStatus = params.get("transactionStatus");

      if (transactionStatus == TransactionStatus._APPROVED) {
        setShowDepositSuccess(true);
      } else if (transactionStatus == TransactionStatus._REJECTED) {
        setShowDepositFailed(true);
      }
      // params.get('transactionStatus') == TransactionStatus._APPROVED ? setShowDepositSuccess(true) :
      // 	params.get('transactionStatus') == TransactionStatus._REJECTED ? setShowDepositFailed(true) : "";
    }

   
  }

  async function startGame(
    gameId,
    hasOwnLobby,
    masterProductId,
    gameName,
    isSeamless,
    isApp,
    appUrl,
    appDeepLink,
    isNewWindow,
    packageNameKeyword,
    isSupportIframe,
    categoryTitle,
    providerName
  ) {
    setSelectedGameName(gameName);
    setMasterProductId(masterProductId);
    setIsApp(isApp);
    setAppDeepLink(appDeepLink);
    setPackageNameKeyword(packageNameKeyword);
    setSelectedGameHasOwnLobby(hasOwnLobby);
    setSelectedGameIsSeamless(isSeamless);
    setIsSupportIframeState(isSupportIframe);
    setCategoryTitle(categoryTitle);
    setProviderName(providerName);

    if (isApp && !stringIsNullOrEmpty(appUrl)) {
      setAppUrl(appUrl);
    }
    if (hasOwnLobby !== true) {
      if (isSeamless == false) {
        setProductGameId(gameId);
        _dispatch(setGameLobbyModal(true));
      } else {
        if (isSupportIframe) {
          var breadcrumbData = [{
            pageName: t("HOME"),
            location: _history.location.pathname + _history.location.search,
            state: _history.location.state
          }];

          var path = WebUrl._URL_IFRAME_GAME_PAGE + "?gameId=" + gameId;
          _history.push(path, {
            breadcrumbData: breadcrumbData
          });
        } else {
          window.open(
            ApiUrl._API_START +
            "?gameId=" +
            gameId + "&device=" + (window.innerWidth <= 768 ? "m" : "d")
          );
        }

      }
    } else {
      if (isSeamless == false) {
        setIsLoading(true);
        var responseJson = await ApiEngine.get(
          ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE +
          "?masterProductId=" +
          masterProductId
        );
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setWalletList(responseJson[ApiKey._API_DATA_KEY]);

          let tempFromOptions = [];
          let tempToOptions = [];

          responseJson[ApiKey._API_DATA_KEY].map((product) => {
            let option = {
              label: product.productName,
              value: product.productId,
              amount: parseFloat(product.balance),
            };
            if (product.productId === _MAIN_WALLET_ID) {
              tempFromOptions.push(option);
            } else if (product.productId === masterProductId) {
              setBonus(product.bonus);
              setCommission(product.commission);
              if (product.productName === "D2 Poker") {
                setClubFee(product.clubFee);
              }
              setGameWalletBalanceBeforeTransfer(product.balance);
              if (product.masterProductContent !== null) {
                setMasterProductContent(product.masterProductContent);
              } else {
                setMasterProductContent("");
              }
              tempToOptions.push(option);
            }
          });
          setFromOptions(tempFromOptions);
          setToOptions(tempToOptions);

        }

        setShowPregamePopup(true);
        getProductComRate(gameName);
        setProductGameId(gameId);
        SetToLobbyBtn(true);
        getRolloverProgress(gameId);

        var path = WebUrl._URL_GAME_LOBBY +
          "?name=" +
          gameName +
          "&id=" +
          gameId +
          "&languageId=" +
          memberData["languageSetting"] +
          "&category=" +
          categoryTitle;

        var breadcrumbData = [{
          pageName: t('HOME'),
          location: _history.location.pathname + _history.location.search,
          state: _history.location.state
        }, {
          pageName: gameName,
          location: path
        }];

        var state = {
          breadcrumbData: breadcrumbData
        }

        _history.push(path, state);
        setIsLoading(false);
      }
    }
  }

  async function getProductComRate(selectedGameName) {
    let apiUrl = ApiUrl._API_PRODUCT_COMM_RATE;
    let data = [];
    let responseJson = await ApiEngine.get(apiUrl);
    if (
      responseJson[ApiKey._API_SUCCESS_KEY] &&
      responseJson[ApiKey._API_DATA_KEY].length > 0
    ) {
      data = responseJson[ApiKey._API_DATA_KEY].filter(
        (x) => x.productName === selectedGameName
      );

      if (data.length > 0) {
        setProductCommRate(data[0]);
      }
    }
  }

  async function getRolloverProgress(masterProductId) {
    setRolloverBalance("");
    setRolloverTarget("");
    if (masterProductId != "0" && !stringIsNullOrEmpty(masterProductId)) {
      var responseJson = await ApiEngine.get(
        ApiUrl._API_GET_DEPOSIT_PROMOTION_ROLLOVER_PROGRESS +
        "?masterProductId=" +
        masterProductId
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        if (responseJson[ApiKey._API_DATA_KEY] != null) {
          if (responseJson[ApiKey._API_DATA_KEY].data != null) {
            setRolloverBalance(
              responseJson[ApiKey._API_DATA_KEY].data["rolloverBalance"]
            );
            setRolloverTarget(
              responseJson[ApiKey._API_DATA_KEY].data["rolloverTarget"]
            );
          }
        }
      }
    }
  }


  const submitPopupForm = async (data, e) => {
    setTransferLoading(true);
    let params = {
      fromWallet: _MAIN_WALLET_ID,
      toWallet: masterProductId,
      amount: toGameAmount,
      promotionProductId: promoId,
    };

    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_WALLET_TRANSFER_TRANSACTION,
      createFormBody(params)
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      await updateWalletBalance(
        parseFloat(responseJson[ApiKey._API_DATA_KEY].amount).toFixed(2)
      );
      setPaidAmount(responseJson[ApiKey._API_DATA_KEY].amount);
      setTransferDate(moment().format("DD MMM YYYY, hh:mm A"));
      setIsTransferSuccess(true);
      setShowPregamePopup(true);
      setTransferShow(false);
      setTranscactionNumber(responseJson[ApiKey._API_DATA_KEY].transactionId);
      getRolloverProgress(masterProductId);
      e.target.reset();
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
    setTransferLoading(false);
  };

  async function updateWalletBalance() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_NON_SEAMLESS_PRODUCT_BALANCE
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let tempFromOptions = [];
      let tempToOptions = [];
      responseJson[ApiKey._API_DATA_KEY].map((product) => {
        let option = {
          label: product.productName,
          value: product.productId,
          amount: parseFloat(product.balance),
        };
        if (product.productId === _MAIN_WALLET_ID) {
          tempFromOptions.push(option);
        } else if (product.productId === masterProductId) {
          let gameWalletBalance = parseFloat(product.balance);
          setGameWalletBalanceBeforeTransfer(gameWalletBalance);
          tempToOptions.push(option);
        }
      });
      setFromOptions(tempFromOptions);
      setToOptions(tempToOptions);
    }
  }


  useEffect(() => {
    if (container) {
      startAutoScroll();
    }
  }, [container]);

  useEffect(() => {
    if (isStopScroll) {
      setTimeout(() => {
        startAutoScroll();
        setIsStopScroll(false);
      }, 500);
    }
  }, [isStopScroll]);

  function startAutoScroll() {
    isAutoScrolling = true;
    scrollToBottom();
    container.addEventListener("wheel", () => {
      stopAutoScroll();
    });
  }

  function stopAutoScroll() {
    isAutoScrolling = false;
    setIsStopScroll(true);
  }

  function scrollToBottom() {
    if (!isAutoScrolling) {
      return;
    }

    const start = container.scrollTop;
    const end = container.scrollHeight - container.clientHeight;
    const duration = 15000; // Set the duration in milliseconds

    const startTime = performance.now();

    function scroll(currentTime) {
      if (!isAutoScrolling) {
        return;
      }

      const elapsedTime = currentTime - startTime;

      const percentage = Math.min(1, elapsedTime / duration);
      const easeInOut = 0.5 - 0.5 * Math.cos(Math.PI * percentage);

      container.scrollTop = start + easeInOut * (end - start);

      if (percentage < 1) {
        requestAnimationFrame(scroll);
      }
    }

    requestAnimationFrame(scroll);
  }

  async function getCompanyIcon() {
    _dispatch(setBusy());
    const alert = find(companyIcons, { iconCategory: "alert" });
    if (alert) {
      setAlertIcon(alert.iconImage||alert.defaultImage);
    }

    const gameCategories = [];
    const pathnamesToFilter = ["/game-list", "/sports", "/casino", "/slots", "/keno", "/fish", "/e-sport", "/arcade"];

    const gameList = filter(companyIcons, item => includes(pathnamesToFilter, item.pathname));
    if (gameList) {
      gameList.forEach(function (x) {
        gameCategories.push({
          id: x.iconName == "All" ? -1 : x.id,
          title: x.iconName,
          image: x.iconImage||x.defaultImage,
        });
      });
      setGameCategories(gameCategories);
    }

    const teamBanner = find(companyIcons, { iconCategory: "team_banner" });
    if (teamBanner) {
      setTeamBanner(teamBanner.iconImage||teamBanner.defaultImage);
    }

    const coinIcon = find(companyIcons, { iconCategory: "coin_icon" });
    if (coinIcon) {
      setCoinIcon(coinIcon.iconImage||coinIcon.defaultImage);
    }

    const logo = find(companyIcons, { iconCategory: "logo" });
    if (logo) {
      setLogo(logo.iconImage||logo.defaultImage);
    }

    const noDataBg = find(companyIcons, { iconCategory: "no_data_background" });
    if (noDataBg) {
      setNoDataBg(noDataBg.iconImage||noDataBg.defaultImage);
    }

    const tempReferralBanner = find(companyIcons, { iconCategory: "referral_banner" });
    if (tempReferralBanner) {
      setReferralBanner(tempReferralBanner.iconImage||tempReferralBanner.defaultImage);
    }

    const tempReferralBannerMobile = find(companyIcons, { iconCategory: "referral_banner_mobile" });
    if (tempReferralBannerMobile) {
      setReferralBannerMobile(tempReferralBannerMobile.iconImage||tempReferralBannerMobile.defaultImage);
    }

    const defaultGameIconTemp = filter(companyIcons, (icon) => {
      return icon.iconCategory === "default_game_icon";
    });
    if (defaultGameIconTemp) {
      setDefaultGameIcon(defaultGameIconTemp[0]?.iconImage||defaultGameIconTemp[0]?.defaultImage);
    }

    _dispatch(setIdle());
  }


  const chunkArray = (arr, chunkSize) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  const itemsPerSlide = 2; // Two columns and three rows
  const slides = chunkArray(bigWinList, itemsPerSlide);


  const updateMasterProductId = (id) => {
    setMasterProductId(id)
  };
  const updateSelectedGameIsSeamless = (bool) => {
    setSelectedGameIsSeamless(bool)
  };
  const updateSelectedGameName = (name) => {
    setSelectedGameName(name)
  };
  const updateProductGameId = (id) => {
    setProductGameId(id)
  };
  const updatePopBannerImage = (url) => {
    setPopupBannerImage(url)
  };
  const updateTitle = (id) => {
    //null
  };
  const updateCategoryTitle = (category) => {
    setCategoryTitle(category)
  };
  const UpdateIsSupportIframe = (bool) => {
    setIsSupportIframeState(bool)
  };
  const updateProviderName = (name) => {
    setProviderName(name)
  };


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 899);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      {/* <Header /> */}

      {/* /////////t3 content */}
      <div className="t3-content-container mb-4">
        {showDepositSuccess && (
          <SweetAlert
            success
            confirmBtnText={t("CONFIRM")}
            onConfirm={() => {
              setShowDepositSuccess(false);
            }}
          >
            {t("DEPOSIT_SUCCESS")}
          </SweetAlert>
        )}

        {showDepositFailed && (
          <SweetAlert
            danger
            confirmBtnText={t("CONFIRM")}
            onConfirm={() => {
              setShowDepositFailed(false);
            }}
          >
            {t("DEPOSIT_FAILED")}
          </SweetAlert>
        )}
        {iframeAlert && (
          <div className="sweet-alert-container t3-sweet-alert-container">
            <SweetAlert
              customIcon={(<img
                className="m-auto"
                src={alertIcon}
                height="90px"
                width="90px"
                alt="Custom Icon"
              />)}
              cancelBtnText={t("CANCEL")}
              confirmBtnText={t("CONTINUE")}
              icon
              // onConfirm={() => {
              //   getRestoreAccountBlance();
              // }}
              onCancel={() => {
                setIsIframeAlert(false);
              }}
              custom
              showCancel
              reverseButtons={true}
              cancelBtnBsStyle="danger"
              customButtons={
                <React.Fragment>
                  <div className="sweetalert-custom-buttons">
                    <button
                      className={"btn btn-lg btn-primary"}
                      onClick={() => {
                        startGame(
                          productGameId,
                          selectedGameHasOwnLobby,
                          masterProductId,
                          selectedGameName,
                          selectedGameIsSeamless,
                          isApp,
                          appDeepLink,
                          isNewWindow,
                          packageNameKeyword,
                          isSupportIframeState
                        )
                        setIsIframeAlert(false);
                      }}
                    >
                      {t("CONTINUE")}
                    </button>
                    <a
                      onClick={() => ( _dispatch(setGameLobbyModal(false)), setIsIframeAlert(false))}
                      className="btn btn-primary btn-lg"
                    >
                      {t("CLOSE")}
                    </a>
                  </div>

                </React.Fragment>
              }
            >
              <span className="sweet-alert-custom-text">
                <div dangerouslySetInnerHTML={{ __html: t("IFRAME_WARM_").replace('\n', '<br/>') }} style={{ marginBottom: "20px", fontSize: "14px" }} />
              </span>
            </SweetAlert>
          </div>
        )}

        {/*arrangement set in BO (control by company)*/}
        <div className={openMenu ? "left active" : "left"}></div>
        <div className={openMenu ? "right active" : "right"}>
          {dashboardComponent != null && dashboardComponent.filter(x => x.status && isMobile ? x.deviceType == 2 : x.deviceType == 1) !=null  &&
            <>
              {dashboardComponent.filter(x => (x.componentStatus && x.userComponentStatus) && (isMobile ? x.deviceType == 2 : x.deviceType == 1)).map((item, index) => {

                switch (item.name) {
                  case "Homepage Banner":
                    return <HomePageBanner key={index} />;
                  case "Marque Annoucement":
                    return <MarqueAnnoucement key={index} />;
                  case "Mobile Login Components":
                    return <MobileLoginComponents key={index} />;
                  case "Live Transactions":
                    return <LiveTransactions key={index} noDataBg={noDataBg} />;
                  case "Recent BigWin":
                    return <RecentBigWin key={index} updateMasterProductId={updateMasterProductId} updateSelectedGameIsSeamless={updateSelectedGameIsSeamless} updateSelectedGameName={updateSelectedGameName}
                      updateProductGameId={updateProductGameId} updatePopBannerImage={updatePopBannerImage} updateTitle={updateTitle} updateCategoryTitle={updateCategoryTitle}
                      UpdateIsSupportIframe={UpdateIsSupportIframe} updateProviderName={updateProviderName} pageName="HOME"
                    />;
                  case "Referral Banner":
                    return <ReferralBannerComponent key={index} />;
                  case "Provider List":
                    return <ProviderList key={index} updateMasterProductId={updateMasterProductId} updateSelectedGameIsSeamless={updateSelectedGameIsSeamless} updateSelectedGameName={updateSelectedGameName}
                      updateProductGameId={updateProductGameId} updatePopBannerImage={updatePopBannerImage} updateTitle={updateTitle} updateCategoryTitle={updateCategoryTitle}
                      UpdateIsSupportIframe={UpdateIsSupportIframe} updateProviderName={updateProviderName} pageName="HOME"
                    />;
                  case "Recent Payout":
                    return <RecentPayout key={index} />;
                  case "Recommended Slot":
                    return <RecommendedSlots key={index} updateMasterProductId={updateMasterProductId} updateSelectedGameIsSeamless={updateSelectedGameIsSeamless} updateSelectedGameName={updateSelectedGameName}
                      updateProductGameId={updateProductGameId} updatePopBannerImage={updatePopBannerImage} updateTitle={updateTitle} updateCategoryTitle={updateCategoryTitle}
                      UpdateIsSupportIframe={UpdateIsSupportIframe} updateProviderName={updateProviderName} pageName="HOME"
                    />;
                  case "Featured Slot":
                    return <FeaturedSlots key={index} updateMasterProductId={updateMasterProductId} updateSelectedGameIsSeamless={updateSelectedGameIsSeamless} updateSelectedGameName={updateSelectedGameName}
                      updateProductGameId={updateProductGameId} updatePopBannerImage={updatePopBannerImage} updateTitle={updateTitle} updateCategoryTitle={updateCategoryTitle}
                      UpdateIsSupportIframe={UpdateIsSupportIframe} updateProviderName={updateProviderName} pageName="HOME"
                    />
                  case "Hot Providers":
                    return <HotProviders key={index} />;
                  case "Recommended Casino":
                    return <RecommendedCasino key={index} />;
                }
              })}
            </>
          }

          {/*default arrangement set in BO (control by super company)*/}
          {dashboardComponent != null && dashboardComponent.filter(x => x.status && x.deviceType ==3) != null &&
            <>
              {dashboardComponent.filter(x => (x.componentStatus && x.userComponentStatus) && x.deviceType == 3).map((item, index) => {
                switch (item.name) {
                  case "Homepage Banner":
                    return <HomePageBanner key={index} />;
                  case "Marque Annoucement":
                    return <MarqueAnnoucement key={index} />;
                  case "Mobile Login Components":
                    return <MobileLoginComponents key={index} />;
                  case "Live Transactions":
                    return <LiveTransactions key={index} noDataBg={noDataBg} />;
                  case "Recent BigWin":
                    return <RecentBigWin key={index} updateMasterProductId={updateMasterProductId} updateSelectedGameIsSeamless={updateSelectedGameIsSeamless} updateSelectedGameName={updateSelectedGameName}
                      updateProductGameId={updateProductGameId} updatePopBannerImage={updatePopBannerImage} updateTitle={updateTitle} updateCategoryTitle={updateCategoryTitle}
                      UpdateIsSupportIframe={UpdateIsSupportIframe} updateProviderName={updateProviderName} pageName="HOME"
                    />;
                  case "Referral Banner":
                    return <ReferralBannerComponent key={index} />;
                  case "Provider List":
                    return <ProviderList key={index} updateMasterProductId={updateMasterProductId} updateSelectedGameIsSeamless={updateSelectedGameIsSeamless} updateSelectedGameName={updateSelectedGameName}
                      updateProductGameId={updateProductGameId} updatePopBannerImage={updatePopBannerImage} updateTitle={updateTitle} updateCategoryTitle={updateCategoryTitle}
                      UpdateIsSupportIframe={UpdateIsSupportIframe} updateProviderName={updateProviderName} pageName="HOME"
                    />;
                  case "Recent Payout":
                    return <RecentPayout key={index} />;
                  case "Recommended Slot":
                    return <RecommendedSlots key={index} updateMasterProductId={updateMasterProductId} updateSelectedGameIsSeamless={updateSelectedGameIsSeamless} updateSelectedGameName={updateSelectedGameName}
                      updateProductGameId={updateProductGameId} updatePopBannerImage={updatePopBannerImage} updateTitle={updateTitle} updateCategoryTitle={updateCategoryTitle}
                      UpdateIsSupportIframe={UpdateIsSupportIframe} updateProviderName={updateProviderName} pageName="HOME"
                    />;
                  case "Featured Slot":
                    return <FeaturedSlots key={index} updateMasterProductId={updateMasterProductId} updateSelectedGameIsSeamless={updateSelectedGameIsSeamless} updateSelectedGameName={updateSelectedGameName}
                      updateProductGameId={updateProductGameId} updatePopBannerImage={updatePopBannerImage} updateTitle={updateTitle} updateCategoryTitle={updateCategoryTitle}
                      UpdateIsSupportIframe={UpdateIsSupportIframe} updateProviderName={updateProviderName} pageName="HOME"
                    />
                  case "Hot Providers":
                    return <HotProviders key={index} />;
                  case "Recommended Casino":
                    return <RecommendedCasino key={index} />;
                }
              })}
            </>
          }

          {/*original arrangement*/}
          {dashboardComponent == null &&
            <>
              <HomePageBanner />
              <MarqueAnnoucement />

              <MobileLoginComponents />

              <LiveTransactions noDataBg={noDataBg} />
              <RecentBigWin updateMasterProductId={updateMasterProductId} updateSelectedGameIsSeamless={updateSelectedGameIsSeamless} updateSelectedGameName={updateSelectedGameName}
                updateProductGameId={updateProductGameId} updatePopBannerImage={updatePopBannerImage} updateTitle={updateTitle} updateCategoryTitle={updateCategoryTitle}
                UpdateIsSupportIframe={UpdateIsSupportIframe} updateProviderName={updateProviderName} pageName="HOME"
              />
              <ReferralBannerComponent />

              <ProviderList updateMasterProductId={updateMasterProductId} updateSelectedGameIsSeamless={updateSelectedGameIsSeamless} updateSelectedGameName={updateSelectedGameName}
                updateProductGameId={updateProductGameId} updatePopBannerImage={updatePopBannerImage} updateTitle={updateTitle} updateCategoryTitle={updateCategoryTitle}
                UpdateIsSupportIframe={UpdateIsSupportIframe} updateProviderName={updateProviderName} pageName="HOME"
              />
              <RecentPayout />
              <RecommendedSlots updateMasterProductId={updateMasterProductId} updateSelectedGameIsSeamless={updateSelectedGameIsSeamless} updateSelectedGameName={updateSelectedGameName}
                updateProductGameId={updateProductGameId} updatePopBannerImage={updatePopBannerImage} updateTitle={updateTitle} updateCategoryTitle={updateCategoryTitle}
                UpdateIsSupportIframe={UpdateIsSupportIframe} updateProviderName={updateProviderName} pageName="HOME"
              />

              <FeaturedSlots updateMasterProductId={updateMasterProductId} updateSelectedGameIsSeamless={updateSelectedGameIsSeamless} updateSelectedGameName={updateSelectedGameName}
                updateProductGameId={updateProductGameId} updatePopBannerImage={updatePopBannerImage} updateTitle={updateTitle} updateCategoryTitle={updateCategoryTitle}
                UpdateIsSupportIframe={UpdateIsSupportIframe} updateProviderName={updateProviderName} pageName="HOME"
              />

              <HotProviders />

              <RecommendedCasino />
            </>
          }

          {/* {latestBets && latestBets.length > 0 && (
						<div className="mt-5">
							<h3 className="t3-custom-title mb-3">
								{t("LATEST")} <span>{t("BETS")} </span>
							</h3>
							<div>
								<div className="t3-table-header">
									<span>{t("GAME")}</span>
									<span>{t("PLAYER")}</span>
									<span>{t("BETS")}</span>
									<span>{t("NET_PROFIT")}</span>
								</div>
							</div>
							<div className="t3-scroll-row" id="t3-scroll-row">
								<div className="t3-scroll-container" id="t3-scroll-container">
									{latestBets &&
										latestBets.length > 0 &&
										map(latestBets, (item, index) => {
											return (
												<div key={index} className="t3-scroll-item">
													<div className="t3-scroll-item-child">
														<span>
															<img
																src={item.image}
																className="img-responsive"
																alt={item.text}
															/>
															<b>{item.gameName}</b>
														</span>
														<span>
															{item.player.substring(0, 2) +
																"*".repeat(item.player.length - 2)}
														</span>
														<span>{item.validTurnover}</span>
														<span
															className={
																item.profit >= 0
																	? "t3-text-green"
																	: "t3-text-red"
															}
														>
															{item.profit}
														</span>
													</div>
												</div>
											);
										})}
								</div>
							</div>
						</div>
					)} */}
        </div>
      </div>

      {/* /////////t3 footer */ }
      {/* <Footer /> */ }

      {/* ///pregame popup */ }
      <GameLobbyModal
        masterProductId={masterProductId}
        selectedGameIsSeamless={selectedGameIsSeamless}
        selectedGameName={selectedGameName}
        productGameId={productGameId}
        popupBannerImage={popupBannerImage}
        title={t("HOME")}
        category={categoryTitle}
        isSupportIframe={isSupportIframeState}
        providerName={providerName}
      />

      <Modal
        fade={false}
        id="t3-custom-modal-withdraw"
        isOpen={transferShow}
        toggle={() => {
          setTransferShow(false);
        }}
        // centered
      >
        <ModalBody>
          <div className="t3-custom-modal-withdraw-container t3-modal-bg-dark">
            <div className="t3-cms-header">
              <div className="first">
                <div>{t("TRANSFER_BALANCE")}</div>
              </div>
              <div className="second">
                <div
                  className="t3-close-custom-modal"
                  onClick={() => {
                    setTransferShow(false);
                    setToGameAmount(0);
                    setPromoId("");
                    setShowPregamePopup(true);
                  }}
                >
                  <VIcon icon={themeIcons.close} className="vicon" />
                </div>
              </div>
            </div>
            <div className="t3-withdraw-modal-body">
              <form onSubmit={handleSubmitPopup(submitPopupForm)}>
                <div className="t3-withdraw-form-style-box">
                  <label>{t("AMOUNT_TO_TRANSFER")}</label>
                </div>
                <div className="urr-amount-box">
                  <div>
                    <input
                      type="text"
                      name="amount"
                      value={Number(toGameAmount).toFixed(2)}
                      placeholder={numberWithCurrencyFormat(
                        parseFloat(toGameAmount),
                        2,
                        true
                      )}
                      className="input-with-balance"
                      onChange={(e) => {
                        let amount = e.target.value
                          .replace(
                            /[a-zA-Z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?(kr|$||)]*/g,
                            ""
                          )
                          .trim();

                        let value = Number(parseFloat(amount) / 100).toFixed(2);
                        if (
                          parseFloat(value) > parseFloat(fromOptions[0].amount)
                        ) {
                          value = fromOptions[0].amount;
                        }
                        e.target.setSelectionRange(value.length, value.length);
                        setInputValue(value);
                        setToGameAmount(value);
                      }}
                      disabled={
                        fromOptions.length > 0 &&
                          fromOptions[0].amount >
                          numberWithCurrencyFormat(parseFloat(0), 2, true)
                          ? ""
                          : "disabled"
                      }
                    />
                  </div>
                  <span className="font10 text-lgray2">
                    {t("AVAILABLE_BALANCE")}
                    {fromOptions.length > 0
                      ? numberWithCurrencyFormat(fromOptions[0].amount, 2)
                      : ""}
                  </span>
                </div>
                <span className="font12 text-lgray2 justify-center mt-2">
                  {t("Min")}:{" "}
                  {promoId != ""
                    ? numberWithCurrencyFormat(
                      promoCodeList.filter(
                        (x) => x.id == parseInt(promoId)
                      )[0].minDeposit,
                      2,
                      true
                    )
                    : numberWithCurrencyFormat(1, 2, true)}
                </span>
                <div
                  className="all-box-with-icon mt-2"
                  onClick={() => {
                    setPromoShow(true);
                    setTransferShow(false);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="first">
                    <div>
                      <VIcon
                        icon={themeIcons.dashboardCommission}
                        className="vicon"
                      />
                    </div>
                    <div className="font14 f-weight-500 text-lblue">
                      {promoId == ""
                        ? t("PROMO_CODE")
                        : promoCodeList.filter(
                          (x) => x.id == parseInt(promoId)
                        )[0].name}
                    </div>
                  </div>
                  <div>
                    <VIcon icon={themeIcons.angleRight} className="vicon" />
                  </div>
                </div>
                {promoId != null &&
                  promoCodeList
                    .filter((x) => x.id == parseInt(promoId))
                    .map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="reports-stats-box mb-3 mt-4"
                        >
                          <div className="ro-box-1-1">
                            <div className="earn-stat-title mb-1">
                              <div className="first-title">{t("BONUS")} %</div>
                              <div className="d-flex align-items-center"></div>
                            </div>
                            <div
                              className="earn-stat-number mb-1"
                              style={{ textAlign: "right" }}
                            >
                              {" "}
                              {item.bonus}
                            </div>
                          </div>

                          <div className="ro-box-1-1">
                            <div className="earn-stat-title mb-1">
                              <div className="first-title">Rollover</div>
                            </div>
                            <div
                              className="earn-stat-number mb-1"
                              style={{ textAlign: "right" }}
                            >
                              {" "}
                              x{item.rollover}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                <div className="mt-2">
                  {bonus > 0 && (
                    <>
                      <span className="font12 text-lgray2">
                        {t("BONUS")}:{" "}
                        {numberWithCurrencyFormat(parseFloat(bonus), 2, true)}%
                      </span>
                      <br />
                    </>
                  )}
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className={
                      inputValue > 0
                        ? "t3-custom-light-btn mt-4 mb-4"
                        : "t3-custom-light-btn mt-4 mb-4 disabled"
                    }
                  >
                    {t("TRANSFER_TO_WALLET")}
                    {transferLoading ? (
                      <Spinner
                        style={{
                          verticalAlign: "middle",
                          width: "1rem",
                          height: "1rem",
                          marginLeft: 5,
                        }}
                      ></Spinner>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        fade={false}
        id="t3-custom-modal-withdraw"
        isOpen={promoShow}
        toggle={() => {
          setPromoShow(false);
        }}
        centered
      >
        <ModalBody>
          <div className="t3-custom-modal-withdraw-container t3-modal-bg-dark">
            <div className="t3-cms-header">
              <div className="first">
                <div>{t("PROMO_CODE")}</div>
              </div>
              <div className="second">
                <div
                  className="t3-close-custom-modal"
                  onClick={() => {
                    setPromoShow(false);
                    setTransferShow(true);
                  }}
                >
                  <VIcon icon={themeIcons.close} className="vicon" />
                </div>
              </div>
            </div>
            <div className="t3-withdraw-modal-body pb-5">
              <form>
                <div className="t3-withdraw-form-style-box">
                  <label>{t("SELECT_PROMO_CODE")}</label>
                </div>
                <div
                  className="all-box-with-icon mt-2"
                  onClick={() => {
                    setPromoId("");
                    setPromoShow(false);
                    setTransferShow(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div className="first">
                    <div>
                      <VIcon
                        icon={themeIcons.dashboardCommission}
                        className="vicon"
                      />
                    </div>
                    <div className="font14 f-weight-500 text-lblue">
                      {t("NO_BONUS")}
                    </div>
                  </div>
                </div>
                {promoCodeList
                  .filter((x) => x.productId == masterProductId)
                  .map((promo, index) => {
                    return (
                      <div
                        key={index}
                        className="all-box-with-icon mt-2"
                        onClick={() => {
                          setPromoId(promo.id);
                          setPromoShow(false);
                          setTransferShow(true);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="first">
                          <div>
                            <VIcon
                              icon={themeIcons.dashboardCommission}
                              className="vicon"
                            />
                          </div>
                          <div className="font14 f-weight-500 text-lblue">
                            {selectedGameName} - {promo.name}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        id="t3-login-register-modal"
        fade={false}
        className={"home-announcement-modal"}
        contentClassName={"t3-login-register-modal"}
        isOpen={showAnnouncementModal}
        toggle={() => {
          setShowAnnouncementModal(false);
        }}
        centered
        fullscreen
      >
        <ModalBody>
          <div className="t3-lr-modal-container pb-5">
            <div className="t3-lr-modal-header">
              <div className="t3-logo">
                {logo ? (
                  <img src={logo} className="img-responsive" alt="logo" />
                ) : (
                  <PlaceholderLoading
                    shape="rect"
                    width={100}
                    height={40}
                    colorStart="#333"
                    colorEnd="#636363"
                  />
                )}
              </div>
              <div
                className="t3-close-modal"
                // onClick={() => setShowModalRegister(!showModalRegister)}
                onClick={() => closePopUp()}
              >
                <VIcon icon={themeIcons.close} className="vicon" />
              </div>
            </div>
            <div className="t3-lr-modal-body">
              <div className="home-announcement-modal-content-box">
                {announcementList &&
                  announcementList.length > 0 &&
                  map(announcementList, (item, index) => {
                    return (
                      <div key={index} className="item">
                        <div className="details mb-2">{item.title}</div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.content,
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
              <div className="text-center mt-4">
                <button
                  className="t3-custom-light-btn"
                  type="submit"
                  onClick={() => closePopUp()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      { isLoading ? <LoadingPlaceholder /> : "" }
    </>
  );
};

export default Login;
