import React, { useState, useEffect, useRef } from "react";
import { ApiKey,ClientTemplates } from "../../util/Constant";
import * as Util from "../../util/Util";
import { useDispatch, useSelector } from "react-redux";
import { showMessage, showResponseMessage } from "../../redux/AppAction";
import { setPageSize, setY, setBusy, setIdle } from "../../redux/AppAction.js";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import AbortController from "abort-controller";
import { find } from "lodash";

/// <summary>
/// Author: -
/// </summary>
const DynamicLoadTable = (props) => {
	const { t, i18n } = useTranslation();
	var _supportInfiniteLoading = props["infiniteLoadEnable"] ?? true;
	const _dispatch = useDispatch();
	const [dataList, setDataList] = useState([]);
	const [totalRecords, setTotaRecords] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [searchEnabled, setSearchEnabled] = useState(false);
	const [autoscrollEnabled, setAutoscrollEnabled] = useState(false);

	const [apiQuery, setApiQuery] = useState(props["apiQuery"]);
	const [title, setTitle] = useState("TOTAL_RECORD");
	const [isTable, setIsTable] = useState(false);
	const y = useSelector((state) => state.appState.y);

	const _DEFAULT_PAGE_SIZE = 20;

	const [pageSize, setPageSize] = useState(_DEFAULT_PAGE_SIZE);
	const [template, setTemplate] = useState('template_1')
	const latestApiQuery = useRef(props.apiQuery);
	// AbortController scoped to the component or module
	const abortControllerRef  = useRef(null); 
	const companyIcons = useSelector((state) => state.appState.companyIcons);
	const [noDataBg, setNoDataBg] = useState();

	const [triggerCount, setTriggerCount] = useState(0);

	useEffect(() => {
		if (companyIcons.length > 0) {
			getCompanyIcon();
		}
	}, [companyIcons]);

	async function getCompanyIcon() {
		_dispatch(setBusy());
		const noDataBg = find(companyIcons, { iconCategory: "no_data_background" });
		if (noDataBg) {
			setNoDataBg(noDataBg.iconImage||noDataBg.defaultImage);
		}
		_dispatch(setIdle());
	}

	useEffect(() => {
		if (!Util.stringIsNullOrEmpty(props["apiQuery"])) {
			setDataList([]);
			setTitle(props["title"]);
			setApiQuery(props["apiQuery"]);
		}
	}, [props["apiQuery"]]);

	/// <summary>
	/// Author: -
	/// </summary>
	useEffect(() => {
		setSearchEnabled(props["searchEnabled"]);
		if (!props["searchEnabled"]) {

			setDataList([]);
		}

		if (props["autoscrollEnabled"]) {
			setAutoscrollEnabled(props["autoscrollEnabled"]);
		}

		if (props["isTable"]) {
			setIsTable(props["isTable"]);
		}
	}, [props["searchEnabled"]]);

	/// <summary>
	/// Author: -
	/// </summary>
	useEffect(() => {
		if (props["returnRecord"]) {
			props.returnRecord(dataList);
		}

	}, [dataList]);

	useEffect(() => {
		if (props["totalRecords"]) {
			props.totalRecords(totalRecords);
		}
	}, [dataList, totalRecords]);

	/// <summary>
	/// Author: -
	/// </summary>
	useEffect(() => {
		setPageSize(props["pageSize"] ? props["pageSize"] : _DEFAULT_PAGE_SIZE);
	}, [props["pageSize"]]);

	/// <summary>
	/// Author: -
	/// </summary>
	useEffect(() => {
		let templateFilter =  ClientTemplates.filter((x) => x.hostname == window.location.hostname)[0]
		?.template ?? "template_1"
		setTemplate(templateFilter)
		if (!Util.stringIsNullOrEmpty(apiQuery)) {
			getDataList();
		}
	}, [apiQuery, searchEnabled, pageSize]);

	/// <summary>
	/// Author: -
	/// </summary>
	useEffect(() => {
		if (_supportInfiniteLoading) {
			// Reset trigger count when dataList is reset to first page
			if(dataList.length <= pageSize){
				setTriggerCount(0);
			}

			// var container = document.querySelector('.page-content-full-height');
			// container.addEventListener("scroll", onScrollHandler);

			window.addEventListener("scroll", onScrollHandler);

			const popUpModalId = document.getElementById('modalBody');

			if (popUpModalId) {
				popUpModalId.addEventListener("scroll", onScrollHandlerModal);
			}

			return () => {
				window.removeEventListener("scroll", onScrollHandler);
				// container.removeEventListener("scroll", onScrollHandler);

				if (popUpModalId) {
					popUpModalId.removeEventListener("scroll", onScrollHandlerModal);
				}
			};
		}
	}, [dataList, totalRecords, isLoading, pageSize]);

	useEffect(() => {
		checkTriggerInfiniteLoading('.t3-game-list-box');
	}, [triggerCount]);

	async function onScrollHandlerModal() {
		if (isLoading) {
			return;
		}

		var popUpModalId = document.getElementById('modalBody');

		const windowHeight = popUpModalId.clientHeight
		const docHeight = Math.max(
			popUpModalId.scrollHeight,
			popUpModalId.offsetHeight,
			popUpModalId.clientHeight,
		);

		const windowBottom = windowHeight + Math.ceil(popUpModalId.scrollTop);

		if (autoscrollEnabled && popUpModalId.scrollTop > 0) {
			_dispatch(setY(popUpModalId.scrollTop));
		}

		if (popUpModalId) {
			const modalElement = document.getElementById('modalBody');
			const modalBottom = modalElement.scrollHeight - modalElement.clientHeight;
			const modalScroll = Math.ceil(modalElement.scrollTop);

			if (modalScroll >= modalBottom && !isLoading) {
				if (dataList.length < totalRecords) {
					modalElement.scrollTo({ top: modalBottom, behavior: 'smooth' });
					setIsLoading(true)
					getDataList(dataList);
				}
			}
		} else if (windowBottom >= docHeight && !isLoading) {
			if (dataList.length < totalRecords) {
				setIsLoading(true)
				getDataList();
			}
		}
	}

	async function onScrollHandler() {
		if (isLoading) {
			return;
		}

		// Chris - use this instead
		checkTriggerInfiniteLoading('.t3-game-list-box');

		var container = document.querySelector('.page-content-full-height');

		// const windowHeight =
		// 	"innerHeight" in window
		// 		? window.innerHeight
		// 		: document.documentElement.offsetHeight;
		// const body = document.body;
		// const html = document.documentElement;
		// const docHeight = Math.max(
		// 	body.scrollHeight,
		// 	body.offsetHeight,
		// 	html.clientHeight,
		// 	html.scrollHeight,
		// 	html.offsetHeight
		// );

		// const windowBottom = windowHeight + Math.ceil(window.pageYOffset);

		const windowHeight = container.clientHeight
		const docHeight = Math.max(
			container.scrollHeight,
			container.offsetHeight,
			container.clientHeight,
		);

		const windowBottom = windowHeight + Math.ceil(container.scrollTop);

		if (autoscrollEnabled && container.scrollTop > 0) {
			_dispatch(setY(container.scrollTop));
		}

		var popUpModalId = document.getElementById('t3-custom-modal-withdraw');

		if (popUpModalId) {
			const modalElement = document.getElementById('popUpModal');

			if(modalElement){
			const modalBottom = modalElement.scrollHeight - modalElement.clientHeight;
			const modalScroll = Math.ceil(modalElement.scrollTop);

			// if (windowBottom >= windowHeight && !isLoading) {
			// 	if (dataList.length < totalRecords) {
			// 		getDataList(dataList);
			// 	}
			// }

			if (modalScroll >= modalBottom && !isLoading) {
				if (dataList.length < totalRecords) {
					modalElement.scrollTo({ top: modalBottom, behavior: 'smooth' });
					getDataList();
				}
			}
		  }
		} else if (windowBottom >= docHeight && !isLoading) {
			if (dataList.length < totalRecords) {
				getDataList();
			}
		}
	}

	function checkTriggerInfiniteLoading(elem) {
		// Chris: do not look for the end of the page-content-full-height, we look for the div that contains the content
		var listContainer = document.querySelector(elem);
		
		if(listContainer){

			// Then find the bottom part of that div (Get the height of the div)
			var triggerHeight = listContainer.offsetHeight + listContainer.offsetTop;

			// // Debug - add red line
			// document.querySelectorAll('.red-line').forEach(x => x.remove());
			// document.body.appendChild(Object.assign(document.createElement('div'), { className: 'red-line', style: `height: 1px; background-color: red; position: absolute; top: ${triggerHeight}px; left: 0; right: 0; z-index: 9999;` }));

			// Add some buffer so that we can lazy load the next page before we hit the red line
			var bufferTriggerHeight = triggerHeight - 200;

			// // Debug - add yellow line
			// document.querySelectorAll('.yellow-line').forEach(x => x.remove());
			// document.body.appendChild(Object.assign(document.createElement('div'), { className: 'yellow-line', style: `height: 1px; background-color: yellow; position: absolute; top: ${bufferTriggerHeight}px; left: 0; right: 0; z-index: 9999;` }));

			// Now we can track the viewport to see if it cross the red line
			var viewportTracker = window.scrollY + window.innerHeight - 100;

			// // Debug - add green line
			// document.querySelectorAll('.green-line').forEach(x => x.remove());
			// document.body.appendChild(Object.assign(document.createElement('div'), { className: 'green-line', style: `height: 1px; background-color: green; position: absolute; top: ${viewportTracker}px; left: 0; right: 0; z-index: 9999;` }));

			// Load the next page if we are below the red line
			if (viewportTracker > bufferTriggerHeight
				&& dataList.length < totalRecords
				&& triggerCount < 3
				&& !isLoading
			) {
				setTriggerCount(triggerCount + 1);
				getDataList();
			}
			// Chris - infinite load end
		}
	}

	useEffect(() => {
		latestApiQuery.current = props.apiQuery;	
	}, [props.apiQuery]);

	useEffect(() => {
		getDataList();
	}, [latestApiQuery.current]);

	async function getDataList(list, signal) {
		if(props.abortController){
			if (abortControllerRef.current) {
				abortControllerRef.current.abort();
			}
		}
		
		abortControllerRef.current = new AbortController();

		if (!props.apiQuery) return;

		if (searchEnabled && !isLoading) {
			setIsLoading(true);

			var pageNumber = Math.ceil(dataList.length / pageSize);
			var isQueryStringAppended = latestApiQuery.current.includes("?");
			var apiUrl =
				latestApiQuery.current +
				(isQueryStringAppended ? "&" : "?") +
				"count=" +
				pageSize +
				"&start=" +
				pageNumber * pageSize;

			// try {
				let responseJson = await ApiEngine.get(apiUrl, { signal: abortControllerRef.current.signal });
				// await new Promise(resolve => setTimeout(resolve, 300));
				
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setIsLoading(true);
					if (latestApiQuery.current === props.apiQuery) {
						setDataList([...dataList, ...responseJson[ApiKey._API_DATA_KEY].data]);
						setTotaRecords(responseJson[ApiKey._API_DATA_KEY].totalCount);
						if (props.onComplete) {
							props.onComplete({
								totalDataLoaded: [
									...dataList,
									...responseJson[ApiKey._API_DATA_KEY].data,
								]["length"],
							});
						}
					} else {
						setIsLoading(true);
						var isQueryStringAppended1 = latestApiQuery.current.includes("?");
						var currentApiUrl =
							latestApiQuery.current +
							(isQueryStringAppended1 ? "&" : "?") +
							"count=" +
							pageSize +
							"&start=" +
							pageNumber * pageSize;
						let currentResponseJson = await ApiEngine.get(currentApiUrl, { signal: abortControllerRef.current.signal });
						if (currentResponseJson[ApiKey._API_SUCCESS_KEY]) {
							setDataList([...dataList, ...currentResponseJson[ApiKey._API_DATA_KEY].data]);
							setTotaRecords(currentResponseJson[ApiKey._API_DATA_KEY].totalCount);
						}
					} 
					var container = document.querySelector('.page-content-full-height');

					if (autoscrollEnabled && container.scrollTop == 0 && y > 0) {
						window.scrollTo(0, y);
					}
					// var container2 = document.getElementById('modalBody');

					// if (autoscrollEnabled && container2.scrollTop == 0 && y > 0) { 
					// 	window.scrollTo(0, y);
					// }
				} else {
					if(props.abortController == false){
						_dispatch(
							showResponseMessage(
								responseJson[ApiKey._API_SUCCESS_KEY],
								t(responseJson[ApiKey._API_MESSAGE_KEY])
							)
						);
					}
				}
			// } catch (error) {
			// 	console.error("Error during fetch:", error);
			// 	setIsLoading(false);
			// 	if (axios.isCancel(error)) {
			// 		console.log('Request was cancelled');
			// 	} else {
			// 		_dispatch(showResponseMessage(false, t("Error loading data")));
			// 	}
			// }
		} else {

			setDataList([]);
			setTotaRecords("0");
		}
		setIsLoading(false);
		//window.scrollTo(0, 200);
	}

	return (
		<>
			{dataList.length == 0 &&
				!isLoading &&
				props["type"] == "notification" && (
					<tr>
						<td colSpan="20" className="font-thick">
							{t("NO_NOTIFICATIONS")}
						</td>
					</tr>
				)}
			{dataList.length == 0 &&
				!isLoading &&
				props["type"] != "notification" && (
					<>
						{!props["noDataSecondDesign"] ? (
							<tr>
								<td colSpan="20" className="font-thick">
									{t("NO_DATA_FOUND")}
								</td>
							</tr>
						) : (
							<tr className="game-grid-adapt-full-width">
								<td colSpan={4}>
									<div className="t3-table-img">
										<img
											src={noDataBg}
											className="img-responsive"
											alt="logo"
										/>
									</div>
									<div className="t3-table-msg t3-primary-text-color-p font14 text-center">
										{t("NO_DATA_FOUND2")}
									</div>
								</td>
							</tr>
						)}
					</>
					
				)}

			{dataList.length != 0 && (
				<>
					{dataList.map((data, i) => {
						data.dataLength = dataList.length;
						return props.render(data, i);
					})}
				</>
			)}

			{isLoading && isTable && (
				<tr>
					<td colSpan="20" className="font-thick">
						<Spinner
							className="dynamic-table-spinner"
							animation="border"
							role="status"
							size="sm"
							style={{ verticalAlign: "middle", width: "2rem", height: "2rem" }}
						>
							<span className="sr-only">{t("LOADING")}...</span>
						</Spinner>
					</td>
				</tr>
			)}
			
			{isLoading && !isTable && (
				<tr
					className={`game-loading ${template == 'template_1' ? 't3-game-loading' : ''}`}
					style={{ display: "flex", justifyContent: "center" }}
				>
					<td
						colSpan="20"
						style={{
							textAlign: "center",
							paddingTop: "25px",
							paddingBottom: "20px",
						}}
					>
						<Spinner
							className="dynamic-table-spinner"
							animation="border"
							role="status"
							size="sm"
							style={{ verticalAlign: "middle", width: "2rem", height: "2rem" }}
						>
							<span className="sr-only">{t("LOADING")}...</span>
						</Spinner>
					</td>
				</tr>
			)}

			{dataList 
			&& dataList.length > 0 
			&& dataList.length < totalRecords 
			&& triggerCount >= 2 
			&& !isLoading 
			&& !isTable 
			&& (
				<button
					className={`t3-custom-light-btn`}
					onClick={() => {
						setTriggerCount(0);
					}}
					style={{ cursor: "pointer", gridColumn: "1 / -1", justifySelf: "center", marginTop: "20px" }}
				>
					{t("LOAD_MORE")}
				</button>
			)}
		</>
	);
};

export default DynamicLoadTable;
