import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
	ApiKey,
	ApiUrl
} from "../../../util/Constant";
import ApiEngine from "../../../util/ApiEngine";
import {
	showMessage,
	showResponseMessage,
} from "../../../redux/AppAction";
import Spinner from "react-bootstrap/Spinner";
import DateRangeFilter from "components/custom/DateRangeFilter";
import PaginationBar from "../../../components/custom/PaginationBar";

const SpinWheelRecord = () => {
	let _location = useLocation();
	const _dispatch = useDispatch();
	const {t} = useTranslation();
	const [paginationPageNum, setPaginationPageNum] = useState(0);
	const [isResetCurrentPage, setIsResetCurrentPage] = useState(false);
	const [loadingFlag, setLoadingFlag] = useState(true);
	const [memberSpinList, setMemberSpinList] = useState([]);
	const [startDate, setStartDate] = useState(
		_location?.state?.date
			? moment(_location.state.date.split(t("TO"))[0], "DD-MM-YYYY")
			: moment().startOf("isoWeek")
	);
	const [endDate, setEndDate] = useState(
		_location?.state?.date
			? moment(_location.state.date.split(t("TO"))[1], "DD-MM-YYYY")
			: moment().endOf("day")
	);

	const [selectedFilterType, setSelectedFilterType] = useState(
		_location?.state?.date
			? _location.state.date
			: moment().startOf("isoWeek").format("DD-MM-YYYY") +
					t("TO") +
					moment().endOf("day").format("DD-MM-YYYY")
	);

	const filterTypeOnSelect = (value) => {
		setSelectedFilterType(value);
	};

	useEffect(() => {
		getMemberSpinData();
	}, [startDate, endDate]);


	async function getMemberSpinData(page, keyword) {
		setMemberSpinList([]);
		setLoadingFlag(true);
		try {
			page = page ? page : 1;
			const itemsPerPage = 10; 
			var start = (page - 1) * itemsPerPage;

			var apiUrl = `${
				ApiUrl._API_GET_MEMBER_SPINS_CLAIMS_RECORD +
				"?StartDate=" +
				moment(startDate).format("YYYY-MM-DD") +
				"&EndDate=" +
				moment(endDate).format("YYYY-MM-DD")
			}`;

			apiUrl = apiUrl + "&start=" + start + "&count="+itemsPerPage;


			var responseJson = await ApiEngine.get(apiUrl);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setMemberSpinList(
					responseJson[ApiKey._API_DATA_KEY][ApiKey._API_DATA_KEY]
				);

				var totalCount = responseJson[ApiKey._API_DATA_KEY].totalCount;
				updatePageinationPageNum(Math.ceil(totalCount / itemsPerPage));
			} else {
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);
			}
		} catch (err) {
			_dispatch(showResponseMessage(false, err));
		}
		setLoadingFlag(false);
		return;
	}

	function updatePageinationPageNum(pageValue) {
		setPaginationPageNum((prevValue) => pageValue);
	}

	return (
		<div className="t3-downline-modal-body px-0 py-0" id="deposit-id">
			<h3 className="t3-h3 text-left mt-0">{t("SPIN_WHEEL_RECORD")}</h3>
			<DateRangeFilter
				// isOpen={isDateFilterOpen}
				startDate={startDate}
				endDate={endDate}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				onSave={(start, end) => {
					setStartDate(start);
					setEndDate(end);
				}}
				options={true}
				initialType={selectedFilterType}
				// onClose={dateRangePickerOnClose}
				typeOnSelect={filterTypeOnSelect}
			/>


			<div className="t3-downline-table-body">
				<div className="t3-table-container">
					<table className="t3-custom-table">
						<thead>
							<tr>
								<th>{t("SPIN_WHEEL_ID")}</th>
								<th>{t("CAMPAIGN")}</th>
								<th>{t("CLAIMED_DATE")}</th>
								<th>{t("REWARD")}</th>
							</tr>
						</thead>
						<tbody>
							{memberSpinList &&
							memberSpinList.length != 0 ? (
								memberSpinList.map((data, index) => {

									return (
										<tr key={index}>
											<td>
												{data["spinWheelId"]}
											</td>
											<td>
											   {data["campaignName"]}
											</td>
											<td style={{ color: data["claimedDateTxt"]==="EXPIRED" ? "#FF0004" : "#FFF" }}>
											   
											   { data["claimedDateTxt"]==="EXPIRED"?
                                                  t("EXPIRED"):
												  data["claimedDateTxt"]
											   }

											</td>
											<td>
												{data["reward"]}
											</td>
										</tr>
									);
								})
							) : loadingFlag ? (
								<tr>
									<td colSpan={4}>
										<div
											style={{
												textAlign: "center",
												marginTop: "25px",
												marginBottom: "20px",
											}}
										>
											<Spinner
												animation="border"
												role="status"
												size="sm"
												style={{
													verticalAlign: "middle",
													width: "2rem",
													height: "2rem",
												}}
											>
												<span className="sr-only">{t("LOADING")}...</span>
											</Spinner>
										</div>
									</td>
								</tr>
							) : (
								<tr>
									<td colSpan={4}>{t("NO_DATA_FOUND")}</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
			{paginationPageNum > 1 && (
				<PaginationBar
					totalPages={paginationPageNum}
					retrieveDataRecords={getMemberSpinData}
					isResetCurrentPage={isResetCurrentPage}
					setIsChangeTab={setIsResetCurrentPage}
				/>
			)}
		</div>
	);
};

export default SpinWheelRecord ;
