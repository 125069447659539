import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ApiEngine from "../../util/ApiEngine.js";
import {
  ApiKey,
  ApiUrl,
  WebUrl,
  _MAIN_WALLET_ID,
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setBusy,
  setIdle,
  setLoginModal,
  setDownlineModal,
  setShareReferralModal,
} from "../../redux/AppAction.js";
import {
  find,
} from "lodash";
import { themeIcons } from "config/theme";
import {
  Spinner,
} from "reactstrap";
import VIcon from "components/custom/Icon";

// ReferralBanner component
const ReferralBanner = () => {
  var { t } = useTranslation();
  var _history = useHistory();
  var _dispatch = useDispatch();

  var { heartBeat } = useSelector((state) => state.authState);
  const [showCodeAlert, setShowCodeAlert] = useState(false);
  const [referralBanner, setReferralBanner] = useState("");
  const [referralBannerMobile, setReferralBannerMobile] = useState("");
  const [uplineLoading, setUplineLoading] = useState(false);
  var referralDetails = useSelector((state) => state.appState.member.referralCode);
  const companyIcons = useSelector((state) => state.appState.companyIcons);
  const [copy1, setCopy1] = useState(false);
  const [copy2, setCopy2] = useState(false);
  var _codeRef = useRef();
  var _linkRef = useRef();
  const _ALERT_TIMEOUT = 3000;
  const [registerUrl, setRegisterUrl] = useState("");
  const _APPEND_REGISTER_URL = "/register?code=";

  async function getCompanyIcon() {
    _dispatch(setBusy());

    const tempReferralBanner = find(companyIcons, { iconCategory: "referral_banner" });
    if (tempReferralBanner) {
      setReferralBanner(tempReferralBanner.iconImage||tempReferralBanner.defaultImage);
    }

    const tempReferralBannerMobile = find(companyIcons, { iconCategory: "referral_banner_mobile" });
    if (tempReferralBannerMobile) {
      setReferralBannerMobile(tempReferralBannerMobile.iconImage||tempReferralBannerMobile.defaultImage);
    }
    _dispatch(setIdle());
  }

  const copyItem = (item) => {
    navigator.clipboard.writeText(item.current.innerText);

    setCopy1(true)
    setTimeout(() => setCopy1(false), [_ALERT_TIMEOUT]);
  };
  const copyItem2 = (item) => {
    navigator.clipboard.writeText(item.current.innerText);

    setCopy2(true)
    setTimeout(() => setCopy2(false), [_ALERT_TIMEOUT]);
  };

  useEffect(() => {
    getCompanyIcon();

  }, []);

  useEffect(() => {
    setRegisterUrl(window.location.origin + _APPEND_REGISTER_URL + referralDetails);
  }, [referralDetails]);

  return (
    <>
      {heartBeat && (
        <div className="t3-referral-container">
          <div className="t3-custom-title">
            &nbsp;
          </div>
          {showCodeAlert && (
            <div className="mt-3 mb-3 reminder-green">
              <div className="first">
                <div className="first">
                  <img
                    src={require("../../assets/img/new_icons/green.png")}
                    className="img-responsive"
                    alt="icon"
                  />
                </div>
                <div>
                  <p className="font14 mb-0 f-weight-500">
                    {t("COPY_SUCCESS")}
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="home-referral-container">
            <div className="t3-team-banner-img-bg">
              {referralBanner ?

                <img
                  src={referralBanner}
                  className="img-teamBanner hide-1250"
                  alt="referral"
                />
                :
                <img
                  src={require("../../assets/img/template_1/referral-banner.jpg")}
                  className="img-teamBanner hide-1250"
                  alt="referral"
                />
              }
              {referralBannerMobile ?

                <img
                  src={referralBannerMobile}
                  className="w-100 show-1250"
                  alt="referral"
                />
                :
                <img
                  src={require("../../assets/img/template_1/referral-banner-mobile.jpg")}
                  className="w-100 show-1250"
                  alt="referral"
                />
              }
              <div className="t3-team-banner-code-box">
                <div className="hide-767">
                  <div className="t3-team-banner-title">
                    {t("MY_REFERRAL_CODE")}
                  </div>
                  <div className="t3-team-banner-details">
                    <div className="first" ref={_codeRef}>
                      {/* {referralDetails} */}
                      {uplineLoading ? (
                        <Spinner
                          className="dynamic-table-spinner"
                          animation="border"
                          role="status"
                          size="sm"
                          style={{
                            verticalAlign: "middle",
                            width: "1rem",
                            height: "1rem",
                          }}
                        ></Spinner>
                      ) : (
                        referralDetails
                      )}
                    </div>
                    <div className="second cursor-pointer">
                      {copy1 ? t("COPIED") : (<VIcon
                        icon={themeIcons.copy}
                        onClick={() => copyItem(_codeRef)}
                        className="vicon"
                      />)}
                    </div>
                  </div>
                </div>
                <div className="mt-3 hide-767">
                  <div className="t3-team-banner-title">
                    {t("MY_REFERRAL_LINK")}
                  </div>
                  <div className="t3-team-banner-details">
                    <div className="first" ref={_linkRef}>
                      {uplineLoading ? (
                        <Spinner
                          className="dynamic-table-spinner"
                          animation="border"
                          role="status"
                          size="sm"
                          style={{
                            verticalAlign: "middle",
                            width: "1rem",
                            height: "1rem",
                          }}
                        ></Spinner>
                      ) : (
                        registerUrl
                      )}
                    </div>
                    <div className="second cursor-pointer">
                      {copy2 ? t("COPIED") : (<VIcon
                        icon={themeIcons.copy}
                        onClick={() => copyItem2(_linkRef)}
                        className="vicon"
                      />)}
                    </div>
                  </div>
                </div>

                <div className="home-referral-button-group-box mt-3">
                  <div
                    className="t3-team-copy-btn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (heartBeat) {
                        _dispatch(setShareReferralModal(true));
                      } else {
                        _dispatch(setLoginModal(true));
                      }
                    }}
                  >
                    {t("SHARE")}
                  </div>
                  <div
                    className="t3-team-copy-btn"
                    onClick={() => {
                      if (heartBeat) {
                        _dispatch(setDownlineModal(true));
                      } else {
                        _dispatch(setLoginModal(true));
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {t("DOWNLINE")}
                  </div>
                </div>
                <div
                  className="t3-team-copy-btn"
                  onClick={() => {
                    if (heartBeat) {
                      _history.push(WebUrl._URL_REFERRAL);
                    } else {
                      _dispatch(setLoginModal(true));
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {t("MORE_INFO")}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ReferralBanner; 