import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  ApiKey,
  ApiUrl,
  _MAIN_WALLET_ID,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import {
  showResponseMessage,
  setDepositModal,
  setLoginModal,
  setRegisterModal,
} from "../../redux/AppAction.js";
import {
  numberWithCurrencyFormat,
} from "../../util/Util";
import {
  map,
} from "lodash";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
// import Spinner from "react-bootstrap/Spinner";
import {
  Spinner,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

const MobileLoginComponent = () => {
  var { t } = useTranslation();
  var _dispatch = useDispatch();

  var { heartBeat } = useSelector((state) => state.authState);
  const memberData = useSelector((state) => state.appState.member);
  const walletsDetails = useSelector((state) => state.appState.walletsDetails);
  const [balanceRefresh, setBalanceRefresh] = useState(false);
  const [minimumDeposit, setMinimumDeposit] = useState(0);
  const [minimumWithdrawal, setMinimumWithdrawal] = useState(0);
  const [rolloverWarning, setRolloverWarning] = useState(false);
  const [rolloverData, setRolloverData] = useState([]);
  const membershipEnabled = useSelector(
    (state) => state.appState.membershipEnabled
  );
  var _userData = useSelector((state) => state.authState.userData);

  useEffect(() => {
    if (heartBeat) {
      getMinumumDepositWithdrawal()
    }

    
  }, []);

  useEffect(() => {
    if (heartBeat) {
      getLatestRolloverRecord();
    }
  }, [walletsDetails, heartBeat]);


  const getMinumumDepositWithdrawal = async () => {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MINIMUM_DEPOSIT_WITHDRAWAL
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setMinimumDeposit(responseJson[ApiKey._API_DATA_KEY]?.minimumDeposit);
      setMinimumWithdrawal(
        responseJson[ApiKey._API_DATA_KEY]?.minimumWithdrawal
      );
    }
  };

  async function checkMemberRollover() {
    try {
      getLatestRolloverRecord();
      var rolloverJson = await ApiEngine.get(ApiUrl._API_CHECK_MEMBER_ROLLOVER);
      if (rolloverJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(setDepositModal(true, 2));
      } else {
        setRolloverWarning(true);
      }
    } catch (err) {
      _dispatch(showResponseMessage(false, err));
    }
  }

  async function getRestore() {
    setBalanceRefresh(true);
    let responseJson = await ApiEngine.get(ApiUrl._API_RESTORE_ALL_WALLET);

    // _dispatch(
    //   showResponseMessage(
    //     responseJson[ApiKey._API_SUCCESS_KEY],
    //     responseJson[ApiKey._API_MESSAGE_KEY]
    //   )
    // );
    setBalanceRefresh(false);
  }

  async function getLatestRolloverRecord() {
    var responseJson = await ApiEngine.get(
      ApiUrl._API_GET_LATEST_ROLLOVER_RECORD
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setRolloverData(responseJson[ApiKey._API_DATA_KEY]);
    }
  }

  return (
    <>
      {rolloverWarning && (
          <div className="sweet-alert-container t3-sweet-alert-container">
            <SweetAlert
              danger
              confirmBtnText={t("OK")}
              onConfirm={() => {
                setRolloverWarning(false);
              }}
            >
              <span className="sweet-alert-custom-text">
                {t("ROLLOVER_REQUIREMENT_ERROR")}
              </span>

              <div className="t3-rollover-sweet-alert mt-3">
                <div className="t3-rollover-sweet-alert-header">
                  <div className="first">{t("LATEST_ROLLOVER_PROGRESS")}</div>
                  <div className="second t3-rollover-box mt-3">
                    {rolloverData &&
                      rolloverData.length > 0 &&
                      map(rolloverData, (item, index) => {
                        const percentage =
                          (item["isLatestRecord"] == 1
                            ? item["rolloverProgress"]
                            : item["rolloverTarget"]) == 0 &&
                            item["rolloverTarget"] == 0
                            ? "0%"
                            : ((item["isLatestRecord"] == 1
                              ? item["rolloverProgress"]
                              : item["rolloverTarget"]) /
                              item["rolloverTarget"]) *
                            100;
                        return (
                          <div key={index} className="t3-rollover-sweet-alert-info">
                            <div className="t3-progress green">
                              <span className="t3-progress-left">
                                <span
                                  className="t3-progress-bar"
                                  style={{
                                    transform:
                                      percentage >= 50
                                        ? `rotate(180deg)`
                                        : `rotate(${(percentage / 100) * 180
                                        }deg)`,
                                  }}
                                ></span>
                              </span>
                              <span className="t3-progress-right">
                                <span
                                  className="t3-progress-bar"
                                  style={{
                                    transform:
                                      percentage > 50
                                        ? `rotate(${(percentage / 100) * -180
                                        }deg)`
                                        : `rotate(-0deg)`,
                                  }}
                                ></span>
                              </span>
                              <div className="t3-progress-value">
                                {percentage.toFixed(2)}
                                <span>%</span>
                              </div>
                            </div>

                            <div className="t3-rollover-sweet-alert-body">
                              <span className="title">
                                {/* {t("LATEST_TOP_UP")} :{" "} */}
                                {t("LATEST_TOP_UP_AND_BONUS")} :{" "}
                              </span>
                              <span className="amount">
                                {numberWithCurrencyFormat(
                                  parseFloat(item["amount"]),
                                  2,
                                  true
                                )}
                              </span>
                              <div className="date">{item["date"]}</div>
                              <div className="highlight-info">
                                {t("YOU_HAVE")}{" "}
                                <b>
                                  {item["isLatestRecord"] == 1
                                    ? item["rolloverProgress"]
                                    : item["rolloverTarget"]}{" "}
                                  / {item["rolloverTarget"]}
                                </b>{" "}
                                {t("TO_GO")}!
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="mt-3 mb-3 reminder-red">
                <div className="first">
                  <div className="first">
                    <img
                      src={require("../../assets/img/new_icons/red.png")}
                      className="img-responsive"
                      alt="icon"
                    />
                  </div>
                  <div>
                    <p className="text-left font14 mb-0 f-weight-500">
                      {t("ROLLOVER_RED_WARNING")}
                    </p>
                  </div>
                </div>
              </div>
            </SweetAlert>
          </div>
      )}
      <div className="t3-mobile-login show-767 mt-3">
        {heartBeat == false && (
          <>
            <div className="home-login-register-box">
              <div
                onClick={() => {
                  _dispatch(setLoginModal(true));
                }}
              >
                {t("LOGIN")}
              </div>
              <div
                onClick={() => {
                  _dispatch(setRegisterModal(true));
                }}
              >
                {t("REGISTER")}
              </div>
            </div>
          </>
        )}
        {heartBeat && (
          <div className="mobile-home-balance-box mt-3">
            {heartBeat && (
              <div className="head">
                <div className="title">{memberData["username"]}</div>
                {membershipEnabled &&
                  <div className="level">
                    {t("MEMBERSHIP")}: {t(_userData.vipName.toUpperCase())}
                    <img
                      className="img-responsive"
                      src={memberData["membership"]?.["icon"]}
                    />
                  </div>
                }
              </div>
            )}
            <div className="body">
              <div className="first">
                <div>{t("BALANCE")}:</div>
                <div className="amount">
                  {numberWithCurrencyFormat(
                    walletsDetails.currentWalletBalance +
                    walletsDetails.bonusWalletBalance,
                    2,
                    true
                  )}
                </div>
                <div>
                  {t("MIN_DEPOSIT")}: <span>{minimumDeposit.toFixed(2)}</span>
                </div>
                <div>
                  {t("MIN_WITHDRAWAL")}:{" "}
                  <span>{minimumWithdrawal.toFixed(2)}</span>
                </div>
              </div>
              <div className="second">
                <div>
                  <button
                    onClick={() => {
                      if (heartBeat) {
                        // _history.push(WebUrl._URL_DEPOSIT)
                        _dispatch(setDepositModal(true));
                      } else {
                        _dispatch(setLoginModal(true));
                      }
                    }}
                  >
                    <VIcon
                      icon={themeIcons.deposit}
                      className="theme-icon-size-20"
                    />
                    {t("DEPOSIT")}
                  </button>
                  <button
                    onClick={() => {
                      if (heartBeat) {
                        checkMemberRollover();
                      } else {
                        _dispatch(setLoginModal(true));
                      }
                    }}
                  >
                    <VIcon
                      icon={themeIcons.withdraw}
                      className="theme-icon-size-20"
                    />
                    {t("WITHDRAW")}
                  </button>
                  <button
                    onClick={() => {
                      if (heartBeat) {
                        getRestore();
                      } else {
                        _dispatch(setLoginModal(true));
                      }
                    }}
                  >
                    {balanceRefresh ? (
                      <Spinner
                        className="dynamic-table-spinner"
                        animation="border"
                        role="status"
                        size="sm"
                        style={{
                          verticalAlign: "middle",
                          width: "0.875rem",
                          height: "0.875rem",
                          marginLeft: 3,
                        }}
                      ></Spinner>
                    ) : (
                      <VIcon
                        icon={themeIcons.refresh}
                        className="theme-icon-size-20"
                      />
                    )}
                    {t("REFRESH")}
                  </button>
                </div>
              </div>
            </div>

            {rolloverData &&
              rolloverData.length > 0 &&
              map(rolloverData, (item, index) => {
                if (item["rolloverTarget"] === 0) {
                  return null; // Skip items with rolloverTarget as 0
                }
                
                const percentage =
                  (item["isLatestRecord"] == 1
                    ? item["rolloverProgress"]
                    : item["rolloverTarget"]) == 0 &&
                    item["rolloverTarget"] == 0
                    ? 0
                    : ((item["isLatestRecord"] == 1
                      ? item["rolloverProgress"]
                      : item["rolloverTarget"]) /
                      item["rolloverTarget"]) *
                    100;
                const formattedPercentage = Math.round(percentage);
                return (
                  <div className="home-balance-progress-bar-container">
                    <div className="highlight-info mb-2">
                        {t("YOUR_ROLLOVER_PROGRESS_IS")}{" "}
                        <b>
                          {item["isLatestRecord"] == 1
                            ? item["rolloverProgress"]
                            : item["rolloverTarget"]}{" "}
                          / {item["rolloverTarget"]}
                        </b>{"."}
                      </div>
                    <div className="home-progress-bar-container">
                      <div className="home-progress-bar-box">
                        <span
                          className="home-progress-identifier"
                          style={{
                            width:
                              (item["isLatestRecord"] == 1
                                ? item["rolloverProgress"]
                                : item["rolloverTarget"]) == 0 &&
                              item["rolloverTarget"] == 0
                                ? "0%"
                                : ((item["isLatestRecord"] == 1
                                    ? item["rolloverProgress"]
                                    : item["rolloverTarget"]) /
                                    item["rolloverTarget"]) *
                                    100 +
                                  "%",
                          }}
                        ></span>
                      </div>
                      <span>{formattedPercentage}%</span>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </>
  )

}

export default MobileLoginComponent