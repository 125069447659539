import { themeIcons } from "config/theme";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import VIcon from "components/custom/Icon";
import { useDispatch, useSelector } from "react-redux";
import {setSpinWheelReportModal } from "redux/AppAction";
import {  find } from "lodash";
import { numberWithCurrencyFormat } from "../../../util/Util";
import SpinWheelRecord from "./SpinWheelRecord";

const SpinWheelClaimRecordPopUp = (props) => {
  var { t } = useTranslation();
  var _dispatch = useDispatch();

  const walletsDetails = useSelector((state) => state.appState.walletsDetails);
  const spinWheelReportModal = useSelector((state) => state.appState.spinWheelReportModal);
  const companyIcons = useSelector((state) => state.appState.companyIcons);
  const [loadingSearchData, setLoadingSearchData] = useState(false);
	const [coinIcon, setCoinIcon] = useState();


  const recordType = [
		{
			label: "SPIN_WHEEL_RECORD",
			value: 1,
		}
		
	];
	const [selectedOption, setSelectedOption] = useState(1);
 

useEffect(() => {
  if (companyIcons.length > 0) {
    getCompanyIcon();
  }
}, [companyIcons]);

async function getCompanyIcon() {
  const coinIcon = find(companyIcons, { iconCategory: "coin_icon" });
  if (coinIcon) {
    setCoinIcon(coinIcon.iconImage||coinIcon.defaultImage);
  }
}
 
  return (
    <div>

      <Modal
        id="t3-custom-modal"
        className="modal-custom-fullscreen"
        contentClassName={"t3-custom-modal t3-custom-modal-with-back-btn min-height-600px"}
        fade={false}
        isOpen={spinWheelReportModal}
        toggle={() => {
          _dispatch(setSpinWheelReportModal(false));
        }}
      >
        <ModalHeader>
        <div className="first">
            <div>{t("CLAIM_RECORD")}</div>
          </div>
          <div className="second">
            <div
              className="t3-close-custom-modal"
              onClick={() => _dispatch(setSpinWheelReportModal(false))}
            >
              <VIcon icon={themeIcons.close} className="vicon" />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="t3-custom-modal-container">
            <div className="custom-body edit-profile" >


            <div className="spin-wallet-container">
             <div className="wallet-row">
             <div className="wallet-info">
      <img
        src={coinIcon}
        style={{ width: "50px", height: "50px" }}
        alt="img-wallet-coin"
      />
      <div>
        <div> {`${t("WALLET_BALANCE")}:`}</div>
        <div
          style={{
            fontWeight: "Bold",
            color: "var(--theme-color-bg-btn-submit)",
          }}
        >
          {numberWithCurrencyFormat(
            walletsDetails.currentWalletBalance,
            2,
            true
           )}
           </div>
         </div>
         </div>
             </div>
            </div>

          <div className="t3-statistics-body-box px-0 pt-10 pb-0">
					<div className="t3-statistics-dropdown-box mb-3 d-block">
						<div className="first mb-3">{t("RECORD_TYPE")}</div>
						<div className="second">
							{!loadingSearchData ? (
								recordType && recordType.length > 0 ? (
									<select
										name="recordType"
										value={selectedOption}
										onChange={(e) => {
											setSelectedOption(e.target.value);
										}}
									>
										{recordType.map((item, index) => {
											return (
												<option key={index} value={item.value}>
													{t(item.label)}
												</option>
											);
										})}
									</select>
								) : (
									<div className="no-data-text">{t("NO_DATA_FOUND")}</div>
								)
							) : (
								<Spinner
									animation="border"
									role="status"
									style={{
										verticalAlign: "middle",
										width: "1rem",
										height: "1rem",
									}}
								></Spinner>
							)}
						</div>
					</div>
					<hr className="record-type-separator-line" />
				</div>
                
        {selectedOption === 1 && spinWheelReportModal && <SpinWheelRecord />}

            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>

  );
};

export default SpinWheelClaimRecordPopUp;
