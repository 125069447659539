import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  ApiKey,
  ApiUrl,
  _MAIN_WALLET_ID,
  SessionKey
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch, useSelector } from "react-redux";
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from "react-loading-skeleton";
import {
  setBanners,
  setDepositModal,
  setLoginModal,
} from "../../redux/AppAction.js";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {
  map,
  get,
} from "lodash";


const HomePageBanner = () => {
  var { t } = useTranslation();
  var companyLanguages = useSelector((state) => state.appState.languages);
  const activeLanguageCode = useSelector(
    (state) => state.languageState.languageId
  );
  const [activeLanguage, setActiveLanguage] = useState("");
  const [activeLanguageId, setActiveLanguageId] = useState(0);
  const [bannerLoading, setBannerLoading] = useState(true)
  var { heartBeat } = useSelector((state) => state.authState);
  const [bannersList, setBannersList] = useState(
    useSelector((state) => state.appState.banners)
  );
  var _dispatch = useDispatch();


  useEffect(() => {
    getBanners()

  }, []);


  const getBanners = async () => {
    setBannerLoading(true)
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_USER_BANNER +
      "?page=/dashboard" +
      "&languagecode=" +
      activeLanguage
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setBannersList(responseJson[ApiKey._API_DATA_KEY]);
      _dispatch(setBanners(responseJson[ApiKey._API_DATA_KEY]));
    }
    setBannerLoading(false)

  };

  useEffect(() => {
    if (companyLanguages != undefined) {
      const tempLangCode = get(companyLanguages[0], "code");
      const tempLangId = get(companyLanguages[0], "id");
      if (
        localStorage.getItem(SessionKey._LANGUAGE) &&
        localStorage.getItem(SessionKey._LANGUAGE) !== "undefined"
      ) {
        let tempLang = companyLanguages.filter(
          (x) => x.code == localStorage.getItem(SessionKey._LANGUAGE)
        )[0];

        if (tempLang != undefined) {
          setActiveLanguage(tempLang.code);
          setActiveLanguageId(tempLang.id);
        } else {
          setActiveLanguage(tempLangCode);
          setActiveLanguageId(tempLangId);
        }
      } else {
        setActiveLanguage(tempLangCode);
        setActiveLanguageId(tempLangId);
      }
    } else {
      setActiveLanguage("en");
      setActiveLanguageId(1);
    }
  }, [companyLanguages, activeLanguageCode]);

  return (
    <div className="t3-dashboard-slick mt-2">
      {!bannerLoading ? (
        <Swiper
          className="t3-dashboard-banner"
          spaceBetween={0}
          slidesPerView={3}
          onSlideChange={() => {
            // console.log('slide change')
          }}
          onSwiper={(swiper) => {
            // console.log(swiper)
          }}
          loop={bannersList.length > 3}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            1280: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            0: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
        >
          {bannersList &&
            bannersList.length > 0 ? (
            // Swiper v9.4.1+ => Because of nature of how the loop mode works (it will rearrange slides), total number of slides must be >= slidesPerView * 2
            Array.from({ length: Math.ceil(bannersList.length / 3) }).map((_, i) => (
              map(bannersList, (item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      className={"banner-items " + (!item.url ? "cursor-unset" : "")}
                      onClick={(e) => {
                        if (item.url && item.url == "/deposit") {
                          if (heartBeat) {
                            _dispatch(setDepositModal(true));
                          } else {

                            _dispatch(setLoginModal(true));

                          }
                        } else {
                          if (item.url) {
                            window.location.href = `${process.env.REACT_APP_ENDPOINT_URL || ""
                              }${item.url}`;
                          }
                        }
                        // if (heartBeat) {
                        //   if (item.url && item.url == "/deposit") {
                        //     _dispatch(setDepositModal(true));
                        //   } else {
                        //     window.location.href = `${process.env.REACT_APP_ENDPOINT_URL || ""
                        //       }${item.url}`;
                        //   }
                        // } else {
                        //   if (item.url == "/deposit") {
                        //     _dispatch(
                        //       showMessage({
                        //         custom: true,
                        //         customIcon: (
                        //           <img
                        //             className="m-auto"
                        //             src={alertIcon}
                        //             height="120px"
                        //             width="120px"
                        //             alt="Custom Icon"
                        //           />
                        //         ),
                        //         content: t("PLEASE_LOGIN_FIRST"),
                        //       })
                        //     );
                        //   }
                        // }
                      }}
                    >
                      <img
                        src={item.mobileImage}
                        alt="banner"
                        className="img-100"
                        loading="lazy"
                      />
                    </div>
                  </SwiperSlide>
                );
              })
            ))
          ) : (
            null
            // <div style={{ display: "flex" }}>
            //   <Skeleton height={200} baseColor="#333" highlightColor="#636363" />
            //   <Skeleton className="hide-767" height={200} baseColor="#333" highlightColor="#636363" />
            //   <Skeleton className="hide-767" height={200} baseColor="#333" highlightColor="#636363" />
            // </div>
          )}
        </Swiper>
      ) : (
        <div style={{ display: "flex", flexDirection: "row", width: "100%", gap: "15px" }}>
          <div style={{ width: "100%" }}>
            <Skeleton height={200} baseColor="var(--skeleton-base-color)" highlightColor="var(--skeleton-highlight-color)" />
          </div>
          <div className="hide-767" style={{ width: "100%" }}>
            <Skeleton height={200} baseColor="var(--skeleton-base-color)" highlightColor="var(--skeleton-highlight-color)" />
          </div>
          <div className="hide-767" style={{ width: "100%" }}>
            <Skeleton height={200} baseColor="var(--skeleton-base-color)" highlightColor="var(--skeleton-highlight-color)" />
          </div>
        </div>
      )}
    </div>
  )

}

export default HomePageBanner