import React, { useEffect, useState, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	TransactionType,
} from "../../../util/Constant";
import Input from "../../../components/custom/Input";
import { find, map } from "lodash";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCurrencyFormat } from "../../../util/Util";
import ApiEngine from "../../../util/ApiEngine";
import moment from "moment";
import ContentHeader from "../../../components/contentheader/header";
import SweetAlert from "react-bootstrap-sweetalert";
import { setThemeName, setPaymentList } from "../../../redux/AppAction";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import WithdrawByBankTransfer from "../transaction/WithdrawByBankTransfer";
import { heartBeat } from "../../../redux/AuthAction";
import Spinner from "react-bootstrap/Spinner";
import WithdrawByPhoneNumber from "../transaction/WithdrawByPhoneNumber";
import WithdrawByEWallet from "../transaction/WithdrawByEWallet";

/// <summary>
/// Author: -
/// </summary>
const WithdrawalMethods = ({isHidden}) => {
	var { t, i18n } = useTranslation();
	const _dispatch = useDispatch();
	var _history = useHistory();
	var _userData = useSelector((state) => state.authState.userData);
	const [headerMsg, setHeaderMsg] = useState("");
	const [countdownPeriod, setCountdownPeriod] = useState(0);
	const [hasPendingTxn, setHasPendingTxn] = useState(false);
	const [countdownStr, setCountdownStr] = useState("");
	const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
	const [transType, setTransType] = useState("");
	const [countdownTime, setCountdownTime] = useState();
	const _SECONDS_IN_MS = 1000;
	const [isInsufficientAmount, setIsInsufficientAmount] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showWithdrawBankTransfer, setShowWithdrawBankTransfer] = useState(false);
	const [showWithdrawTopkash, setShowWithdrawTopkash] = useState(false);
	const [showWithdrawMethods, setShowWithdrawMethods] = useState(true);
	const [showWithdrawBCPay, setShowWithdrawBCPay] = useState(false);
	const [instantPayOption, setInstantPayOption] = useState([]);
	const [isInitFinished, setIsInitFinished] = useState(false); // used to ensure RestoreAllWallet is done
	const [isWithdrawEleReady, setIsWithdrawEleReady] = useState(false); 
	const [showWithdrawByPhoneNumber1, setShowWithdrawByPhoneNumber1] = useState(false);
	const [showWithdrawByPhoneNumber2, setShowWithdrawByPhoneNumber2] = useState(false);
	const [showWithdrawByEWallet, setShowWithdrawByEWallet] = useState(false);
	const [paymentOptionList, setPaymentOptionList] = useState(
		useSelector((state) => state.appState.payments)
	);
	const [withdrawMethods, setWithdrawMethods] = useState([
	]);
	const [isNormalWithdrawalEnable, setIsNormalWithdrawalEnable] = useState(
		useSelector((state) => state.appState.isNormalWithdrawalEnable)
	);

	const [isLoading, setIsLoading] = useState(false);
	const [showBackward, setShowBackward] = useState(false);
	const [selectedWithdrawMethod, setSelectedWithdrawMethod] = useState([]);

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		if (isWithdrawEleReady) {
			isHidden();
		}
	}, [isWithdrawEleReady]);

	const handleChildRenderComplete = () => {
		setIsInitFinished(true);
		setIsWithdrawEleReady(true);
		setIsLoading(false);
	};
	const afterHandleCheck = () => {
		setSelectedWithdrawMethod([]);
		setShowWithdrawMethods(true);
		setShowBackward(false);

		// hide all the child components
		setShowWithdrawBankTransfer(false);
		setShowWithdrawByPhoneNumber1(false);
		setShowWithdrawByPhoneNumber2(false);
		setShowWithdrawByEWallet(false);
		setIsWithdrawEleReady(false);

		setIsInitFinished(false);
		setIsWithdrawEleReady(false);
		setIsLoading(false);
		
		init();
		
	};


	async function init() {
		_dispatch(heartBeat());
		setIsLoading(true);

		setHeaderMsg("");
		// setWithdrawMethods([])
		let withdrawList = []
		if (isNormalWithdrawalEnable) {


			withdrawList = [
                ...withdrawList,
                {
                    title: "BANK_TRANSFER",
                    handle: "BANK_TRANSFER",
                    remark: t("NORMAL_BANK_TRANSFER"),
                },
            ];
			// setWithdrawMethods((withdrawMethods) => [
			// 	...withdrawMethods,
			// 	{
			// 		// image: require("../../../assets/img/new_icons/bank.png"),
			// 		title: "BANK_TRANSFER",
			// 		handle: "BANK_TRANSFER",
			// 		remark: t("NORMAL_BANK_TRANSFER"),
			// 		// link: WebUrl._URL_WITHDRWAL_BY_BANK_TRANSFER,
			// 	}
			// ]);

			setWithdrawMethods(withdrawList)
		}

		var responseJson = await ApiEngine.get(ApiUrl._API_GET_PENDING_TRANSACTION);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			if (responseJson[ApiKey._API_DATA_KEY] != null) {
				clearTimeout(countdownTime);
				let tempS = parseInt(responseJson[ApiKey._API_DATA_KEY]["timeSpan"]);
				setTransType(responseJson[ApiKey._API_DATA_KEY]["transType"]);
				setTimeDiffSeconds(tempS);
				setCountdownPeriod(tempS < 0 ? 0 : tempS);
				setHasPendingTxn(true);
				setHeaderMsg(t("NEW_TXN_PROHIBITED"));
			}
			else {
				await getRestore();
			}
		}

		//KT this is very slow, we're not going to use Payment Gateway for AUD, might as well dont check and waste time

		var responseJson3 = await ApiEngine.get(ApiUrl._API_GET_EWALLET_STATUS);
		if (responseJson3[ApiKey._API_SUCCESS_KEY]) {
			if (responseJson3[ApiKey._API_DATA_KEY]) {
				setWithdrawMethods((withdrawMethods) => [
					...withdrawMethods,
					{
						title: t("E_WALLET"),
						handle: "E_WALLET",
						remark: ""
					},
				]);
			}
		}

		// Check if redux already has the payment option list saved
		// we dont have payment gateway for withdrawal yet. so comment first (JW)
		//if (paymentOptionList.length > 0) {
		//	initOptions(paymentOptionList);
		//} else {
		//	let responseJson2 = await ApiEngine.get(
		//		ApiUrl._API_GET_USER_PAYMENT_GATEWAY_INFO
		//	);
		//	if (responseJson2[ApiKey._API_SUCCESS_KEY]) {
		//		// Save payment list into redux to avoid calling API again if unnecessary
		//		_dispatch(setPaymentList(responseJson2[ApiKey._API_DATA_KEY]));

		//		initOptions(responseJson2[ApiKey._API_DATA_KEY]);
		//	}
		//}

		setIsInitFinished(true);
		setIsLoading(false);
	}

	function initOptions(responseJsonData) {
		if(responseJsonData) {
			setInstantPayOption(responseJsonData);

			responseJsonData.forEach((item) => {
				setWithdrawMethods((withdrawMethods) => [
					...withdrawMethods,
					{
						title: item.instantPayGatewayString,
						handle: item.instantPayGatewayString.toUpperCase().replace(/ /g, "_"),
						remark: item.remarks
					},
				]);
			})

			if (withdrawMethods.length === 1 && responseJsonData.length == 0) {
				setShowWithdrawBankTransfer(true);
				setShowBackward(false);
			}
		}
	}

	async function getRestore() {
		let responseJson = await ApiEngine.get(ApiUrl._API_RESTORE_ALL_WALLET);
	}

	useEffect(() => {
		if (countdownPeriod > 0) {
			clearTimeout(countdownTime);
			setCountdownTime(
				setTimeout(() => {
					let tempPeriod = countdownPeriod - 1;
					setCountdownPeriod(tempPeriod);
					let tempX = moment.duration(tempPeriod);
					let tempMM = Math.floor(tempX["_milliseconds"] / 60);
					let tempSS = tempX["_milliseconds"] - tempMM * 60;
					setTimeDiffSeconds(tempX["_milliseconds"]);
					let tempStr =
						(tempMM.toString().length > 1 ? tempMM : "0" + tempMM) +
						" " +
						t("MINUTES") +
						" " +
						(tempSS.toString().length > 1 ? tempSS : "0" + tempSS) +
						" " +
						t("SECOND");
					setCountdownStr(tempStr);
				}, _SECONDS_IN_MS)
			);
		}
	}, [countdownPeriod]);

	return (
		<div className="test2">
			{ !isLoading && hasPendingTxn && (
				<div className="mt-3 mb-3 reminder-red">
					<div className="first">
						<div className="first">
							<img
								src={require("../../../assets/img/new_icons/red.png")}
								className="img-responsive"
								alt="icon"
							/>
						</div>
						<div>
							<p className="font14 mb-0 f-weight-500">
								{timeDiffSeconds > 0
									? t("PLEASE_WAIT") +
									" " +
									countdownStr +
									" " +
									(transType === "Deposit"
										? t("FOR_DEPOSIT_APPROVAL")
										: t("FOR_WITHDRAWAL_APPROVAL"))
									: headerMsg}
							</p>
						</div>
					</div>
				</div>
			)}

			{!hasPendingTxn && showWithdrawMethods && (
				<>
					{showConfirmation && (
						<SweetAlert
							warning
							confirmBtnText={t("CONFIRM")}
							confirmBtnBsStyle="danger"
							onConfirm={() => {
								setShowConfirmation(false);
							}}
							style={{
								backgroundColor: "#05102F",
								borderColor: "white !important",
								opacity: 0.9,
								color: "white",
								marginTop: "68%",
							}}
							cancelBtnBsStyle="#45ff8b"
						>
							{t(
								"PLEASE_WAIT_FOR_PREVIOUS_TRANSACTION_APPROVAL_OR_CONTACT_CUSTOMER_SERVICE"
							)}
						</SweetAlert>
					)}

					{/* {isInsufficientAmount && (
						<div className="mt-3 mb-3 reminder-yellow">
							<div className="first">
								<div className="first">
									<img
										src={require("../../../assets/img/new_icons/yellow.png")}
										className="img-responsive"
										alt="icon"
									/>
								</div>
								<div>
									<p className="font14 mb-0 f-weight-500">
										{t("AMOUNT_TOO_LESS")}
									</p>
								</div>
							</div>
						</div>
					)} */}

					<div className="test1">
						{isInitFinished && withdrawMethods && withdrawMethods.length > 1 && (
							<>
								<div
									className={
										_userData["walletBalance"] > 0
											? "font14 f-weight-500 mb-3"
											: "font14 f-weight-500 mb-3 disabled-action"
									}
								>
									{t("CHOOSE_ONE_FROM_THE_AVAILABLE_OPTIONS") }
								</div>
								<div className="t3-settings-menu-list mt-3">
									{
										map(withdrawMethods, (item, index) => {
											return (
												<div
													className="t3-settings-menu-list-item"
													key={index}
													onClick={() => {
														if (hasPendingTxn) {
															setShowConfirmation(true);
														} else {
															setShowWithdrawMethods(false);

															if (item.handle == "BANK_TRANSFER") {
																setShowWithdrawBankTransfer(true);
															}
															else if (item.handle == "BCPAY") {
																setShowWithdrawBCPay(true);
															}
															else if (item.handle == "TAKAPAY_EWALLET") {
																setShowWithdrawByPhoneNumber1(true);
															}
															else if (item.handle == "TAKAPAY_DIRECT") {
																setShowWithdrawByPhoneNumber2(true);
															}
															else if (item.handle == "E_WALLET") {
																setShowWithdrawByEWallet(true);
															}

															setShowBackward(true);
															setSelectedWithdrawMethod(item);
															setIsLoading(true);
														}
													}}
													style={{ cursor: "pointer" }}
												>
													<div className="first">
														<div>
															<VIcon
																icon={item.handle !== "BANK_TRANSFER" ? themeIcons.wallet : themeIcons.settingsBank}
																className="theme-icon-size-20 vicon"
															/>
														</div>
														<div>
															<div>
																<div
																	className={
																		_userData["walletBalance"] > 0
																			? "title"
																			: "title disabled-action"
																	}
																>
																	{t(item.title)}
																</div>
																<span className="remark">{item["remark"]}</span>
															</div>
														</div>
													</div>
													<div className="second">
														<VIcon icon={themeIcons.angleRight} className="vicon" />
													</div>
												</div>
											);
										})
									}
								</div>
							</>
						)}
					</div>
				</>
			)}
			{isInitFinished && withdrawMethods && withdrawMethods.length == 1 && withdrawMethods[0].title =="BANK_TRANSFER" && (
				<div style={(isInitFinished && isWithdrawEleReady) ? { display: "block" } : { display: "none" }}>
					<WithdrawByBankTransfer
						// hidden={!isInitFinished && !isWithdrawEleReady} 
						onRenderComplete={handleChildRenderComplete}
						onAfterHandleCheck={afterHandleCheck}

					/>
				</div>
			)}
			{console.log(withdrawMethods) }
			{withdrawMethods.length == 1 && withdrawMethods[0].handle == "E_WALLET" && (
				<div style={(isInitFinished && isWithdrawEleReady) ? { display: "block" } : { display: "none" }}>
					<WithdrawByEWallet
						onRenderComplete={handleChildRenderComplete}
					/>
				</div>
			)}

			{showBackward && (
				<div className="d-flex align-items-center mb-3">
					<div
						className="deposit-withdraw-back-btn"
						onClick={() => {
							setSelectedWithdrawMethod([]);
							setShowWithdrawMethods(true);
							setShowBackward(false);

							// hide all the child components
							setShowWithdrawBankTransfer(false);
							setShowWithdrawByPhoneNumber1(false);
							setShowWithdrawByPhoneNumber2(false);
							setShowWithdrawByEWallet(false);
							setIsWithdrawEleReady(false);
						}}
					>
						<VIcon
							icon={themeIcons.angleLeft}
							className="vicon theme-icon-size-20"
						/>
					</div>
					<h5 className="mb-0 ml-3">
						{selectedWithdrawMethod.handle === "BANK_TRANSFER" 
							? t("NORMAL_BANK_TRANSFER") 
							: t(`${selectedWithdrawMethod.handle}`)
						}
					</h5>
				</div>
			)}

			{showWithdrawBankTransfer && (
				<div style={(isInitFinished && isWithdrawEleReady) ? { display: "block" } : { display: "none" }}>
					<WithdrawByBankTransfer 
						// hidden={!isInitFinished && !isWithdrawEleReady} 
						onRenderComplete={handleChildRenderComplete}
						onAfterHandleCheck={afterHandleCheck}
					/>
				</div>
			)}

			{/*We don't have payment gateway for withdrawal yet*/}

			{/*{showWithdrawByPhoneNumber1 && (*/}
			{/*	<div style={(isInitFinished && isWithdrawEleReady) ? { display: "block" } : { display: "none" }}>*/}
			{/*		<WithdrawByPhoneNumber */}
			{/*			// hidden={!isInitFinished && !isWithdrawEleReady}*/}
			{/*			onRenderComplete={handleChildRenderComplete}*/}
			{/*			type="TAKAPAY_EWALLET"*/}
			{/*			instantPayOption={instantPayOption.filter((x) => x.instantPayGatewayString === selectedWithdrawMethod.title)}*/}
			{/*			instantBankSettingId={*/}
			{/*				instantPayOption.filter(*/}
			{/*					(x) => x.instantPayGatewayString === selectedWithdrawMethod.title*/}
			{/*				)[0].id*/}
			{/*			}*/}
			{/*			bankId={*/}
			{/*				instantPayOption.filter(*/}
			{/*					(x) => x.instantPayGatewayString === selectedWithdrawMethod.title*/}
			{/*				)[0].instantBankSettingModel[0].bankId*/}
			{/*			}*/}
			{/*			instantBankSetting={find(instantPayOption, {instantPayGatewayString : selectedWithdrawMethod.title})}*/}
			{/*			instantPayGatewayId={*/}
			{/*				instantPayOption.filter(*/}
			{/*					(x) => x.instantPayGatewayString === selectedWithdrawMethod.title*/}
			{/*				)[0].instantPayGatewayId*/}
			{/*			}*/}
			{/*			 instantPayGatewayNotes={*/}
			{/*				instantPayOption.filter(*/}
			{/*					(x) => x.instantPayGatewayString === selectedWithdrawMethod.title*/}
			{/*				)[0].notes*/}
			{/*			}*/}
			{/*		/>*/}
			{/*	</div>*/}
			{/*)}*/}

			{/*We don't have payment gateway for withdrawal yet*/}

			{/*{showWithdrawByPhoneNumber2 && (*/}
			{/*	<div style={(isInitFinished && isWithdrawEleReady) ? { display: "block" } : { display: "none" }}>*/}
			{/*		<WithdrawByPhoneNumber*/}
			{/*			// hidden={!isInitFinished && !isWithdrawEleReady}*/}
			{/*			onRenderComplete={handleChildRenderComplete}*/}
			{/*			type="TAKAPAY_DIRECT"*/}
			{/*			instantPayOption={instantPayOption.filter((x) => x.instantPayGatewayString === selectedWithdrawMethod.title)}*/}
			{/*			instantBankSettingId={*/}
			{/*				instantPayOption.filter(*/}
			{/*					(x) => x.instantPayGatewayString === selectedWithdrawMethod.title*/}
			{/*				)[0].id*/}
			{/*			}*/}
			{/*			bankId={*/}
			{/*				instantPayOption.filter(*/}
			{/*					(x) => x.instantPayGatewayString === selectedWithdrawMethod.title*/}
			{/*				)[0].instantBankSettingModel[0].bankId*/}
			{/*			}*/}
			{/*			instantBankSetting={find(instantPayOption, { instantPayGatewayString: selectedWithdrawMethod.title })}*/}
			{/*			instantPayGatewayId={*/}
			{/*				instantPayOption.filter(*/}
			{/*					(x) => x.instantPayGatewayString === selectedWithdrawMethod.title*/}
			{/*				)[0].instantPayGatewayId*/}
			{/*			}*/}
			{/*			instantPayGatewayNotes={*/}
			{/*				instantPayOption.filter(*/}
			{/*					(x) => x.instantPayGatewayString === selectedWithdrawMethod.title*/}
			{/*				)[0].notes*/}
			{/*			}*/}
			{/*		/>*/}
			{/*	</div>*/}
			{/*)}*/}

			{showWithdrawByEWallet && (
				<div style={(isInitFinished && isWithdrawEleReady) ? { display: "block" } : { display: "none" }}>
					<WithdrawByEWallet
						onRenderComplete={handleChildRenderComplete}
					/>
				</div>
			)}

			{/* <WithdrawByBCPay 
				instantBankSettingId={instantPayOption.filter((x) => x.instantPayGatewayString === "BCPay")[0].id} 
				bankId={instantPayOption.filter((x) => x.instantPayGatewayString === "BCPay")[0].instantBankSettingModel[0].bankId}
				instantPayGatewayId={instantPayOption.filter((x) => x.instantPayGatewayString === "BCPay")[0].instantPayGatewayId}
			/> */}

			{isLoading && (
				<div
					style={{
						textAlign: "center",
						marginTop: "25px",
						marginBottom: "20px",
					}}
				>
					<Spinner
						animation="border"
						role="status"
						size="sm"
						style={{
							verticalAlign: "middle",
							width: "2rem",
							height: "2rem",
						}}
					>
						<span className="sr-only">{t("LOADING")}...</span>
					</Spinner>
				</div>
			)}
		</div>
	);
};

export default WithdrawalMethods;
