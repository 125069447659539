import { themeIcons } from "config/theme";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import i18n from "localization/i18n";
import {
  setRegisterModal,
  showResponseMessage,
  setLoginModal,
  showMessage,
  setDepositModal,
  setCompleteInfoModal
} from "../../redux/AppAction.js";
import { useTranslation } from "react-i18next";
import { Alert, Modal, ModalBody, Spinner } from "reactstrap";
import Input from "components/custom/Input";
import VIcon from "components/custom/Icon";
import { useDispatch, useSelector } from "react-redux";
// import { setLoginModal, showMessage, setBusy, setIdle, bindCompanyIcon } from "redux/AppAction";
import { useForm } from "react-hook-form";
import { performLoginReturnMessage } from "redux/AuthAction";
import SecureLS from "secure-ls";
import {
  AlertTypes, ApiKey, ApiUrl,
  /*PhoneNumberPrefix,*/
  SessionKey
} from "util/Constant";
import { createFormBody, createMultiPartFormBody, stringIsNullOrEmpty } from "../../util/Util";
import ApiEngine from "util/ApiEngine";
import { map, debounce, find, filter, upperCase, get } from "lodash";
import PinInput from "react-pin-input";
import {
	loadCaptchaEnginge,
	LoadCanvasTemplateNoReload,
	validateCaptcha,
} from "react-simple-captcha";


const RegisterModal = (props) => {
  var _dispatch = useDispatch();
  var { t } = useTranslation();

  const search = window.location.search;

  const params = new URLSearchParams(search);
  const elePin = useRef(null);

  const PhoneNumberPrefix = useSelector((state) => state.appState.dialingCodes);
  const [referralCode, setReferralCode] = useState("");
  const [loginBanner, setLoginBanner] = useState();
  const [disableRegister, setDisableRegister] = useState(false);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [disableViaEmail, setDisableViaEmail] = useState(false);
  const [viaEmailLoading, setViaEmailLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [showCodeError, setShowCodeError] = useState(false);
  const [referralCodeString, setReferralCodeString] = useState("");
  const companyIcons = useSelector((state) => state.appState.companyIcons);
  var { heartBeat } = useSelector((state) => state.authState);
  const registerModal = useSelector((state) => state.appState.registerModal);
  const companyLanguages = useSelector((state) => state.appState.languages);

  const [logo, setLogo] = useState();
  const [selectedChannel, setSelectedChannel] = useState();

  const [disabledRefrerral, setDisabledRefrerral] = useState(false);

  const [_username, _setUsername] = useState("");
  const [_email, _setEmail] = useState("");

  const [_emailText, _setEmailText] = useState("");

  const [selectedPhonePrefix, setSelectedPhonePrefix] = useState(
    ""
  );
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [formattedPhone, setFormattedPhone] = useState("");
  const _MIN_PASSWORD_LENGTH = 9;
  const [hideLoginPassword, setHideLoginPassword] = useState(true);

  const [registerSteps, setRegisterSteps] = useState(1);
  const [registerStepsViaEmail, setRegisterStepsViaEmail] = useState(false);
  const [registerStepsViaOtherChannel, setRegisterStepsViaOtherChannel] = useState(false);

  const [registerStepsThreeFirst, setRegisterStepsThreeFirst] = useState(false);
  const [pinInputDisabled, setPinInputDisabled] = useState(false);

  const [TACsent, setTACsent] = useState(true);

  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [TACcountdownPeriod, setTACCountdownPeriod] = useState(0);
  const [TACtimedOut, setTACtimedOut] = useState(true);
  const [canSendTAC, setCanSendTAC] = useState(true);
  const [TACtimer, setTACtimer] = useState(setTimeout(() => { }, 60000));
  // const [TACtimer, setTACtimer] = useState(setTimeout(() => {}, 5000));

  const _TAC_VALID_TIMER = 60000;
  // const _TAC_VALID_TIMER = 5000;

  const _SECONDS_IN_MS = 1000;
  const _TAC_TIMEOUT_IN_MINUTES = 1;

  const [pinLoading, setPinLoading] = useState(false);
  const [timerPeriod, setTimerPeriod] = useState(null);

  const customerServiceUrl = "/livechat";
  const [attemp, setAttemp] = useState(1);
  var registerChannel = useSelector((state) => state.appState.chatSphere);
  const [alertIcon, setAlertIcon] = useState();

  const [tempPinNumber, setTempPinNumber] = useState();
  const OTP_VIA_SMS = 1;
  const OTP_VIA_EMAIL = 2;
  const OTP_BY_WHATSAPP = 3;
  const OTP_VIA_TELEGRAM = 4;

  const [phoneNumber, setPhoneNumber] = useState("");

  //new flow
  const [smsChannel, setSmsChannel] = useState(false);
  const [chatSphereChannel, setChatSphereChannel] = useState(false);
  const [chatSphereChannelList, setChatSphereChannelList] = useState([])
  const [channleClick, setChannleClick] = useState('')

  const [channelType, setChannelType] = useState(OTP_VIA_SMS)

  const [showModalRegister, setShowModalRegister] = useState(false)

  const otpEnabled = useSelector((state) => state.appState.otpEnabled);
  const captchaEnabled = useSelector((state) => state.appState.captchaEnabled);
  const priorityModalType = useSelector((state) => state.appState.priorityModalTypeId)

  const {
    register,
    handleSubmit,
    errors,
    clearErrors,
    setError,
    setValue,
    getValues,
    watch,
    control,
    trigger,
    phoneNumberChk,
  } = useForm();

  const _captcha_length = 4;

  useEffect(() => {

    // init();
    //loadCaptchaEnginge(_captcha_length, "black", "white", "numbers");
    const parsedReferralCode = params.get("code");
    if (parsedReferralCode != null && !heartBeat) {
      // getUpline(parsedReferralCode);
      _dispatch(setRegisterModal(true));
      setReferralCode(parsedReferralCode);
      // setRegisterSteps(2);
      checkIfReferralExist(parsedReferralCode);
    } else if (parsedReferralCode != null && heartBeat) {
      _dispatch(
        showMessage({
          //KT: The company icons details not loaded when reach this point.
          content: t("ALREADY_LOGIN"),
        })
      );
    }

  }, []);

  //seem like not using
  useEffect(() => {
    if (selectedChannel) {
      if (!stringIsNullOrEmpty(selectedChannel.attachment)) {
        // setToggleModal(true);
      }
    }
  }, [selectedChannel]);

  useEffect(() => {
    if (companyIcons.length > 0) {
      getCompanyIcon();
    }
  }, [companyIcons]);

  useEffect(() => {
    if (registerModal) {
      setShowModalRegister(true)
      init();

    }
  }, [registerModal]);

  useEffect(() => {

    if (!showModalRegister) {
      setRegisterSteps(1);
      setAttemp(1);
      _setEmail("");
      _setEmailText("");
      clearTimeout(timerPeriod);
      clearTimeout(TACtimer);
      setRegisterStepsThreeFirst(false)

    }
  }, [showModalRegister]);


  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimerPeriod(
        setTimeout(() => {
          let tempPeriod = countdownPeriod - 1;
          setCountdownPeriod(tempPeriod);
        }, _SECONDS_IN_MS)
      );
    }
  }, [countdownPeriod]);

  useEffect(() => {
    if (TACcountdownPeriod > 0) {
      setTACtimer(
        setTimeout(() => {
          let tempPeriod = TACcountdownPeriod - 1;
          setTACCountdownPeriod(tempPeriod);
        }, _SECONDS_IN_MS)
      );
    }
  }, [TACcountdownPeriod]);


  const init = async () => {

    let responseJson = await ApiEngine.get(ApiUrl._API_GET_COMPANY_CHANNEL);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let channelList = responseJson[ApiKey._API_DATA_KEY]
      if (channelList) {
        setChatSphereChannel(channelList.chatSphereChannelEnabled);
        setSmsChannel(channelList.smsChannelEnabled);

        if (channelList.chatSphereActionType.length > 0) {
          setChatSphereChannelList(channelList.chatSphereActionType)
        }
      }
      }
    if (captchaEnabled){
        loadCaptchaEnginge(_captcha_length, "black", "white", "numbers");
    }
  }
  async function getCompanyIcon() {
    // _dispatch(setBusy());
    const alert = find(companyIcons, { iconCategory: "alert" });
    if (alert) {
      setAlertIcon(alert.iconImage||alert.defaultImage);
    }


    const loginBanner = find(companyIcons, { iconCategory: "login_popup" });
    if (loginBanner) {
      setLoginBanner(loginBanner.iconImage||loginBanner.defaultImage);
    }

    const logo = find(companyIcons, { iconCategory: "logo" });
    if (logo) {
      setLogo(logo.iconImage||logo.defaultImage);
    }

    // _dispatch(setIdle());
  }

  async function checkIfReferralExist(code) {
    let referralCode = code;
    if (code.includes(" +")) {
      referralCode = code.substring(0, code.indexOf(" +"));
    }
    let apiUrl =
      ApiUrl._API_CHECK_IF_REFERRAL_CODE_EXIST + "?code=" + referralCode;
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setDisabledRefrerral(true);
      setRegisterSteps(1);
    } else {
      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          t("ERROR_REFERRAL_CODE_CANNOT_BE_FOUND")
        )
      );
    }
  }

  const validateReferralCode = (e) => {
    var regexp = /^[A-Za-z0-9]+$/i;
    if (e.nativeEvent.data === null || regexp.test(e.nativeEvent.data)) {
      setReferralCode(e.target.value);
    }
  };
  const onChangeUsername = useCallback(
    debounce(async (e) => {
      var regexp = /^[\S]*^[^ \[\]`~\/!@#$%^&*()"':;?|{}=+_<>,.\\-]*$/g;
      if (e.nativeEvent.data === null || regexp.test(e.nativeEvent.data)) {
        _setUsername(e.target.value);
      }
    }, 500),
    []
  );

  async function checkUsername(username) {
    let avail = true;
    let responseJson = await ApiEngine.get(
      ApiUrl._API_CHECK_USERNAME +
      "?username=" +
      username +
      "&code=" +
      referralCode
    );
    avail = responseJson[ApiKey._API_SUCCESS_KEY];


    return avail;
  }

  function onchange(e) {
    const re = /^[0-9\b]+$/;
    let value = e.target.value;

    // Check if the value contains only digits
    if (re.test(value)) {
      // Limit the value to 12 characters
      if (value.length > 12) {
        value = value.slice(0, 12);
      }

      // setIsDigit(true);
      setFormattedPhone(value);
    } else {
      return false;
      // setIsDigit(false);
    }
  }

  const handleChange = (event) => {
    // Remove spaces and convert to lowercase
    const newValue = event.target.value.replace(/\s/g, "").toLowerCase();
    _setEmail(newValue);
  };

  async function checkIfReferralExist2(code) {
    let referralCode = code;
    if (code.includes(" +")) {
      referralCode = code.substring(0, code.indexOf(" +"));
    }
    let apiUrl =
      ApiUrl._API_CHECK_IF_REFERRAL_CODE_EXIST + "?code=" + referralCode;
    let responseJson = await ApiEngine.get(apiUrl);
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      return true;
    } else {
      return false;
    }
  }

  async function submitPin(pin) {
    let params = {
      username: _username,
      email: _email,
      password: password,
      phoneNumber: (selectedPhonePrefix == "" ? PhoneNumberPrefix[0].value : selectedPhonePrefix) + formattedPhone,
      referralCode: referralCodeString,
      TAC: pin,
      otpEnabled : otpEnabled
    };

    if (companyLanguages != undefined) {
			const defaultLang = get(companyLanguages[0], "code");
			if (
				localStorage.getItem(SessionKey._LANGUAGE) &&
				localStorage.getItem(SessionKey._LANGUAGE) !== "undefined"
			) {
				if (
					companyLanguages.find(
						(x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
					) != undefined
				) {
          params["languageSetting"] = companyLanguages.find(
            (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
          ).code;
				} else {
					params["languageSetting"] = defaultLang;
				}
			} else {
				params["languageSetting"] = defaultLang;
			}
		}


    let responseJson = await ApiEngine.post(
      ApiUrl._API_REGISTER,
      createFormBody(params)
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setRegisterSteps(3);
      _dispatch(
        showMessage({
          type: AlertTypes._SUCCESS,
          content: t("REGISTRATION_COMPLETED"), // KT: Can say something about deposit now and get promotion etc maybe ?
          onConfirm: async () => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
              if (responseJson[ApiKey._API_DATA_KEY]) {
                // KT: Register completed.
                _dispatch(setRegisterModal(false));
                setShowModalRegister(false)
                if(priorityModalType == 1){
                  _dispatch(setDepositModal(true));

                }else{
                  _dispatch(setCompleteInfoModal(true));

                }
              } else {
                window.location.replace("https://www.google.com");
              }
            } else {
            }
          },
        })
      );
    } else {
      _dispatch(
        showResponseMessage(false, t(responseJson[ApiKey._API_MESSAGE_KEY]))
      );
      elePin["current"].clear();
      // setPinNumber("");
      // setEnteredPinNumber("");
      // setEnteredPinNumberConfirm("");
      // setPinNumberConfirmFocused(0);
    }
  }

  async function onKeyPress(key, index) {
    //setAllowPinClick(false);
    // 1st: have to check currentPinRef
    // 2nd: have to know which index is currently focused for altering the state.value
    // 3rd: have to check if at elePin.index(3), if yes then convert value to string and focus on eleConfirm.index(0)
    // 4th: have to check if at eleConfirm.index(3), if yes then convert value to string, and submitPin
    // let currentIndex = 0;
    let pinStr = tempPinNumber;
    let keyLength = key.split("");
    if (key !== "{bksp}" && key !== "&nbsp;") {
      pinStr = key;
      setTempPinNumber(pinStr);
      if (keyLength.length !== 6) {
      } else {
        setTempPinNumber("");
        setPinInputDisabled(true);
        setPinLoading(true);

        await submitPin(pinStr);

        setPinLoading(false);
        setPinInputDisabled(false);
      }
    } else {
      elePin["current"].clear();
      // setPinNumber("");
      // setTempPinNumber("");
      // setPinNumberConfirmFocused(0);
      // setTempPinNumberFocused(0);
    }
  }

  async function checkEmail(email) {
    let avail = true;
    let responseJson = await ApiEngine.get(
      ApiUrl._API_CHECK_EMAIL + "?email=" + email + "&code=" + referralCode
    );
    avail = responseJson[ApiKey._API_SUCCESS_KEY];
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      // setIsEmailAvailable(true);
    } else {
      // setIsEmailAvailable(false);
    }

    return avail;
  }

  async function registerWithoutOTP(data, referralString) {
      let params = {
          username: data.username,
          email: _email,
          password: data.password,
          phoneNumber: (selectedPhonePrefix == "" ? PhoneNumberPrefix[0].value : selectedPhonePrefix) + formattedPhone,
          referralCode: referralString,
          otpEnabled: otpEnabled
      };

      if (companyLanguages != undefined) {
          const defaultLang = get(companyLanguages[0], "code");
          if (
              localStorage.getItem(SessionKey._LANGUAGE) &&
              localStorage.getItem(SessionKey._LANGUAGE) !== "undefined"
          ) {
              if (
                  companyLanguages.find(
                      (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
                  ) != undefined
              ) {
                  params["languageSetting"] = companyLanguages.find(
                      (x) => x.code === localStorage.getItem(SessionKey._LANGUAGE)
                  ).code;
              } else {
                  params["languageSetting"] = defaultLang;
              }
          } else {
              params["languageSetting"] = defaultLang;
          }
      }


      let responseJson = await ApiEngine.post(
          ApiUrl._API_REGISTER,
          createFormBody(params)
      );
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          _dispatch(
              showMessage({
                  type: AlertTypes._SUCCESS,
                  content: t("REGISTRATION_COMPLETED"), // KT: Can say something about deposit now and get promotion etc maybe ?
                  onConfirm: async () => {
                      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                          if (responseJson[ApiKey._API_DATA_KEY]) {
                              // KT: Register completed.
                              _dispatch(setRegisterModal(false));
                              setShowModalRegister(false)
                              // _dispatch(setDepositModal(true));
                              if(priorityModalType == 1){
                                _dispatch(setDepositModal(true));
              
                              }else{
                                _dispatch(setCompleteInfoModal(true));
              
                              }
                          } else {
                              window.location.replace("https://www.google.com");
                          }
                      } else {
                      }
                  },
              })
          );
      } else {
          _dispatch(
              showResponseMessage(false, t(responseJson[ApiKey._API_MESSAGE_KEY]))
          );

      }
  }

  const submitForm = async (data, e) => {
    setDisableViaEmail(true);
    setViaEmailLoading(true);
    // return false
    if (canSendTAC) {

      if (TACtimedOut || countdownPeriod > 0) {

        let params = {}
        if (channelType == OTP_VIA_EMAIL) {
          let emailAvail = await checkEmail(data.email);
          if (emailAvail) {
            params = {
              phoneNumber: (selectedPhonePrefix == "" ? PhoneNumberPrefix[0].value : selectedPhonePrefix) + formattedPhone,
              referralCode: referralCodeString,
              email: data.email,
              languageCode: "en",
              countdown: countdownPeriod,
              TACExist: !TACtimedOut,
              type: channelType,
              usage: "createMember",
            };


          } else {
            setError("email", { message: t("EMAIL_TAKEN") });
            setDisableViaEmail(false);
            setViaEmailLoading(false);
          }
        } else {
          params = {
            phoneNumber: (selectedPhonePrefix == "" ? PhoneNumberPrefix[0].value : selectedPhonePrefix) + formattedPhone,
            referralCode: referralCodeString,
            // email: data.email,
            languageCode: "en",
            countdown: countdownPeriod,
            TACExist: !TACtimedOut,
            type: OTP_VIA_SMS,
            usage: "createMember",
          };
        }
        // let emailAvail = await checkEmail(data.email);
        // if (emailAvail) {
        // let params = {
        // 	phoneNumber: selectedPhonePrefix + formattedPhone,
        // 	referralCode: referralCode,
        // 	email: data.email,
        // 	languageCode: "en",
        // 	countdown: countdownPeriod,
        // 	TACExist: !TACtimedOut,
        // 	type: channleType,
        // 	usage: "createMember",
        // };

        let responseJson = await ApiEngine.post(
          ApiUrl._API_GENERATE_OTP,
          createFormBody(params)
        );
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
              // _setEmail(data.email);
              // _setEmailText("*".repeat(4) + data.email.substr(4));
              if (channelType == OTP_VIA_EMAIL) {
                  _setEmail(data.email);
                  _setEmailText("*".repeat(4) + data.email.substr(4));
              }
              setTACsent(true);
              setTACtimedOut(false);
              setCountdownPeriod(0);
              setCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
              // setTACBtnAble(false);
              setAttemp(attemp + 1);
              setTimeout(() => {
                  // setTACBtnAble(true);
                  setTACtimedOut(true);
              }, _TAC_VALID_TIMER);
              setRegisterStepsViaEmail(false);
              setRegisterSteps(2);
              setRegisterStepsThreeFirst(true);
          }
        // } else {
        // 	setError("email", { message: t("EMAIL_TAKEN") });
        // 	setDisableViaEmail(false);
        // 	setViaEmailLoading(false);
        // }
      }
    }

    setCanSendTAC(false);
    clearTimeout(TACtimer);
    clearTimeout(timerPeriod);
    setTACCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);

    setTimeout(() => {
      setCanSendTAC(true);
    }, _SECONDS_IN_MS * 60 * _TAC_TIMEOUT_IN_MINUTES);

    setDisableViaEmail(false);
    setViaEmailLoading(false);
  };



  const submitRegisterForm2 = async (data, e) => {
    if (captchaEnabled) {
        var temp = validateCaptcha(data.loginCaptcha);
        if (temp !== true) {
            _dispatch(
                showResponseMessage(false, t("CAPTCHA_FAILED"))
            );
            return;
        }
    }

    setDisableRegister(true);
    setIsRegisterLoading(true);

    setPassword(data.password);
    let usernameAvail = await checkUsername(data.username);
    let phoneError = false;
    if (!usernameAvail) {
      setError("username", { message: t("USERNAME_TAKEN") });
    }

    let referralString = "";
    let referralCheck = true;
    if (referralCode !== "") {
      if (referralCode.length >= 8) {
        setShowCodeError(false);
        referralString = referralCode;
        setReferralCodeString(referralString);
        referralCheck = await checkIfReferralExist2(referralString);
        if (!referralCheck) {
          _dispatch(
            showResponseMessage(false, t("ERROR_REFERRAL_CODE_CANNOT_BE_FOUND"))
          );
          setIsRegisterLoading(false);
          setDisableRegister(false);
          return false;
        }
      } else {
        setShowCodeError(true);
      }
    } else {
      var defaultReferralCode = await ApiEngine.get(
        ApiUrl._API_GET_MASTER_REFERRAL_CODE
      );
      if (defaultReferralCode[ApiKey._API_SUCCESS_KEY]) {
        referralString = defaultReferralCode[ApiKey._API_DATA_KEY].code;

        setReferralCodeString(referralString);
      } else {
        _dispatch(
          showResponseMessage(false, t("ERROR_REFERRAL_CODE_CANNOT_BE_FOUND"))
        );
        setIsRegisterLoading(false);
        setDisableRegister(false);
        return false;
      }
    }

    var prefix = (selectedPhonePrefix == "" ? PhoneNumberPrefix[0].value : selectedPhonePrefix);
    if (
      typeof formattedPhone !== "undefined" &&
      (prefix + formattedPhone).length >= 10
    ) {
      if (!errors.phoneNumber && usernameAvail) {
        if (!otpEnabled) {
            await registerWithoutOTP(data, referralString);
        }
        else {
            let params = {
                phoneNumber: (prefix == "" ? PhoneNumberPrefix[0].value : prefix) + formattedPhone,
                referralCode: referralString,
                languageCode: "en",
                countdown: 0,
                TACExist: false,
                type: channelType,
                usage: "createMember",
            };

            let responseJson = await ApiEngine.post(
              ApiUrl._API_GENERATE_OTP,
              createFormBody(params)
            );
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
              const phoneNo = (prefix == "" ? PhoneNumberPrefix[0].value : prefix) + formattedPhone;
              const phoneNum =
                Array.from({ length: phoneNo.length - 3 - 1 }, () => "*").join("") +
                phoneNo.slice(-4);
              setPhoneNumber(phoneNum);
              _setUsername(data.username);
              setPhoneNumberError(false);
              phoneError = false;
            } else {
              _dispatch(
                showMessage({
                  custom: true,
                  customIcon: (
                    <img
                      className="m-auto"
                      src={alertIcon}
                      height="120px"
                      width="120px"
                      alt="Custom Icon"
                    />
                  ),
                  content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                })
              );
              phoneError = true;
              setPhoneNumberError(true);
            }
        }
      }
    } else {
      setError("registerPhone", { message: t("INVALID_PHONE_NUMBER") });
    }

    if (usernameAvail && !phoneError && referralCheck && otpEnabled) {
      setTACCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
      setCountdownPeriod(_TAC_VALID_TIMER / _SECONDS_IN_MS);
      setRegisterSteps(2);
      setRegisterStepsThreeFirst(true);
    }
console.log('done')
    setIsRegisterLoading(false);
    setDisableRegister(false);
  };


  async function handleRegisterClick(channel) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "chat",
          //url: `${channel.actionUrl}`,
          url:
            channel.actionUrl.match(/livechat/i) != null
              ? `${window.location.origin}${channel.url}`
              : `${channel.url}`,
        })
      );
    } else {
      var selectedReferralCode =
        referralCode == "" ? referralCodeString : referralCode;
      if (!stringIsNullOrEmpty(channel.url)) {
        // setToggleModal(false);
        let referralString = "";

        if (stringIsNullOrEmpty(selectedReferralCode)) {
          var defaultReferralCode = await ApiEngine.get(
            ApiUrl._API_GET_MASTER_REFERRAL_CODE
          );
          if (defaultReferralCode[ApiKey._API_SUCCESS_KEY]) {
            referralString = defaultReferralCode[ApiKey._API_DATA_KEY].code;

            selectedReferralCode = referralString;
          } else {
            _dispatch(
              showResponseMessage(
                false,
                t("ERROR_REFERRAL_CODE_CANNOT_BE_FOUND")
              )
            );
          }
        }

        window.location.href = channel.url + selectedReferralCode;
      } else {
        setSelectedChannel(channel);
        // setToggleModal(true);
      }
    }
  }

  return (
    <Modal
      id="t3-login-register-modal"
      fade={false}
      contentClassName={"t3-login-register-modal"}
      isOpen={registerModal}
      toggle={() => {
        _dispatch(setRegisterModal(!registerModal));
        setShowModalRegister(false);
      }}
      backdrop={"static"}
      centered
    >
      <ModalBody>
        <div className="t3-lr-modal-container">
          <div className="t3-lr-modal-header">
            <div className="t3-logo">
              <img src={logo} className="img-responsive" alt="logo" />
            </div>
            <div
              className="t3-close-modal"
              onClick={() => {
                if (params.get("code") != referralCode) {
                  setReferralCode("");
                }
                _dispatch(setRegisterModal(false));
                setShowModalRegister(false)

              }}
            >
              <VIcon icon={themeIcons.close} className="vicon" />
            </div>
          </div>
          <div className="t3-lr-modal-body register">
            <div className="left">

              <div className="image">
                <img
                  src={loginBanner}
                  className="img-responsive"
                  alt="BUILD THE BEST CASINO TOGETHER"
                />
              </div>
            </div>
            <div className="right">
              {registerSteps == 1 && (
                <form
                  onSubmit={handleSubmit(submitRegisterForm2)}
                  autocomplete="off"
                >
                  <div className="t3-lr-form">
                    <div className="t3-input-container mt-3">
                      <label>{t("ENTER_USERNAME")}</label>
                      <div>
                        <Input
                          ref={register({
                            required: true,
                            // validate: async (value) =>
                            // 	(await checkUsername(value)) ||
                            // 	(console.log("test"), "USERNAME_TAKEN"),
                            pattern: {
                              value: /^(?=.*[A-Za-z0-9])[A-Za-z0-9]{4,9}$/i,
                              message: t(
                                "USERNAME_REQUIRES_ALPHANUMERIC_WITH_LOWERCASE_MINIMUM_4_9_CHARACTERS_ONLY"
                              ),
                            },
                          })}
                          id="loginUsername"
                          name="username"
                          // value={_username.toLowerCase()}
                          // onInput={(e) => validateUsername(e)}
                          // onChange={onChangeUsername}
                          onInput={onChangeUsername}
                          errors={errors}
                          className="t3-custom-input-text"
                          autoFocus
                          autoComplete="off"
                          onKeyDown={(e) => {
                            const key = e.key;
                            const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];

                            // Allow lowercase letters, numbers, and allowed keys
                            if (!/[a-z0-9]/.test(key) && !allowedKeys.includes(key)) {
                              if (/[A-Z]/.test(key)) {
                                e.preventDefault();
                                const start = e.target.selectionStart;
                                const end = e.target.selectionEnd;

                                // Insert the lowercase version of the uppercase key
                                e.target.value = e.target.value.slice(0, start) + key.toLowerCase() + e.target.value.slice(end);
                                // Adjust the cursor position to be after the inserted character
                                e.target.setSelectionRange(start + 1, start + 1);
                              } else {
                                // Prevent any other unwanted key input
                                e.preventDefault();
                              }
                            }
                          }}
                        />

                      </div>
                    </div>
                    <div className="t3-input-container mt-3">
                      <label>{t("MOBILE_NUMBER")}</label>
                    </div>
                    <div className="t3-contact-number-box">
                      <div className="first">
                        <select
                          onChange={(e) => {
                            setSelectedPhonePrefix(e.target.value);
                          }}
                        >
                          {PhoneNumberPrefix.map((prefix, index) => {
                            return (
                              <option
                                key={index}
                                className={"phone-prefix-" + (index + 1)}
                                value={prefix.value}
                              >
                                {prefix.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="second">
                        <input
                          name="registerPhone"
                          className="t3-custom-input-text"
                          autoComplete="new-password"
                          ref={register({
                            required: true,
                            minLength: {
                              value: 8,
                              message: t("MIN_CHARACTERS", { length: 8 }),
                            },
                            maxLength: {
                              value: 11,
                              message: t("MAX_CHARACTERS", { length: 11 }),
                            },
                            pattern: {
                              value: /^[0-9]+$/g,
                              message:
                                t("MIN_CHARACTERS", { length: 8 }) +
                                " " +
                                t("AND") +
                                " " +
                                t("ONLY_NUMBERS"),
                            },
                          })}
                          errors={errors}
                          onKeyDown={(e) => {
                            const key = e.key;
                            const allowedKeys = [
                              "Backspace",
                              "ArrowLeft",
                              "ArrowRight",
                              "Delete",
                              "Tab",
                            ];

                            if (
                              !/[0-9]/.test(key) &&
                              !allowedKeys.includes(key)
                            ) {
                              e.preventDefault();
                            }

                            // Prevent '0' as the first character
                            const inputValue = e.target.value;
                            if (inputValue.length === 0 && key === "0") {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            let newValue = e.target.value;

                            // Remove all leading zeros
                            newValue = newValue.replace(/^0+/, '');
                        
                            // Call your original `onChange` function
                            onchange(e);
                        
                            // Update the input field with the modified value
                            e.target.value = newValue;
                          }}
                        />

                        {phoneNumberError == true ? (
                          <div className="d-flex align-items-center user-available">
                            <div className="font12 text-lgray3 second text-customred">
                              {t("NUMBER_NOT_AVAILABLE")}
                            </div>
                          </div>
                        ) : errors.registerPhone ? (
                          <div className="d-flex align-items-center user-available">
                            <div className="font12 text-lgray3 second text-customred">
                              {t(errors.registerPhone.message)}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="t3-input-container mt-3">
                      <label>{t("ENTER_YOUR_PASSWORD")}</label>
                      <div style={{ position: "relative" }}>
                        <Input
                          ref={register({
                            required: true,
                            minLength: {
                              value: _MIN_PASSWORD_LENGTH,
                              message: t("MIN_CHARACTERS", {
                                length: _MIN_PASSWORD_LENGTH,
                              }),
                            },
                            pattern: {
                              value:
                                /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/i,
                              message: t("PASSWORD_MESSAGE"),
                            },
                          })}
                          onKeyDown={(e) => {
                            const key = e.key;
                            const allowedKeys = [
                              "Backspace",
                              "ArrowLeft",
                              "ArrowRight",
                              "Delete",
                              "Tab",
                            ];

                            if (
                              !/[a-zA-Z0-9]/.test(key) &&
                              !allowedKeys.includes(key)
                            ) {
                              e.preventDefault();
                            }
                          }}
                          errors={errors}
                          id="password"
                          name="password"
                          type={hideLoginPassword ? "password" : "text"}
                          className="t3-custom-input-text"
                          autocomplete="new-password"
                          autoFocus
                        />
                        <VIcon
                          icon={
                            hideLoginPassword
                              ? themeIcons.eyeBlocked
                              : themeIcons.eye
                          }
                          className="vicon"
                          style={{ top: "20px" }}
                          onClick={() =>
                            setHideLoginPassword(!hideLoginPassword)
                          }
                        />
                      </div>
                    </div>

                    <div className="pl-2 pr-2">
                      <div className="font12 text-lgray6 second">
                        <VIcon
                          icon={themeIcons.checkmarkOutline}
                          className="theme-icon-size-15 mr-1"
                        />
                        {t("REGISTER_PAGE_MIN_9_CHARS")}
                      </div>
                      <div className="font12 text-lgray6 second">
                        <VIcon
                          icon={themeIcons.checkmarkOutline}
                          className="theme-icon-size-15 mr-1"
                        />
                        {t("ONLY_LETTERS_NUMBERS_9")}
                      </div>
                      <div className="font12 text-lgray6 second">
                        <VIcon
                          icon={themeIcons.checkmarkOutline}
                          className="theme-icon-size-15 mr-1"
                        />
                        {t("NO_SPECIAL_SYMBOLS")}
                      </div>
                    </div>
                    <div className="t3-input-container mt-3">
                      <label>
                        {t("KEY_IN_YOUR_REFERRAL_CODE")} ({t("OPTIONAL")})
                      </label>
                      <div>
                        <Input
                          id="referral"
                          name="referral"
                          className={
                            "t3-custom-input-text" +
                            (disabledRefrerral ? " disabled" : "")
                          }
                          onInput={(e) => validateReferralCode(e)}
                          value={referralCode}
                          autoFocus
                        // disabled={true}
                        />
                      </div>
                      {showCodeError && (
                        <div className="d-flex align-items-center user-available mb-4">
                          <div className="font12 text-lgray3 second text-customred">
                            {t("INVALID_REFERRAL_CODE")}
                          </div>
                        </div>
                      )}
                    </div>
                    {captchaEnabled && 
                        <div className="t3-input-container mt-3">
                            <div style={{ display: "flex" }}>
                                <input
                                    name="loginCaptcha"
                                    ref={register({
                                        required: "PLEASE_ENTER_CAPTCHA",
                                        minLength: {
                                            value: _captcha_length,
                                            message: "PLEASE_FOLLOW_CAPTCHA_LENGTH",
                                        },
                                        maxLength: {
                                            value: _captcha_length,
                                            message: "PLEASE_FOLLOW_CAPTCHA_LENGTH",
                                        },
                                    })}
                                    type="text"
                                    className="t3-custom-input-text-captcha"
                                    placeholder={t("PLEASE_ENTER_CAPTCHA")}
                                />
                                <div className="recaptcha-text">
                                    <div>
                                        <LoadCanvasTemplateNoReload />
                                    </div>
                                </div>
                            </div>
                            {errors.loginCaptcha && (
                                <div className="invalid-feedback">
                                    {t(errors.loginCaptcha.message)}
                                </div>
                            )}
                        </div>
                    }
                    <div className="t3-lr-button-box mt-5">
                    {otpEnabled &&
                      <>
                      {chatSphereChannel && chatSphereChannelList.length > 0 &&
                        chatSphereChannelList.map((item, index) => {
                          return (
                            <button
                              className="t3-custom-light-btn"
                              type="submit"
                              onClick={() => {
                                setChannleClick(item.name)
                                if (item.name == "Whatsapp") {
                                  setChannelType(OTP_BY_WHATSAPP)
                                } else {
                                  setChannelType(OTP_VIA_TELEGRAM)
                                }
                                // setChannleType(OTP_VIA_SMS)
                              }}
                              disabled={disableRegister}
                            >
                              {isRegisterLoading && channleClick == item.name ? (
                                <Spinner
                                  className="dynamic-table-spinner"
                                  animation="border"
                                  role="status"
                                  size="sm"
                                  style={{
                                    verticalAlign: "middle",
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    marginLeft: 3,
                                  }}
                                ></Spinner>
                              ) : (
                                `${t("REGISTER_VIA")} ${item.name}`
                              )}
                            </button>
                          )
                        })
                      }
                      {smsChannel && chatSphereChannelList.length == 0 &&
                        <div>
                          <button
                            className="t3-custom-light-btn"
                            type="submit"
                            onClick={() => setChannelType(OTP_VIA_SMS)}
                            disabled={disableRegister}
                          >
                            {isRegisterLoading ? (
                              <Spinner
                                className="dynamic-table-spinner"
                                animation="border"
                                role="status"
                                size="sm"
                                style={{
                                  verticalAlign: "middle",
                                  width: "1.5rem",
                                  height: "1.5rem",
                                  marginLeft: 3,
                                }}
                              ></Spinner>
                            ) : (
                              t("REGISTER")
                            )}
                          </button>
                        </div>
                      }
                      </>
                    }
                    {!otpEnabled && 
                        <div>
                            <button
                                className="t3-custom-light-btn"
                                type="submit"
                                onClick={() => setChannelType(OTP_VIA_SMS)}
                                disabled={disableRegister}
                            >
                                {isRegisterLoading ? (
                                    <Spinner
                                        className="dynamic-table-spinner"
                                        animation="border"
                                        role="status"
                                        size="sm"
                                        style={{
                                            verticalAlign: "middle",
                                            width: "1.5rem",
                                            height: "1.5rem",
                                            marginLeft: 3,
                                        }}
                                    ></Spinner>
                                ) : (
                                    t("REGISTER")
                                )}
                            </button>
                        </div>
                    }
                    </div>
                    <div className="t3-lr-button-box mt-3">
                      <span>{t("ALREADY_HAVE_AN_ACCOUNT")}</span>{" "}
                      <b
                        className="header-login-btn"
                        onClick={() => {
                          _dispatch(setRegisterModal(false));
                          setShowModalRegister(false)

                          _dispatch(setLoginModal(true));
                        }}
                      >
                        {t("LOGIN_NOW")}
                      </b>
                    </div>
                  </div>
                </form>
              )}

              {registerSteps == 2 && (
                <>
                  {registerStepsThreeFirst && (
                    <div className="t3-lr-pin-box">
                      <h3 className="t3-pin-title mb-0">
                        {t("VERIFY_YOUR_NUMBER")}
                      </h3>
                      <div className="t3-pin-title2 mb-3">
                        {t("ENTER_THE_CODE_WE_SENT_TO")}{" "}
                        {_emailText != "" ? _emailText : phoneNumber}.
                      </div>
                      <PinInput
                        length={6}
                        // initialValue="------"
                        ref={elePin}
                        // secret
                        type="numeric"
                        disabled={pinInputDisabled}
                        inputMode="numeric"
                        inputStyle={{ border: "0", margin: "0px 5px" }}
                        regexCriteria={/^[ 0-9_@./#&+-]*$/}
                        onComplete={(value, index) => {
                          onKeyPress(value, index);
                        }}
                      // onChange={(value, index) => {
                      // 	onKeyPress(value,index);
                      // 	// setPinNumberFocused(index);
                      // }}
                      />
                      {TACsent && countdownPeriod != 0 && (
                        <p className="tac-countdown mt-2 font12">
                          {t("TAC_SENT")} {countdownPeriod}s
                        </p>
                      )}

                      {pinLoading ? (
                        <div className="mt-2">
                          <Spinner
                            className="dynamic-table-spinner"
                            animation="border"
                            role="status"
                            size="sm"
                            style={{
                              verticalAlign: "middle",
                              width: "1.5rem",
                              height: "1.5rem",
                              marginLeft: 3,
                            }}
                          ></Spinner>
                        </div>
                      ) : (
                        ""
                      )}

                      {countdownPeriod == 0 && (
                        <div>
                          <button
                            className="tac-countdown"
                            style={{
                              background: "none",
                              border: "none",
                              padding: 0,
                              cursor: "pointer",
                            }}
                          >
                            {t("DIDNT_RECEIVE_A_CODE_TRY_OTHER_METHOD")}
                            {
                              attemp == 2 && (
                                <>
                                  <br />
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: t(
                                        "TRY_CONTACTING_OUR_CUSTOMER_SERVICE",
                                        { url: customerServiceUrl }
                                      ),
                                    }}
                                  />
                                </>
                              )
                            }
                          </button>
                          {attemp == 1 && channelType != OTP_VIA_SMS && (
                            <>
                              <div>
                                {smsChannel && 
                                (
                                  <button
                                  className="t3-custom-light-btn mt-3"
                                  onClick={() => {

                                    submitForm()
                                    setChannelType(1)
                                  }}
                                  disabled={pinInputDisabled || viaEmailLoading ? true : false}
                                >

                                  {viaEmailLoading ? (
                                    <Spinner
                                      className="dynamic-table-spinner"
                                      animation="border"
                                      role="status"
                                      size="sm"
                                      style={{
                                        verticalAlign: "middle",
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        marginLeft: 3,
                                      }}
                                    ></Spinner>
                                  ) : (
                                    t("RECEIVE_OTP_VIA_SMS")
                                  )}

                                </button>
                                )}
                                
                              </div>
                              <div>
                                <button
                                  className="t3-custom-light-btn mt-2"
                                  onClick={() => {
                                    setRegisterStepsThreeFirst(false);
                                    setRegisterStepsViaOtherChannel(true);
                                    setChannelType(OTP_VIA_EMAIL)
                                  }}
                                  disabled={pinInputDisabled || viaEmailLoading ? true : false}
                                >
                                  {t("RECEIVE_OTP_VIA_EMAIL")}
                                </button>
                              </div>
                            </>

                          )}

                          {attemp == 1 && channelType == OTP_VIA_SMS && (
                                <div>
                                    <button
                                        className="t3-custom-light-btn mt-2"
                                        onClick={() => {
                                            setRegisterStepsThreeFirst(false);
                                            setRegisterStepsViaOtherChannel(true);
                                            setChannelType(OTP_VIA_EMAIL)
                                        }}
                                        disabled={pinInputDisabled || viaEmailLoading ? true : false}
                                    >
                                        {t("RECEIVE_OTP_VIA_EMAIL")}
                                    </button>
                                </div>
                          )}
                          {/*  {attemp == 2 && (
                                <div>
                                    <button
                                        className="t3-custom-light-btn"
                                        onClick={() => setIsLiveChatOpen(true)}
                                        disabled={pinInputDisabled}
                                    >
                                        {t("CONTACT_CUSTOMER_SERVICE")}
                                    </button>
                                </div>
                            )} */}
                        </div>
                        )}
                    </div>
                  )}

                  {!registerStepsThreeFirst && registerStepsViaOtherChannel && (
                    <form onSubmit={handleSubmit(submitForm)}>
                      <div className="t3-lr-form">
                        <div className="t3-input-container mt-3">
                          <label>{t("EMAIL")}</label>
                          <Input
                            ref={register({
                              required: true,

                              pattern: {
                                value:
                                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: t("INVALID_EMAIL"),
                              },
                            })}
                            id="loginUsername"
                            name="email"
                            placeholder={t("EMAIL")}
                            onChange={handleChange}
                            errors={errors}
                            className="t3-custom-input-text"
                            autoFocus
                          />

                          <div className="t3-lr-button-box mt-5">
                            <div>
                              <button
                                className="t3-custom-light-btn"
                                disabled={disableViaEmail}
                              >
                                {viaEmailLoading ? (
                                  <Spinner
                                    className="dynamic-table-spinner"
                                    animation="border"
                                    role="status"
                                    size="sm"
                                    style={{
                                      verticalAlign: "middle",
                                      width: "1.5rem",
                                      height: "1.5rem",
                                      marginLeft: 3,
                                    }}
                                  ></Spinner>
                                ) : (
                                  t("CONTINUE")
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </>
              )}
              {registerSteps == 3 && (
                <>
                  <div className="t3-lr-success">
                    <h2 className="t3-primary-text-color">
                      {t("REGISTRATION_COMPLETED")}
                    </h2>

                  </div>
                </>
              )}
            </div>
          </div>
          {registerChannel && registerChannel.length > 0 && (
            <div
              className="t3-lr-modal-body-social-mdedia t3-side-menu-container active"
              style={{ textAlign: "center" }}
            >
              <div className="t3-register-channel-container">
                <div className="mt-3">
                  {t("REGISTER_THROUGH_SOCIAL_MEDIA")}
                </div>
                <div></div>
                <div className="t3-sm-box-active mt-3">
                  {map(registerChannel, (channel, index) => {
                    return (
                      <div
                        key={index}
                        className="ml-2 register-channel"
                        onClick={() => {
                          if (!stringIsNullOrEmpty(channel.url)) {
                            handleRegisterClick(channel);
                          }
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="first">
                          <img
                            src={channel.image}
                            className="img-responsive"
                            alt={channel.usage}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  )
};

export default RegisterModal;