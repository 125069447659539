import { themeIcons } from "config/theme";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { map } from "lodash";
import VIcon from "components/custom/Icon";

import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl } from "../../util/Constant";
import { createFormBody } from "../../util/Util.js";
import ApiEngine from "../../util/ApiEngine.js";
import { setMusicPlayerModal, showMessage } from "../../redux/AppAction.js";
import { Spinner } from "react-bootstrap";

const MusicPlayerModal = (props) => {
    var { t } = useTranslation();
    var _dispatch = useDispatch();

    var {
        systemMusicTracks,
        setSystemMusicPlayList,
        memberFavoriteTracks,
        setMemberFavoritePlaylist,
        setPlayList,
        playing,
        setPlaying,
        currentTrack,
        setCurrentTrack,
        currentTrackIndex,
        setCurrentTrackIndex,
        trackDuration,
        trackProgress,
        setTrackProgress,
        playedProgress,
        setPlayed,
        playerRef,
        setSeeking,
        isLoading,
        setIsLoading,
        loop,
        setLoop,
        isShuffle,
        setIsShuffle,
        setShuffleBtnClicked,
        currentPlayListType,
        setCurrentPlayListType
    } = props;

    const musicPlayerModal = useSelector((state) => state.appState.musicPlayerModal);
    var { heartBeat } = useSelector((state) => state.authState);

    const [durations, setDurations] = useState([]);
    const [pendingItems, setPendingItems] = useState(systemMusicTracks);
    const [activeMusicListTab, setActiveMusicListTab] = useState(1);
    const iconTheme = "music-player-contorl-btn-icon";

    const musicListTab = [
		{
            text: t("SYSTEM_MUSIC"),
            musicListTabId: 1,
		},
		{
			text: t("MY_MUSIC"),
            musicListTabId: 2,
		},
	];

    const closeBtn = (
	    <div
            className="close"
            onClick={() => {
                _dispatch(setMusicPlayerModal(false));
            }}
	    >
		    <VIcon icon={themeIcons.close} className="vicon" />
	    </div>
    );

    useEffect(() => {

    }, [musicPlayerModal]);

    //handle next music
    const handleNext = () => {
        var targetPlayList = currentPlayListType == "system" ? systemMusicTracks : memberFavoriteTracks;
        var nextIndex = (currentTrackIndex + 1) % targetPlayList.length;
        var isSameTrack = targetPlayList[nextIndex]?.id == currentTrack.id;

        if (!isSameTrack) {
            handleSeekAndProgress();
            setCurrentTrack(targetPlayList[nextIndex]);
            setCurrentTrackIndex(nextIndex);  
        }
    };

    //handle previous music
    const handlePrevious = () => {
        var targetPlayList = currentPlayListType == "system" ? systemMusicTracks : memberFavoriteTracks;
        var prevIndex = (currentTrackIndex - 1 + targetPlayList.length) % targetPlayList.length;
        var isSameTrack = targetPlayList[prevIndex]?.id == currentTrack.id;

        if (!isSameTrack) {
            handleSeekAndProgress();
            setCurrentTrack(targetPlayList[prevIndex]);
            setCurrentTrackIndex(prevIndex);
        }
    };

    const handleSeekAndProgress = () => {
        setIsLoading(true);
        setTrackProgress(0);
        setPlayed(0);
    }

    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);  // Adjust minutes calculation
        const secs = Math.floor(seconds % 60);

        if (hours > 0) {
            // If hours are present, return hours, minutes, and seconds
            return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${secs < 10 ? "0" : ""}${secs}`;
        } else {
            // If no hours, return only minutes and seconds
            return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
        }
    };

    const handleSeekMouseDown = (e) => {
        setSeeking(true);
    };

    const handleSeekChange = (e) => {
        const newProgress = parseFloat(e.target.value);
        setTrackProgress(newProgress); // Update time in seconds
        setPlayed(newProgress / trackDuration); // Update fraction for CSS
    };

    const handleSeekMouseUp = (e) => {
        setSeeking(false);
        playerRef.current.seekTo(parseFloat(e.target.value), 'seconds');
    };

    const handleTouchStart = (e) => {
        setSeeking(true);
    };

    const handleTouchMove = (e) => {
        const touch = e.touches[0];
        const rect = e.target.getBoundingClientRect();
        const touchPosition = ((touch.clientX - rect.left) / rect.width) * trackDuration;

        // Clamp the value within trackDuration
        const clampedProgress = Math.min(Math.max(touchPosition, 0), trackDuration);
        setPlayed(clampedProgress / trackDuration); // Update fraction for CSS
        setTrackProgress(clampedProgress); // Update current progress in seconds
    };

    const handleTouchEnd = () => {
        setSeeking(false);
        playerRef.current.seekTo(trackProgress, 'seconds');
    };


    const handleToggleLoop = () => {
        setLoop(!loop);
        sessionStorage.setItem('loop', !loop);
    };

    const handleTrackDuration = (id, duration) => {
        setDurations((prev) => [
            ...prev,
            { id: id, duration }, // Add duration with ID
        ]);
        // Remove the video from pendingUrls after fetching the duration
        setPendingItems((prev) =>
            prev.filter((pendingItem) => pendingItem.id !== id)
        );
    };

    const handleListPlayPause = (selectedTrack, index) => {
        if (currentTrack.id !== selectedTrack.id) {
            handleSeekAndProgress();
            setCurrentPlayListType(activeMusicListTab == 1 ? "system" : "member");
            setPlayList(activeMusicListTab == 1 ? systemMusicTracks : memberFavoriteTracks);
            setCurrentTrack(selectedTrack);
            setCurrentTrackIndex(index);

            if (!playing) {
                setPlaying(true);
            }
        }
        else {
            setPlaying(!playing);
        }

        var activeMusicListTabType = activeMusicListTab == 1 ? "system" : "member";
        if (setCurrentPlayListType != activeMusicListTabType) {
            setCurrentPlayListType(activeMusicListTabType);
            setPlayList(activeMusicListTab == 1 ? systemMusicTracks : memberFavoriteTracks);
            setCurrentTrackIndex(index);
        }
    }

    const setTrackAsFavourite = (item) => {
        var index = systemMusicTracks.findIndex((x) => {
            return x.id == item.id;
        });

        var updatedPlayList = [...systemMusicTracks];
        updatedPlayList[index].favorite = !updatedPlayList[index].favorite;
        setSystemMusicPlayList(updatedPlayList);

        var updatedFavoriteList = updatedPlayList.filter((track) => track.favorite);
        updateMemberFavoriteTrackIds(updatedFavoriteList);
    }

    async function updateMemberFavoriteTrackIds(updatedFavoriteList) {
        setMemberFavoritePlaylist(updatedFavoriteList);
        const ids = updatedFavoriteList.map(track => track.id).join(',');

        let params = {
            memberFavoriteTrackIds: ids
        };

        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_MEMBER_FAVORITE_TRACK_IDS,
            createFormBody(params)
        );

        if (typeof responseJson[ApiKey._API_SUCCESS_KEY] !== "undefined") {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setMemberFavoritePlaylist(updatedFavoriteList);

                if (currentPlayListType == "member") {
                    setCurrentTrackIndex(updatedFavoriteList.findIndex((x) => x.id == currentTrack.id));
                }
            }
        }
    }

    return (
        <>
            <Modal 
                fade={false}
                id="t3-custom-modal"
                isOpen={musicPlayerModal}
                contentClassName={"t3-custom-modal"}

                toggle={() => {
                    _dispatch(setMusicPlayerModal(!musicPlayerModal))
                }}
            >
                <ModalHeader close={closeBtn}>	
                    {t("MUSIC_PLAYER")}
                </ModalHeader>
                <ModalBody>
                    <div className="t3-custom-modal-container">
                        <div className="custom-body">
                            <div className="music-player">
                                <div className="music-player-container">
                                    {pendingItems.map((item) => (
                                        <ReactPlayer
                                            key={item.id}
                                            url={item.link}
                                            onDuration={(duration) => handleTrackDuration(item.id, duration)}
                                            style={{ display: 'none' }}
                                        />
                                    ))}
                                    <div className="music-track-info">
                                        <VIcon
                                            icon={themeIcons.musicNotes}
                                        />
                                        {currentTrack?.title}
                                    </div>
                                    <div className="music-player-seekbar-container">
                                        <div className="music-player-seekbar">
                                            <input
                                                className="custom-seekbar"
                                                type="range"
                                                min="0"
                                                max={trackDuration || 1}
                                                step="0.1"
                                                value={trackProgress}
                                                onMouseDown={handleSeekMouseDown}
                                                onChange={handleSeekChange}
                                                onMouseUp={handleSeekMouseUp}
                                                onTouchStart={handleTouchStart}
                                                onTouchMove={handleTouchMove}
                                                onTouchEnd={handleTouchEnd}
                                                style={{
                                                    "--progress": playedProgress, // Set the CSS variable
                                                }}
                                            />
                                        </div>
                                        <div className="audio-time-container">
                                            <div>
                                                {formatTime(trackProgress)}
                                            </div>
                                            <div>
                                                {isLoading ? (
                                                    <Spinner
                                                        className="dynamic-table-spinner"
                                                        animation="border"
                                                        role="status"
                                                        size="sm"
                                                        style={{
                                                            verticalAlign: "middle",
                                                            width: "16px",
                                                            height: "16px",
                                                            color: "#fff"
                                                        }}
                                                    ></Spinner>
                                                ) : (
                                                    formatTime(trackDuration)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="music-player-controls-container">
                                    <div
                                            className={isShuffle ? "control-button active" : "control-button"}
                                            onClick={() => {
                                                setShuffleBtnClicked(true);
                                                setIsShuffle((prev) => !prev)
                                            }}
                                        >
                                            <VIcon
                                                icon={themeIcons.shuffleBtn}
                                                className={`music-player-contorl-btn-icon ${isShuffle ? "control-active" : ""}`}
                                            />
                                        </div>
                                        <div
                                            className="control-button"
                                            onClick={handlePrevious}
                                        >
                                            <VIcon
                                                icon={themeIcons.previousBtn}
                                                className={iconTheme}
                                            />
                                        </div>
                                        <div
                                            className="control-button"
                                            onClick={handlePlayPause}
                                        >
                                            {playing ?
                                                <VIcon
                                                    icon={themeIcons.pauseButton}
                                                    className={iconTheme}
                                                /> :
                                                <VIcon
                                                    icon={themeIcons.playButton}
                                                    className={iconTheme}
                                                />
                                            }
                                        </div>
                                        <div
                                            className="control-button"
                                            onClick={handleNext}
                                        >
                                            <VIcon
                                                icon={themeIcons.nextBtn}
                                                className={iconTheme}
                                            />
                                        </div>
                                        <div
                                            className={loop ? "control-button active" : "control-button"}
                                            onClick={handleToggleLoop}
                                        >
                                            <VIcon
                                                icon={themeIcons.loop}
                                                className={`music-player-contorl-btn-icon ${loop ? "control-active" : ""}`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="music-playlist-container">
                                <div className="music-playlist-tab">
                                    {heartBeat && musicListTab &&
                                        musicListTab.length &&
                                        map(musicListTab, (item, index) => {
                                            if (!heartBeat && item.musicListTabId === 2) {
                                                return null;
                                            }

                                            return (
                                                <button
                                                    key={index}
                                                    className={
                                                        activeMusicListTab === item.musicListTabId
                                                            ? "active"
                                                            : ""
                                                    }
                                                    onClick={() => {
                                                        setActiveMusicListTab(item.musicListTabId);
                                                    }}
                                                >
                                                    <div
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {t(item.text)}
                                                    </div>
                                                </button>
                                            );
                                        })}
                                </div>
                                <div className="music-playlist">
                                    <div className="music-track-container">
                                        {activeMusicListTab === 1 &&
                                            systemMusicTracks.length &&
                                            map(systemMusicTracks, (item, index) => {
                                                const trackDuration = durations.find((d) => d.id === item.id);
                                                return (
                                                    <div key={index}>
                                                        <div className="music-track">
                                                            <div className="track-items">
                                                                <div className="track-info">
                                                                    <div className="track-title">
                                                                        {currentTrack && currentTrack.id == item.id &&
                                                                            <VIcon
                                                                                icon={themeIcons.musicNotes}
                                                                            />
                                                                        }
                                                                        {item.title}
                                                                    </div>
                                                                    <div className="track-times">
                                                                        <span className="track-played-time">
                                                                            {currentTrack && currentTrack.id == item.id ? formatTime(trackProgress) : "0:00"}
                                                                        </span>
                                                                        <span>&nbsp;/&nbsp;</span>
                                                                        <span className="track-durations">
                                                                            {trackDuration ? formatTime(trackDuration.duration) : t("LOADING_DURATION")}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="music-playlist-btn-container">
                                                                    <div
                                                                        className="play-btn"
                                                                        onClick={() => handleListPlayPause(item, index)}
                                                                    >
                                                                        {playing && (currentTrack && currentTrack.id == item.id) ?
                                                                            <VIcon
                                                                                icon={themeIcons.pauseButton}
                                                                            /> :
                                                                            <VIcon
                                                                                icon={themeIcons.playButton}
                                                                            />
                                                                        }
                                                                    </div>
                                                                    {heartBeat &&
                                                                        <div
                                                                            className={item.favorite ? "favorite-btn" : "add-favorite-btn"}
                                                                            onClick={() => setTrackAsFavourite(item)}
                                                                        >
                                                                            {item.favorite ?
                                                                                <VIcon
                                                                                    icon={themeIcons.addedMusic}
                                                                                    className={"favourite-track"}
                                                                                /> :
                                                                                <VIcon
                                                                                    icon={themeIcons.addFavourite}
                                                                                    className={"add-track"}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="line"></div>
                                                    </div>
                                                );
                                            })
                                        }

                                        {activeMusicListTab === 2 &&
                                            (memberFavoriteTracks.length ? (
                                            map(memberFavoriteTracks, (item, index) => {
                                                const trackDuration = durations.find((d) => d.id === item.id);
                                                    return (
                                                        <>
                                                            <div className="music-track" key={index}>
                                                                <div className="track-items">
                                                                    <div className="track-info">
                                                                        <div className="track-title">
                                                                            {currentTrack && currentTrack.id === item.id && (
                                                                                <VIcon icon={themeIcons.musicNotes} />
                                                                            )}
                                                                            {item.title}
                                                                        </div>
                                                                        <div className="track-times">
                                                                            <span className="track-played-time">
                                                                                {currentTrack && currentTrack.id === item.id
                                                                                    ? formatTime(trackProgress)
                                                                                    : "0:00"}
                                                                            </span>
                                                                            <span>&nbsp;/&nbsp;</span>
                                                                            <span className="track-durations">
                                                                                {trackDuration ? formatTime(trackDuration.duration) : t("LOADING_DURATION")}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="music-playlist-btn-container">
                                                                        <div
                                                                            className="play-btn"
                                                                            onClick={() => handleListPlayPause(item, index)}
                                                                        >
                                                                            {playing &&
                                                                                currentTrack &&
                                                                                currentTrack.id === item.id ? (
                                                                                <VIcon icon={themeIcons.pauseButton} />
                                                                            ) : (
                                                                                <VIcon icon={themeIcons.playButton} />
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                item.favorite
                                                                                    ? "favorite-btn"
                                                                                    : "add-favorite-btn"
                                                                            }
                                                                            onClick={() => setTrackAsFavourite(item)}
                                                                        >
                                                                            {item.favorite ? (
                                                                                <VIcon
                                                                                    icon={themeIcons.addedMusic}
                                                                                    className={"favourite-track"}
                                                                                />
                                                                            ) : (
                                                                                <VIcon
                                                                                    icon={themeIcons.addFavourite}
                                                                                    className={"add-track"}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="line"></div>
                                                        </>
                                                    );
                                                })
                                            ) : (
                                                <div className="no-favorites-message">
                                                    {t("NO_FAVORITE_MUSIC")}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
};

export default MusicPlayerModal;