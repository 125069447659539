import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  ApiKey,
  ApiUrl,
  _MAIN_WALLET_ID,
  SessionKey
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import {
  stringIsNullOrEmpty,
} from "../../util/Util";
import 'react-loading-skeleton/dist/skeleton.css'
import SpeakerIcon from "components/custom/SpeakerIcon";
import { useDispatch, useSelector } from "react-redux";
import parse from "react-html-parser";
import {
  get,
} from "lodash";


const MarqueAnnoucement = () => {
  var { t } = useTranslation();
  const [content, setContent] = useState("");
  var companyLanguages = useSelector((state) => state.appState.languages);
  const activeLanguageCode = useSelector(
    (state) => state.languageState.languageId
  );
  const [activeLanguage, setActiveLanguage] = useState("");
  const [activeLanguageId, setActiveLanguageId] = useState(0);

  useEffect(() => {
    getMarqueeTagContent()

  }, []);


  async function getMarqueeTagContent() {
    let responseJson = await ApiEngine.get(
      ApiUrl._API_GET_MARQUEE_TAG_CONTENT_BY_PLATFORM +
      "?MarqueeTagId=1" +
      "&LanguageId=" +
      activeLanguageId +
      "&Platform=" +
      "MemberApp"
    );
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      responseJson[ApiKey._API_DATA_KEY].map((option) => {
        setContent(option["content"]);
      });
    }
  }

  useEffect(() => {
    if (companyLanguages != undefined) {
      const tempLangCode = get(companyLanguages[0], "code");
      const tempLangId = get(companyLanguages[0], "id");
      if (
        localStorage.getItem(SessionKey._LANGUAGE) &&
        localStorage.getItem(SessionKey._LANGUAGE) !== "undefined"
      ) {
        let tempLang = companyLanguages.filter(
          (x) => x.code == localStorage.getItem(SessionKey._LANGUAGE)
        )[0];

        if (tempLang != undefined) {
          setActiveLanguage(tempLang.code);
          setActiveLanguageId(tempLang.id);
        } else {
          setActiveLanguage(tempLangCode);
          setActiveLanguageId(tempLangId);
        }
      } else {
        setActiveLanguage(tempLangCode);
        setActiveLanguageId(tempLangId);
      }
    } else {
      setActiveLanguage("en");
      setActiveLanguageId(1);
    }
  }, [companyLanguages, activeLanguageCode]);

  return (
    <>
      {content && (
        <div className="t3-announcement-container mt-3">
          <div className="first hide-767">
            {/* <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAAiCAMAAADvReCJAAAACGFjVEwAAAADAAAAAM7tusAAAABFUExURQAAAM/vz8fn18Xk1cfj08bi0sXi0sTh0cXh0cTi0sXj0cXi0sTi0sXi0sTi0sXi0sXh0cTi0sXh0cXh0cTh0cXh0cXi0hDGZVYAAAAWdFJOUwAQIDBAUGBwgI+Qn6Cwv8DP0N/g7/BQ+mYHAAAAGmZjVEwAAAAAAAAAJQAAACIAAAAAAAAAAAABAAQAAAa1MssAAABiSURBVDjL7dQ7DoAgFAVR8AU1avwBd/9LlQJjYXQSa6c+9biP2ZoaRknqCbUSq0Gs/ChWfhUr28UqJD2rdq5JL0pnv/pVrSN156xcyKxKFlmV/AKqNqHiA1xZZlVqtmjuUwd0XiRp0nIYuQAAABpmY1RMAAAAAQAAABAAAAAVAAAADgAAAAQAAQAEAAEQEonzAAAAWGZkQVQAAAACOMu1jkEOgCAMBKlS1EQFlMz/n2q8sRy82eO03dmwhT/HzisKcGDpwXQDLj8HNBNSIUlMgqwiKKoGvkEcM1bYpVrTZlag9gcFmEUx9vT87h87pQQ2dmgqxgAAABpmY1RMAAAAAwAAAAkAAAAaAAAAHAAAAAQAAQAEAAGSl0JaAAAAaGZkQVQAAAAEOMt1ztkKgDAQQ9GObd1wxXr//1PVBudBsFA4kAkkxGWwUN8Mm5hxtsAYnElcYZEaoPF+55ezZFCe6P4nmLfTV5P0kwJCht0L2mUFYtX4hhHIFQXWBwk4Tds4dB76SYMue7MG2OVprN4AAAAcdEVYdFNvZnR3YXJlAEFQTkcgQXNzZW1ibGVyIDIuOTH+/Sr4AAAAAElFTkSuQmCC"
                  className="img-responsive"
                  alt="announcement"
                /> */}
            <SpeakerIcon />
          </div>
          <div className="second">
            <marquee>
              <span className="text">
                {!stringIsNullOrEmpty(content) && parse(content)}
              </span>
            </marquee>
          </div>
        </div>
      )}
    </>
  )

}

export default MarqueAnnoucement