import React from "react";
import { PageSettings } from "./config/page-settings.js";
import Content from "./components/content/content.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import {
	updateLoginUser,
	resetLogin,
	performLogout,
	heartBeat,
} from "./redux/AuthAction.js";
import { setAppSetting } from "./redux/AppAction.js";
import { isObjectEmpty, stringIsNullOrEmpty, createFormBody, isValidLocale, hasValidLocale, extractLocaleFromPathname } from "./util/Util.js";
import { withTranslation } from "react-i18next";
import ApiEngine from "./util/ApiEngine.js";
import { ApiKey, ApiUrl, WebUrl } from "./util/Constant";
import { themeGenerator } from "config/theme.js";
import { paramCase } from "change-case";
import { ClientTemplates } from "./util/Constant";
import { Helmet } from "react-helmet";
import { Spinner } from "react-bootstrap";
import classNames from "classnames";
import { map } from "lodash";
// import parse from "html-react-parser";

LoadingOverlay.propTypes = undefined;
class App extends React.Component {
	constructor(props) {
		super(props);

		this.handleSetPageContent = (value) => {
			this.setState((state) => ({
				pageContent: value,
			}));
		};
		this.handleSetPageContentClass = (value) => {
			this.setState((state) => ({
				pageContentClass: value,
			}));
		};
		this.handleSetPageContentFullHeight = (value) => {
			this.setState((state) => ({
				pageContentFullHeight: value,
			}));
		};
		this.handleSetPageContentFullWidth = (value) => {
			this.setState((state) => ({
				pageContentFullWidth: value,
			}));
		};
		this.handleSetPageContentInverseMode = (value) => {
			this.setState((state) => ({
				pageContentInverseMode: value,
			}));
		};
		this.handleSetPageFooter = (value) => {
			this.setState((state) => ({
				pageFooter: value,
			}));
		};
		this.handleSetBodyWhiteBg = (value) => {
			if (value === true) {
				document.body.classList.add("bg-white");
			} else {
				document.body.classList.remove("bg-white");
			}
		};

		this.state = {
			template:
				ClientTemplates.filter((x) => x.hostname == window.location.hostname)[0]
					?.template ?? "template_1",
			pageContent: true,
			pageContentClass: "",
			pageContentFullHeight: false,
			pageContentFullWidth: true,
			pageContentInverseMode: false,
			pageNavBar: "",
			handleSetPageContent: this.handleSetPageContent,
			handleSetPageContentClass: this.handleSetPageContentClass,
			handleSetPageContentFullHeight: this.handleSetPageContentFullHeight,
			handleSetPageContentFullWidth: this.handleSetPageContentFullWidth,
			handleSetPageContentInverseMode: this.handleSetPageContentInverseMode,

			pageFooter: false,
			handleSetPageFooter: this.handleSetPageFooter,
			error: null,
			errorInfo: null,

			handleSetBodyWhiteBg: this.handleSetBodyWhiteBg,
			handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,

			interval1: null,
			location: window.location.href,
			language: this.props.i18n.language,
			appLoad: true
		};

		this.scrollRef = React.createRef();

	}

	/// <summary>
	/// Author: -
	/// </summary>
	async componentDidMount() {
		this.setState({
			// hasGetSkinSettings: false,
			hasCustomSkin: false,
			activeTheme: 0,
			backgroundImage: "",
		});
		clearInterval(this.state.interval1);
		this.state.interval1 = null;
		this.props.heartBeat();
		this.state.interval1 = setInterval(() => this.props.heartBeat(), 20000);


		// Dynamically import the CSS file based on the environment variable
		const root = process.env.REACT_APP_ROOT ? process.env.REACT_APP_ROOT : 'rootbase';

		this.setState({ appLoad: true });
		try {
			await import(`./assets/css/theme/${root}.css`);
			console.log(`${root}.css loaded successfully!`);
		} catch (error) {
			console.log(`Failed to load ${root}.css`, error);
		} finally {
			this.setState({ appLoad: false });
		}

		// this.getSetting(this.state.language);

		this.fetchSettings();
		this.getMeta(this.state.language);

		// Create a custom event
		const customEvent = new CustomEvent('changedLocation', {
			detail: { message: `Location changed to ${window.location.pathname}` }
		});

		// Dispatch the event
		window.dispatchEvent(customEvent);
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.languageState.languageId != prevProps.languageState.languageId
		) {
			this.props.i18n.changeLanguage(this.props.languageState.languageId);
		}

		// if (!this.state.hasCustomSkin) {
		// 	let heartBeat = this.props.authState.heartBeat;
		// 	if (heartBeat) {
		// 		this.getSkinSettings();
		// 	}
		// }

		if (
			this.state.location != window.location.href ||
			this.state.language != prevProps.i18n.language
		) {
			this.getSetting(this.props.i18n.language);
			this.getMeta(this.props.i18n.language);
			this.state.location = window.location.href;
			this.state.language = this.props.i18n.language;

			this.scrollRef.current.scrollTop = 0;
			window.scrollTo(0, 0);

			// Create a custom event
			const customEvent = new CustomEvent('changedLocation', {
				detail: { message: `Location changed to ${window.location.pathname}` }
			});

			// Dispatch the event
			window.dispatchEvent(customEvent);
		}


		if (
			this.state.seoTitle != this.props.appState.seoContent?.seoTitle
			|| this.state.seoDescription != this.props.appState.seoContent?.seoDescription
			|| this.state.seoImage != this.props.appState.seoContent?.seoImage
		) {
			this.setState({
				seoTitle: this.props.appState.seoContent.seoTitle,
				seoDescription: this.props.appState.seoContent.seoDescription,
				seoImage: this.props.appState.seoContent.seoImage
			});
		}
	}

	// CHRIS 20240801: Not used for some reason
	// applyTheme() {
	// 	if (this.state.template == "template_1") {
	// 		const themeValue = themeGenerator("template1");

	// 		for (const [key, value] of Object.entries(themeValue)) {
	// 			const variableName = `--${paramCase(key)}`;
	// 			document.documentElement.style.setProperty(variableName, value);
	// 		}
	// 	}
	// }

	/// <summary>
	/// Author: -
	/// </summary>
	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo,
		});
	}
	getPageSlug(pathname) {
		const locale = WebUrl.getCurrentLocale();

		// Check if url contains valid locale
		const regex = new RegExp(`^/${locale}(/|$)`);

		if (isValidLocale(locale) && pathname.startsWith(`/${locale}`) && pathname.match(regex)) {
			if (pathname === `/${locale}`) {
				return '/';
			}
			return pathname.replace(`/${locale}`, '');
		} else {
			return pathname.replace(`/${locale}`, '');
		}
	}

	async fetchSettings() {
		this.setState({ appLoad: true });

		await this.getSetting(this.state.language);

		this.setState({ appLoad: false });

	  }
	async getMeta(language) {
		// SEO Override System - will override all other SEO settings from other pages
		var pageSlug = this.getPageSlug(window.location.pathname);

		var encodedPageSlug = encodeURIComponent(pageSlug);
		var encodedLanguage = encodeURIComponent(language);

		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_SEO_META_CONTENT}?slug=${encodedPageSlug}&language=${encodedLanguage}`
		);

		var seoManagementFlag = false;

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			var data = responseJson[ApiKey._API_DATA_KEY];

			var scriptTagRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/gi;
			// const filteredData = data.filter(item => item.pageSlug == slug);
			var pageName = data.pageName;
			var seoTitle = '';
			var seoDescription = '';
			var seoImage = '';
			var customScripts = '';

			if (data && data.languageSeoTitle) {
				seoTitle = data.languageSeoTitle;
			} else {
				seoTitle = data.seoTitle;
			}

			if (data && data.languageSeoDescription) {
				seoDescription = data.languageSeoDescription;
			} else {
				seoDescription = data.seoDescription;
			}

			if (data && data.languageSeoImage) {
				seoImage = data.languageSeoImage;
			} else {
				seoImage = data.seoImage;
			}

			if (data && data.languageCustomScripts) {
				customScripts = data.languageCustomScripts;
			} else {
				customScripts = data.customScripts;
			}

			seoManagementFlag = true;

			////get rawScripts
			if (customScripts) {
				var customScriptsJson = JSON.parse(customScripts).html; /// extract the data from html JSON
				var matches = customScriptsJson.match(scriptTagRegex); /// make the html into array
				var contentWithoutScriptTags = null;

				if (matches) {
					var contentWithArrayForTags = matches.map(match => match.match(/<script[^>]*>([\s\S]*?)<\/script>/i)); /////separate each value
					contentWithoutScriptTags = contentWithArrayForTags.map(tags => tags[1]).join(''); ///join both value
				}
			}

			// console.log('seoTitle getMeta')
			this.setState({ pageNameOverride: pageName });
			this.setState({ seoTitleOverride: seoTitle });
			this.setState({ seoDescriptionOverride: seoDescription });
			this.setState({ seoImageOverride: seoImage });
			this.setState({ rawScriptsOverride: contentWithoutScriptTags });

		} else {
			console.log("getMeta error");
		}

		///if seo meta content is empty, show the one in page if got any seo meta

		if (seoManagementFlag == false) {
			if (this.props.appState.seoContent.pageName) {
				// console.log('seoTitle getMeta seoManagementFlag')
				this.setState({ pageName: this.props.appState.seoContent.pageName });
				this.setState({ seoTitle: this.props.appState.seoContent.seoTitle });
				this.setState({ seoDescription: this.props.appState.seoContent.seoDescription });
			}
		}
	}

	// async getSkinSettings() {
	// 	if (window.location.pathname.match(/dashboard/)) {
	// 		var responseJson = await ApiEngine.get(ApiUrl._API_GET_EXTRA_HTML_STYLE);
	// 		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
	// 			this.state.hasCustomSkin = true;
	// 			const style = document.createElement("style");
	// 			style.textContent = '"' + responseJson[ApiKey._API_DATA_KEY] + '"';
	// 			document.head.appendChild(style);
	// 		}
	// 		this.state.hasGetSkinSettings = true;
	// 	} else {
	// 		this.state.hasGetSkinSettings = false;
	// 	}
	// }

	async getSetting(language) {
		// this.setState({ appLoad: true });

		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_SETTING}?LanguageCode=` + language
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			var presetAmtData = responseJson[ApiKey._API_DATA_KEY].presetAmt;
			var chatsphereData = responseJson[ApiKey._API_DATA_KEY].chatsphere;
			var brandNameData = responseJson[ApiKey._API_DATA_KEY].brandName;
			var siteTitleData = responseJson[ApiKey._API_DATA_KEY].siteTitle;
			var siteDescriptionData = responseJson[ApiKey._API_DATA_KEY].siteDescription;
			var faviconData = responseJson[ApiKey._API_DATA_KEY].favicon;
			var timeZoneData = responseJson[ApiKey._API_DATA_KEY].timeZone;
			var userFullNameData = responseJson[ApiKey._API_DATA_KEY].userFullName;
			var userStatusData = responseJson[ApiKey._API_DATA_KEY].userStatus;
			var userStatusInStringData = responseJson[ApiKey._API_DATA_KEY].userStatusInString;
			var htmlStyleData = responseJson[ApiKey._API_DATA_KEY].htmlStyle;
			var customScriptData = responseJson[ApiKey._API_DATA_KEY].customScript;
			var currencyData = responseJson[ApiKey._API_DATA_KEY].currency;
			var languagesData = responseJson[ApiKey._API_DATA_KEY].languages;
			var membershipEnabled = responseJson[ApiKey._API_DATA_KEY].membershipEnabled;
			var mediasData = responseJson[ApiKey._API_DATA_KEY].medias;
			var channelsData = responseJson[ApiKey._API_DATA_KEY].channels;
			var currencySymbol = responseJson[ApiKey._API_DATA_KEY].currencySymbol;
			var dialingCodes = responseJson[ApiKey._API_DATA_KEY].dialingCodes;
			var settingApiComplete = true;
			var promotionLayout = responseJson[ApiKey._API_DATA_KEY].promotionLayout;
			var promotionShowCategoryTitle = responseJson[ApiKey._API_DATA_KEY].promotionShowCategoryTitle;
			var showPromoThumbnailClaimButton = responseJson[ApiKey._API_DATA_KEY].showPromoThumbnailClaimButton;
			var isReferralBonusActive = responseJson[ApiKey._API_DATA_KEY].isReferralBonusActive;
			var otpEnabled = responseJson[ApiKey._API_DATA_KEY].otpEnabled;
			var captchaEnabled = responseJson[ApiKey._API_DATA_KEY].captchaEnabled;
			var priorityModalTypeId = responseJson[ApiKey._API_DATA_KEY].priorityModalTypeId;
			var dashboardComponent = responseJson[ApiKey._API_DATA_KEY].dashboardComponent;
			var isNormalDepositEnable = responseJson[ApiKey._API_DATA_KEY].isNormalDepositEnable;
			var isNormalWithdrawalEnable = responseJson[ApiKey._API_DATA_KEY].isNormalWithdrawalEnable;
			var musicPlayerEnabled = responseJson[ApiKey._API_DATA_KEY].musicPlayerEnabled;
			var musicTrackList = responseJson[ApiKey._API_DATA_KEY].musicTrackList;
			var isEWalletActive = responseJson[ApiKey._API_DATA_KEY].isEWalletActive;
			var isBankTransferActive = responseJson[ApiKey._API_DATA_KEY].isBankTransferActive;
			var availableProductCategories = responseJson[ApiKey._API_DATA_KEY].availableProductCategories;
			var isGameLobbyRequired = responseJson[ApiKey._API_DATA_KEY].isGameLobbyRequired;

			if (htmlStyleData && htmlStyleData != "") {
				const existingStyle = document.getElementById("custom-style");
				if (!existingStyle) {
					const styleElement = document.createElement("style");
					styleElement.id = "custom-style";
					styleElement.innerHTML = htmlStyleData;
					document.head.appendChild(styleElement);
				}
			}

			if (customScriptData && customScriptData !== "") {
				const existingScript = document.getElementById("custom-script");
				if (!existingScript) {
					const scriptElement = document.createElement("script");
					scriptElement.id = "custom-script";
					scriptElement.innerHTML = customScriptData;
					document.body.appendChild(scriptElement);
				}
			}
			// this.setState({ appLoad: false });

			var chatsphere = [];
			const _APPEND_REGISTER_WHATSAPP_URL = "?text=register%20";
			const _APPEND_REGISTER_TELEGRAM_URL = "?start=";

			chatsphereData.forEach((x) => {
				chatsphere.push({
					usage: x.usage,
					url:
						x.usage == "Whatsapp"
							? "https://wa.me/" + x.phoneNumber + _APPEND_REGISTER_WHATSAPP_URL
							: x.usage == "Telegram"
								? "https://t.me/" + x.phoneNumber + _APPEND_REGISTER_TELEGRAM_URL
								: "",
					image: x.image,
				});
			});

			chatsphereData = chatsphere;

			const { setAppSetting } = this.props;
			setAppSetting(
				brandNameData,
				presetAmtData,
				currencyData,
				mediasData,
				channelsData,
				htmlStyleData,
				chatsphereData,
				languagesData,
				membershipEnabled,
				currencySymbol,
				dialingCodes,
				settingApiComplete,
				promotionLayout,
				promotionShowCategoryTitle,
				showPromoThumbnailClaimButton,
				isReferralBonusActive,
				otpEnabled,
				captchaEnabled,
				priorityModalTypeId,
				dashboardComponent,
				isNormalDepositEnable,
				isNormalWithdrawalEnable,
				musicPlayerEnabled,
				musicTrackList,
				isEWalletActive,
				isBankTransferActive,
				availableProductCategories,
				isGameLobbyRequired
			);
			//SEO
			//{
			var data = [] // responseJson[ApiKey._API_DATA_KEY].seo;

			var contentLength = data.length;
			var list = [];
			var newData = [];
			var newList = [];
			for (var x = 0; x < contentLength; x++) {
				var content = JSON.parse(data[x]["content"]);
				var contentLength1 = Object.keys(content).length;
				for (var i = 0; i < contentLength1; i++) {
					var jsonObj = JSON.parse(content["parameter_" + (i + 1)]);
					newData.push({
						name: "PARAMETER " + (i + 1),
						metaType: jsonObj["metaType"],
						content: jsonObj["content"],
					});
				}
				newList = {
					metaType: data[x]["metaDataType"],
					data: newData,
				};
				newData = [];
				list.push(newList);
			}
			var titleList = [];
			var meta = [];
			var link = [];
			var script = [];
			var noscript = [];
			var base = [];
			var title = "";

			for (var i = 0; i < list.length; i++) {
				if (list[i].metaType == "Meta") {
					for (var x = 0; x < list[i].data.length; x++) {
						var content = list[i].data[x].content;
						try {
							var trimmedContent = content.trim();
							var jsonObject = JSON.parse(trimmedContent);

							meta.push({
								page: list[i].data[x].metaType,
								content: jsonObject,
							});
						} catch (error) {
							console.error("Error parsing JSON:", error);
							console.error("Invalid JSON content:", content);
						}
					}
				} else if (list[i].metaType == "Title") {
					for (var x = 0; x < list[i].data.length; x++) {
						titleList.push({
							page: list[i].data[x].metaType,
							content: list[i].data[x].content,
						});
					}
				} else if (list[i].metaType == "Link") {
					for (var x = 0; x < list[i].data.length; x++) {
						var content = list[i].data[x].content;
						var jsonObject = JSON.parse(content);
						link.push({
							page: list[i].data[x].metaType,
							content: jsonObject,
						});
					}
				} else if (list[i].metaType == "script") {
					for (var x = 0; x < list[i].data.length; x++) {
						var page = list[i].data[x].metaType;
						var content = list[i].data[x].content;

						try {
							var jsonObject = JSON.parse(content);
							var code = jsonObject.code.replaceAll("'", `"`);
							jsonObject.code = code;
							script.push({
								page: page,
								content: jsonObject,
							});
						} catch (e) {
							console.error(`Error parsing JSON for page: ${page}`, e);
							console.log("Content received:", content);
							// Handle the error appropriately, e.g., skip this item, use a default value, etc.
						}
					}
				} else if (list[i].metaType == "noscript") {
					for (var x = 0; x < list[i].data.length; x++) {
						var page = list[i].data[x].metaType;
						var content = list[i].data[x].content;
						var jsonObject = JSON.parse(content);
						noscript.push({
							page: page,
							content: jsonObject,
						});
					}
				} else if (list[i].metaType == "Base") {
					for (var x = 0; x < list[i].data.length; x++) {
						var page = list[i].data[x].metaType;
						var content = list[i].data[x].content;
						var jsonObject = JSON.parse(content);
						base.push({
							page: page,
							content: jsonObject,
						});
					}
				}
			}

			var scriptList = [];
			var metaList = [];
			var linkList = [];
			var noscriptList = [];
			var baseList = [];

			if (titleList.length > 0) {
				titleList
					.filter((title) => title.page == window.location.pathname)
					.map((content) => {
						if (!stringIsNullOrEmpty(content)) {
							title = content.content;
						}
					});

				if (stringIsNullOrEmpty(title)) {
					titleList
						.filter((title) => title.page == "*")
						.map((contentAll) => {
							title = contentAll.content;
						});
				}
			}

			if (script.length > 0) {
				script
					.filter(
						(scrips) =>
							scrips.page == window.location.pathname || scrips.page == "*"
					)
					.map((content) => {
						if (!stringIsNullOrEmpty(content)) {
							scriptList.push(content.content);
						}
					});
			}

			if (meta.length > 0) {
				meta
					.filter(
						(metas) =>
							metas.page == "*" || metas.page == window.location.pathname
					)
					.map((content) => {
						if (!stringIsNullOrEmpty(content)) {
							metaList.push(content.content);
						}
					});
				var list = [];
				var listPage = [];
				if (metaList.length > 0) {
					var filterList = meta.filter(function (obj) {
						if (obj.content.name === "description") {
							list.push(obj.content);
							listPage.push(obj.page);
							return obj.content;
						}
					});
					var i = 0;
					var x = 0;
					while (i < metaList.length) {
						if (metaList[i] === list[x]) {
							metaList.splice(i, 1);
							x++;
						} else {
							++i;
						}
					}
				}

				listPage.includes(window.location.pathname)
					? filterList
						.filter((a) => a.page == window.location.pathname)
						.map((content) => {
							metaList.push(content.content);
						})
					: filterList
						.filter((a) => a.page == "*")
						.map((content) => {
							metaList.push(content.content);
						});
			}

			if (link.length > 0) {
				link
					.filter(
						(links) =>
							links.page == window.location.pathname || links.page == "*"
					)
					.map((content) => {
						if (!stringIsNullOrEmpty(content)) {
							linkList.push(content.content);
						}
					});
			}

			if (noscript.length > 0) {
				noscript
					.filter(
						(noscripts) =>
							noscripts.page == window.location.pathname ||
							noscripts.page == "*"
					)
					.map((content) => {
						if (!stringIsNullOrEmpty(content)) {
							noscriptList.push(content.content);
						}
					});
			}

			if (base.length > 0) {
				base
					.filter(
						(bases) =>
							bases.page == window.location.pathname || bases.page == "*"
					)
					.map((content) => {
						if (!stringIsNullOrEmpty(content)) {
							baseList.push(content.content);
						}
					});
			}

			this.setState({ baseList: baseList });
			this.setState({ brandName: brandNameData });
			this.setState({ noscriptList: noscriptList });
			this.setState({ scriptList: scriptList });
			this.setState({ linkList: linkList });
			this.setState({ title: siteTitleData });
			this.setState({ description: siteDescriptionData });
			this.setState({ metaList: metaList });
			this.setState({ seo: list });
			this.setState({ favicon: faviconData });
			//}
		}
	}

	render() {
		const {
			seo,
			metaList,
			brandName,
			title,
			description,
			favicon,
			linkList,
			scriptList,
			noscriptList,
			location,
			language,
			seoTitle,
			seoDescription,
			seoImage,
			seoTitleOverride,
			seoDescriptionOverride,
			seoImageOverride,
			rawScripts,
			appLoad,
		} = this.state;

		if (this.state.errorInfo) {
			return (
				<div className="error" style={{ padding: "20px" }}>
					<img
						src={require("./assets/img/error.png")}
						style={{
							maxWidth: "100px",
							objectFit: "contain",
							marginLeft: "auto",
							marginRight: "auto",
						}}
					/>
					<h3>Something went wrong, please contact admin for help</h3>
					<div className="m-b-30">{window.navigator.userAgent}</div>
				</div>
			);
		} else {
			return (
				<PageSettings.Provider value={this.state} className="template-3">
					{/* <Helmet title={title} meta={metaList} link={linkList} /> */}

					{/* Set default metas - START */}
					{/* Site name */}
					<Helmet>
						<meta name="application-name" content={brandName} />
						{/* <!-- iOS - Specifies the title of the web app when added to the home screen --> */}
						<meta name="apple-mobile-web-app-title" content={brandName} />
						<meta property="og:site_name" content={brandName} />
					</Helmet>

					{/* URL */}
					<Helmet>
						<base href={location} />
						<link rel="canonical" href={location} />
						<meta property="og:url" content={location} />
						<meta property="twitter:url" content={location} />
					</Helmet>

					{/* Titles */}
					<Helmet>
						<title>{title}</title>
						<meta property="og:title" content={title} />
						<meta property="twitter:title" content={title} />
					</Helmet>


					{/* Descriptions */}
					<Helmet>
						<meta name="description" content={description} />
						<meta property="og:description" content={description} />
						<meta property="twitter:description" content={description} />
					</Helmet>

					{/* Icons */}
					<Helmet>
						<link rel="icon" type="image/png" sizes="16x16" href={favicon} />
						<link rel="icon" type="image/png" sizes="32x32" href={favicon} />
						<link rel="icon" type="image/png" sizes="192x192" href={favicon} />
						<link rel="icon" type="image/png" sizes="512x512" href={favicon} />
						<link rel="apple-touch-icon" sizes="180x180" href={favicon} />
						<link rel="mask-icon" href={favicon} color="#002e6c" />
						<link rel="shortcut icon" href={favicon} />

						{/* <!-- iOS - Specify icons for the web app when it is added to the home screen --> */}
						<link rel="apple-touch-icon" sizes="180x180" href={favicon} />

						{/* <!-- Android - Specifies the title of the web app when added to the home screen --> */}
						<link rel="manifest" href="/manifest-rc.json" />

						<meta property="og:image" content={favicon} />
						<meta property="twitter:image" content={favicon} />
					</Helmet>

					{/* Others */}
					<Helmet>
						<meta name="theme-color" content="#ffffff" />
						<meta name="msapplication-TileColor" content="#2b5797" />
					</Helmet>
					{/* Set default metas - END */}

					{/* Set dynamic metas - START */}
					{/* seoTitle */}
					{
						seoTitleOverride && seoTitleOverride.length ? (
							<>
								<Helmet>
									<title>{seoTitleOverride}</title>
									<meta property="og:title" content={seoTitleOverride} />
									<meta property="twitter:title" content={seoTitleOverride} />
								</Helmet>
							</>
						) : (
							seoTitle && seoTitle.length && (
								<>
									<Helmet>
										<title>{seoTitle}</title>
										<meta property="og:title" content={seoTitle} />
										<meta property="twitter:title" content={seoTitle} />
									</Helmet>
								</>
							)
						)
					}

					{/* seoDescriptions */}
					{
						seoDescriptionOverride && seoDescriptionOverride.length ? (
							<>
								<Helmet>
									<meta name="description" content={seoDescriptionOverride} />
									<meta property="og:description" content={seoDescriptionOverride} />
									<meta property="twitter:description" content={seoDescriptionOverride} />
								</Helmet>
							</>
						) : (
							seoDescription && seoDescription.length && (
								<>
									<Helmet>
										<meta name="description" content={seoDescription} />
										<meta property="og:description" content={seoDescription} />
										<meta property="twitter:description" content={seoDescription} />
									</Helmet>
								</>
							)
						)
					}


					{/* seoImages */}
					{
						seoImageOverride && seoImageOverride.length ? (
							<>
								<Helmet>
									<meta property="og:image" content={seoImageOverride} />
									<meta property="twitter:image" content={seoImageOverride} />
								</Helmet>
							</>
						) : (
							seoImage && seoImage.length && (
								<>
									<Helmet>
										<meta property="og:image" content={seoImage} />
										<meta property="twitter:image" content={seoImage} />
									</Helmet>
								</>
							)
						)
					}

					{/* Custom Scripts */}
					<Helmet>
						{rawScripts && rawScripts.length && (
							<script key={seoTitle} type="text/javascript">
								{rawScripts}
							</script>
						)}
					</Helmet>


					{scriptList &&
						scriptList.length > 0 &&
						scriptList.map(({ code, type, id, src }, index) => {
							// Create an object to hold the props
							const props = {};

							// Conditionally add props to the object
							if (src !== undefined) {
								props.src = src;
							}
							if (id !== undefined) {
								props.id = id;
							}
							if (type !== undefined) {
								props.type = type;
							}

							return (
								<Helmet key={index}>
									<script {...props}>{code}</script>
								</Helmet>
							);
						})}

					{noscriptList &&
						noscriptList.length > 0 &&
						noscriptList.map(({ code }, index) => (
							<Helmet key={index}>
								<noscript>{code}</noscript>
							</Helmet>
						))}

					{/* Set dynamic metas - END */}

					{(this.props.appState.isBusy || !this.props.authState.loginStateInitialized) &&
						<div className="loader-line"></div>
					}

					<LoadingOverlay
						active={this.props.appState.isBusyLoading}
						styles={{
							overlay: (base) => ({
								...base,
								zIndex: "10000",
								position: "fixed",
							}),
						}}
						className={classNames("loading-overlay")}
					>
						{/* <img src={require("../../../assets/img/loading.gif")} />
						*/}
						<Spinner
							className="dynamic-table-spinner"
							animation="border"
							role="status"
							size="sm"
							style={{
								verticalAlign: "middle",
								width: "2rem",
								height: "2rem",
								color: "#fff"
							}}
						></Spinner>
						{/* <p>{this.props.appState.message}</p> */}
					</LoadingOverlay>

					{!isObjectEmpty(this.props.appState.alert) && (
						<div className="sweet-alert-container t3-sweet-alert-container">
							<SweetAlert
								{...this.props.appState.alert}
								style={
									this.props.appState.member.id != undefined
										? {
											backgroundColor: "#05102F",
											borderColor: "white !important",
											opacity: 0.95,
											color: "white",
										}
										: ""
								}
							>
								{!stringIsNullOrEmpty(this.props.appState.alert.html) &&
									this.props.appState.alert.html}

								{!stringIsNullOrEmpty(this.props.appState.alert.content) &&
									(this.props.appState.alert.html
										? this.props.appState.alert.content
										: this.props.appState.alert.content
											.split("\n")
											.map((item, key) => {
												return (
													<span key={key}>
														{item}
														<br />
													</span>
												);
											}))}
							</SweetAlert>
						</div>
					)}
					{this.props.authState.loginStateInitialized && !appLoad && (
						<>
							<div
								ref={this.scrollRef}
								className="fade page-sidebar-fixed show page-container page-content-full-height"
								style={{
									minHeight: "100vh",
									height: "100%",
									userSelect: "none",
									WebkitTouchCallout: "none",
									WebkitUserSelect: "none",
									MozUserSelect: "none",
									msUserSelect: "none",
								}}
							>
								<div
									onClick={() => {
										if (this.state.pageSidebarToggled) {
											this.toggleMobileSidebar();
										}
									}}
								>
									{this.state.pageContent && (
										<Content template={this.state.template} />
									)}
								</div>
							</div>
						</>
					)}
				</PageSettings.Provider>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	...state,
});

export default connect(mapStateToProps, {
	updateLoginUser: updateLoginUser,
	resetLogin: resetLogin,
	performLogout: performLogout,
	heartBeat: heartBeat,
	setAppSetting: setAppSetting,
})(withTranslation()(App));
